:root {
  --color-transparent-b76lky:transparent;
  --color-charts-red-300-taex3d:#ea7158;
  --color-charts-red-400-xo1w45:#dc5032;
  --color-charts-red-500-8et8sv:#d13313;
  --color-charts-red-600-ej8iox:#ba2e0f;
  --color-charts-red-700-pe7xt7:#a82a0c;
  --color-charts-red-800-9wqz57:#972709;
  --color-charts-red-900-fkiox3:#892407;
  --color-charts-red-1000-xhb2ma:#7d2105;
  --color-charts-red-1100-zkhjl8:#721e03;
  --color-charts-red-1200-t42yl7:#671c00;
  --color-charts-orange-300-uh1x0z:#e07941;
  --color-charts-orange-400-rg9ft0:#cc5f21;
  --color-charts-orange-500-6dcz8x:#bc4d01;
  --color-charts-orange-600-psg5i7:#a84401;
  --color-charts-orange-700-6rlkwo:#983c02;
  --color-charts-orange-800-aqh9u5:#8a3603;
  --color-charts-orange-900-71d364:#7e3103;
  --color-charts-orange-1000-p3xy9d:#732c02;
  --color-charts-orange-1100-plp0ix:#692801;
  --color-charts-orange-1200-msf9v8:#602400;
  --color-charts-yellow-300-zgwohc:#b2911c;
  --color-charts-yellow-400-z6s56e:#9c7b0b;
  --color-charts-yellow-500-f52bpo:#8a6b05;
  --color-charts-yellow-600-gzedvz:#7b5f04;
  --color-charts-yellow-700-uq1jq9:#6f5504;
  --color-charts-yellow-800-esbvam:#654d03;
  --color-charts-yellow-900-e2ugyo:#5d4503;
  --color-charts-yellow-1000-myf1mq:#553f03;
  --color-charts-yellow-1100-fi5c3g:#4d3901;
  --color-charts-yellow-1200-faeu24:#483300;
  --color-charts-green-300-8q7o65:#67a353;
  --color-charts-green-400-b5efwo:#41902c;
  --color-charts-green-500-o7urau:#1f8104;
  --color-charts-green-600-8e3las:#1a7302;
  --color-charts-green-700-h4agmh:#176702;
  --color-charts-green-800-jp1xwf:#145d02;
  --color-charts-green-900-czmqk3:#125502;
  --color-charts-green-1000-wrs7fm:#104d01;
  --color-charts-green-1100-d8mgjl:#0f4601;
  --color-charts-green-1200-jyfc23:#0d4000;
  --color-charts-teal-300-egfaot:#2ea597;
  --color-charts-teal-400-ikctdb:#1c8e81;
  --color-charts-teal-500-v952sw:#0d7d70;
  --color-charts-teal-600-9ffc3q:#096f64;
  --color-charts-teal-700-nn7a5u:#06645a;
  --color-charts-teal-800-97l4n7:#045b52;
  --color-charts-teal-900-zq1u0w:#03524a;
  --color-charts-teal-1000-u4e4vv:#014b44;
  --color-charts-teal-1100-cdt1d8:#01443e;
  --color-charts-teal-1200-3ozaok:#003e38;
  --color-charts-blue-1-300-4x8rnx:#529ccb;
  --color-charts-blue-1-400-owg3dt:#3184c2;
  --color-charts-blue-1-500-sl8chl:#0273bb;
  --color-charts-blue-1-600-3824a7:#0166ab;
  --color-charts-blue-1-700-e3d0uw:#015b9d;
  --color-charts-blue-1-800-vnypf9:#015292;
  --color-charts-blue-1-900-rs4k5z:#014a87;
  --color-charts-blue-1-1000-wjr7hn:#01437d;
  --color-charts-blue-1-1100-nmn22x:#003c75;
  --color-charts-blue-1-1200-od4mlj:#00366d;
  --color-charts-blue-2-300-6yvfip:#688ae8;
  --color-charts-blue-2-400-iba8cu:#5978e3;
  --color-charts-blue-2-500-vtdvkt:#4066df;
  --color-charts-blue-2-600-tpo4rj:#3759ce;
  --color-charts-blue-2-700-p6tu56:#314fbf;
  --color-charts-blue-2-800-08f3pp:#2c46b1;
  --color-charts-blue-2-900-3aq93e:#273ea5;
  --color-charts-blue-2-1000-6b05hm:#23379b;
  --color-charts-blue-2-1100-0014sa:#1f3191;
  --color-charts-blue-2-1200-tzr398:#1b2b88;
  --color-charts-purple-300-yatxj8:#a783e1;
  --color-charts-purple-400-d384pt:#9469d6;
  --color-charts-purple-500-skntta:#8456ce;
  --color-charts-purple-600-8iqvzu:#7749bf;
  --color-charts-purple-700-3qzy9s:#6b40b2;
  --color-charts-purple-800-ucyxda:#6237a7;
  --color-charts-purple-900-tlepdb:#59309d;
  --color-charts-purple-1000-3pjp6l:#512994;
  --color-charts-purple-1100-t0m9qa:#4a238b;
  --color-charts-purple-1200-003tqq:#431d84;
  --color-charts-pink-300-0gug9u:#da7596;
  --color-charts-pink-400-jh84a9:#ce567c;
  --color-charts-pink-500-c97yed:#c33d69;
  --color-charts-pink-600-rpo3pi:#b1325c;
  --color-charts-pink-700-mshe2z:#a32952;
  --color-charts-pink-800-bph464:#962249;
  --color-charts-pink-900-mvfm0y:#8b1b42;
  --color-charts-pink-1000-kq8pu0:#81143b;
  --color-charts-pink-1100-pi0m3u:#780d35;
  --color-charts-pink-1200-hukz4p:#6f062f;
  --color-charts-status-critical-268let:#7d2105;
  --color-charts-status-high-vsd25n:#ba2e0f;
  --color-charts-status-medium-371m8g:#cc5f21;
  --color-charts-status-low-lfwc29:#b2911c;
  --color-charts-status-positive-2ozsqj:#67a353;
  --color-charts-status-info-l8g84h:#3184c2;
  --color-charts-status-neutral-b4b0p2:#7d8998;
  --color-charts-threshold-negative-y7lrhf:#d91515;
  --color-charts-threshold-positive-42ybvt:#037f0c;
  --color-charts-threshold-info-kcjduw:#0972d3;
  --color-charts-threshold-neutral-k14cuc:#5f6b7a;
  --color-charts-line-grid-6wil4m:#d1d5db;
  --color-charts-line-tick-yidpvu:#d1d5db;
  --color-charts-line-axis-yo865k:#d1d5db;
  --color-charts-palette-categorical-1-bonb16:#688ae8;
  --color-charts-palette-categorical-2-emtquh:#c33d69;
  --color-charts-palette-categorical-3-imrqvq:#2ea597;
  --color-charts-palette-categorical-4-g31h69:#8456ce;
  --color-charts-palette-categorical-5-64f3p2:#e07941;
  --color-charts-palette-categorical-6-msr86m:#3759ce;
  --color-charts-palette-categorical-7-kdjms7:#962249;
  --color-charts-palette-categorical-8-79776a:#096f64;
  --color-charts-palette-categorical-9-s4w3dd:#6237a7;
  --color-charts-palette-categorical-10-cuicpx:#a84401;
  --color-charts-palette-categorical-11-no2uu5:#273ea5;
  --color-charts-palette-categorical-12-jq9rcw:#780d35;
  --color-charts-palette-categorical-13-4cawsz:#03524a;
  --color-charts-palette-categorical-14-i1n5cl:#4a238b;
  --color-charts-palette-categorical-15-9pgabq:#7e3103;
  --color-charts-palette-categorical-16-wnvn1q:#1b2b88;
  --color-charts-palette-categorical-17-y2duhb:#ce567c;
  --color-charts-palette-categorical-18-xehfp2:#003e38;
  --color-charts-palette-categorical-19-v1x5n3:#9469d6;
  --color-charts-palette-categorical-20-q5g6qv:#602400;
  --color-charts-palette-categorical-21-z2fito:#4066df;
  --color-charts-palette-categorical-22-mdbc68:#a32952;
  --color-charts-palette-categorical-23-9iyat0:#0d7d70;
  --color-charts-palette-categorical-24-17kvjc:#6b40b2;
  --color-charts-palette-categorical-25-c2vakt:#bc4d01;
  --color-charts-palette-categorical-26-g98tv5:#2c46b1;
  --color-charts-palette-categorical-27-lo8vzl:#81143b;
  --color-charts-palette-categorical-28-2ppjnc:#045b52;
  --color-charts-palette-categorical-29-68gzom:#512994;
  --color-charts-palette-categorical-30-9pbejo:#8a3603;
  --color-charts-palette-categorical-31-8odgjs:#1f3191;
  --color-charts-palette-categorical-32-xug28a:#da7596;
  --color-charts-palette-categorical-33-cr6l8a:#01443e;
  --color-charts-palette-categorical-34-209022:#a783e1;
  --color-charts-palette-categorical-35-twgdku:#692801;
  --color-charts-palette-categorical-36-g2mar5:#5978e3;
  --color-charts-palette-categorical-37-9deaq2:#b1325c;
  --color-charts-palette-categorical-38-5fg2jv:#1c8e81;
  --color-charts-palette-categorical-39-c8yeil:#7749bf;
  --color-charts-palette-categorical-40-vlagy9:#cc5f21;
  --color-charts-palette-categorical-41-o49tuv:#314fbf;
  --color-charts-palette-categorical-42-5irquw:#8b1b42;
  --color-charts-palette-categorical-43-8pxo9j:#06645a;
  --color-charts-palette-categorical-44-w3yd1t:#59309d;
  --color-charts-palette-categorical-45-32a8x6:#983c02;
  --color-charts-palette-categorical-46-4ikr9l:#23379b;
  --color-charts-palette-categorical-47-wg3s7g:#6f062f;
  --color-charts-palette-categorical-48-7no3ly:#014b44;
  --color-charts-palette-categorical-49-qy0tr5:#431d84;
  --color-charts-palette-categorical-50-gkixcb:#732c02;
  --color-background-button-link-active-t13dka:#d3e7f9;
  --color-background-button-link-hover-69sbpz:#f2f8fd;
  --color-background-button-normal-active-9lyks0:#d3e7f9;
  --color-background-button-normal-default-syw2p1:#ffffff;
  --color-background-button-normal-disabled-z5vd4i:#ffffff;
  --color-background-button-normal-hover-c3qbgm:#f2f8fd;
  --color-background-button-primary-active-vg0knj:#033160;
  --color-background-button-primary-default-pwn1g1:#0972d3;
  --color-background-button-primary-disabled-eon7km:#e9ebed;
  --color-background-button-primary-hover-qu1zio:#033160;
  --color-background-calendar-today-762czq:#f4f4f4;
  --color-background-cell-shaded-26ubfj:#f8f8f8;
  --color-background-code-editor-gutter-active-line-default-p2p8kl:#5f6b7a;
  --color-background-code-editor-gutter-active-line-error-lfh0u4:#d91515;
  --color-background-code-editor-gutter-default-l5k65m:#f4f4f4;
  --color-background-code-editor-loading-4cqamf:#fbfbfb;
  --color-background-code-editor-pane-item-hover-lrigi6:#e9ebed;
  --color-background-code-editor-status-bar-qgcwj5:#f4f4f4;
  --color-background-container-content-i8i4a0:#ffffff;
  --color-background-container-header-4flbq5:#ffffff;
  --color-background-control-checked-9admlu:#0972d3;
  --color-background-control-default-4pa05r:#ffffff;
  --color-background-control-disabled-zplipy:#d1d5db;
  --color-background-dropdown-item-default-fzdqsg:#ffffff;
  --color-background-dropdown-item-dimmed-qw48ma:transparent;
  --color-background-dropdown-item-filter-match-6ac2mw:#f2f8fd;
  --color-background-dropdown-item-hover-mqsxkv:#f4f4f4;
  --color-background-dropdown-item-selected-8l4mpk:#f2f8fd;
  --color-background-home-header-mpq08f:#000716;
  --color-background-input-default-u57378:#ffffff;
  --color-background-input-disabled-wu9j6u:#e9ebed;
  --color-background-item-selected-ebt4bi:#f2f8fd;
  --color-background-layout-main-sfhm4y:#ffffff;
  --color-background-layout-mobile-panel-9vweqk:#000716;
  --color-background-layout-panel-content-808qum:#ffffff;
  --color-background-layout-panel-hover-0bg3gq:#e9ebed;
  --color-background-layout-toggle-active-d15dki:#354150;
  --color-background-layout-toggle-default-f9hnkv:#192534;
  --color-background-layout-toggle-hover-gi0e0b:#354150;
  --color-background-layout-toggle-selected-active-9w7pk3:#0972d3;
  --color-background-layout-toggle-selected-default-e1giv3:#0972d3;
  --color-background-layout-toggle-selected-hover-mes33s:#065299;
  --color-background-modal-overlay-17dapf:rgba(35, 47, 62, 0.7);
  --color-background-notification-blue-vqb59v:#0972d3;
  --color-background-notification-green-0d9ow8:#037f0c;
  --color-background-notification-grey-ua4ep5:#414d5c;
  --color-background-notification-red-v7js1f:#d91515;
  --color-background-popover-mn5o9k:#ffffff;
  --color-background-progress-bar-content-default-r4tyyh:#0972d3;
  --color-background-progress-bar-content-in-flash-37g8fy:#ffffff;
  --color-background-progress-bar-layout-default-9xbpym:#e9ebed;
  --color-background-progress-bar-layout-in-flash-phmfak:rgba(255, 255, 255, 0.25);
  --color-background-segment-active-bl0u6r:#0972d3;
  --color-background-segment-default-5k37bj:#ffffff;
  --color-background-segment-disabled-hgka10:#ffffff;
  --color-background-segment-hover-s3f27a:#ffffff;
  --color-background-status-error-pe1hux:#fff7f7;
  --color-background-status-info-usb30e:#f2f8fd;
  --color-background-status-success-xnxxn4:#f2fcf3;
  --color-background-status-warning-zz4oi6:#ffffff;
  --color-background-table-header-i1pa1g:#ffffff;
  --color-background-tiles-disabled-byt5lx:#e9ebed;
  --color-background-toggle-checked-disabled-07lf06:#b5d6f4;
  --color-background-toggle-default-w4ftus:#414d5c;
  --color-border-button-normal-active-zbmaft:#033160;
  --color-border-button-normal-default-djv8f5:#0972d3;
  --color-border-button-normal-disabled-262a0x:#9ba7b6;
  --color-border-button-normal-hover-e1d0kh:#033160;
  --color-border-button-primary-disabled-6gwabt:#e9ebed;
  --color-border-calendar-grid-sshunw:transparent;
  --color-border-calendar-grid-selected-focus-ring-cxqa0s:#fbfbfb;
  --color-border-code-editor-ace-active-line-light-theme-3l07nr:#d1d5db;
  --color-border-code-editor-ace-active-line-dark-theme-74siyt:#5f6b7a;
  --color-border-code-editor-default-l9vmjf:#d1d5db;
  --color-border-code-editor-pane-item-hover-xephrt:#7d8998;
  --color-border-container-divider-4ade7z:transparent;
  --color-border-container-top-srcvx4:transparent;
  --color-border-control-checked-4xvndc:#0972d3;
  --color-border-control-default-m3lmsh:#7d8998;
  --color-border-control-disabled-vx8bco:#d1d5db;
  --color-border-divider-active-nnnfx9:#7d8998;
  --color-border-divider-default-7s2wjw:#e9ebed;
  --color-border-divider-panel-bottom-zrjpck:#e9ebed;
  --color-border-divider-panel-side-yjkfcr:#e9ebed;
  --color-border-dropdown-container-tyn0js:#9ba7b6;
  --color-border-dropdown-group-0utpsr:#e9ebed;
  --color-border-dropdown-item-default-faosd8:#e9ebed;
  --color-border-dropdown-item-hover-tyzq9m:#7d8998;
  --color-border-dropdown-item-dimmed-hover-6dhy15:#7d8998;
  --color-border-dropdown-item-top-xnzq48:transparent;
  --color-border-input-default-e47bkv:#7d8998;
  --color-border-input-disabled-x2lopj:#e9ebed;
  --color-border-item-focused-ap3b6s:#0972d3;
  --color-border-item-placeholder-jgzhnb:#0972d3;
  --color-border-item-selected-ppkssz:#0972d3;
  --color-border-layout-wf5alz:#d1d5db;
  --color-border-popover-5pygk7:#9ba7b6;
  --color-border-segment-active-kdy5tm:#414d5c;
  --color-border-segment-default-091088:#414d5c;
  --color-border-segment-disabled-6v5zlt:#414d5c;
  --color-border-segment-hover-9notuw:#414d5c;
  --color-border-status-error-mfrddc:#d91515;
  --color-border-status-info-546i7i:#0972d3;
  --color-border-status-success-cqi45f:#037f0c;
  --color-border-status-warning-hg85ut:#7d8998;
  --color-border-divider-interactive-default-rcasvw:#e9ebed;
  --color-border-tabs-divider-69fx5i:#e9ebed;
  --color-border-tabs-shadow-4lmh2z:rgba(0, 7, 22, 0.12);
  --color-border-tabs-underline-9bcfzu:#0972d3;
  --color-border-tiles-disabled-dp4h7y:#e9ebed;
  --color-border-tutorial-4ioksw:#d1d5db;
  --color-foreground-control-default-7ajdem:#ffffff;
  --color-foreground-control-disabled-v6a97u:#ffffff;
  --color-shadow-default-3se70f:rgba(0, 7, 22, 0.12);
  --color-text-accent-s1eqko:#0972d3;
  --color-text-body-default-ajf1h5:#000716;
  --color-text-body-secondary-fdstdf:#414d5c;
  --color-text-breadcrumb-current-mum16s:#5f6b7a;
  --color-text-breadcrumb-icon-mlugp6:#7d8998;
  --color-text-button-inline-icon-default-w65lqn:#0972d3;
  --color-text-button-inline-icon-disabled-gfhvz7:#9ba7b6;
  --color-text-button-inline-icon-hover-wd5icp:#033160;
  --color-text-button-normal-active-6obrzh:#033160;
  --color-text-button-normal-default-u8647m:#0972d3;
  --color-text-button-normal-hover-kxmvcc:#033160;
  --color-text-link-button-normal-default-2yrp9u:#0972d3;
  --color-text-link-button-normal-hover-gz8y5b:#033160;
  --color-text-link-button-normal-active-8lhs8f:#033160;
  --color-text-button-primary-active-vems2x:#ffffff;
  --color-text-button-primary-default-340wh6:#ffffff;
  --color-text-button-primary-hover-vzqjpi:#ffffff;
  --color-text-calendar-day-hover-graajh:#000716;
  --color-text-calendar-month-elf7e9:#5f6b7a;
  --color-text-code-editor-gutter-active-line-3r48bk:#ffffff;
  --color-text-code-editor-gutter-default-q46j79:#000716;
  --color-text-code-editor-status-bar-disabled-6bhuub:#7d8998;
  --color-text-code-editor-tab-button-error-vez6ll:#ffffff;
  --color-text-column-header-u472sv:#414d5c;
  --color-text-column-sorting-icon-q5sr09:#414d5c;
  --color-text-control-disabled-3z3eul:#9ba7b6;
  --color-text-counter-jwdegc:#5f6b7a;
  --color-text-disabled-a2nkh4:#9ba7b6;
  --color-text-dropdown-footer-rj6jvf:#5f6b7a;
  --color-text-dropdown-group-label-eaqlcl:#414d5c;
  --color-text-dropdown-header-6e05nm:#000716;
  --color-text-dropdown-item-default-7tg9p2:#000716;
  --color-text-dropdown-item-dimmed-jyqdrs:#9ba7b6;
  --color-text-dropdown-item-disabled-varol7:#9ba7b6;
  --color-text-dropdown-item-filter-match-x302if:#0972d3;
  --color-text-dropdown-item-highlighted-oaabyk:#000716;
  --color-text-dropdown-item-secondary-0me7js:#5f6b7a;
  --color-text-dropdown-item-secondary-hover-7ii1ru:#5f6b7a;
  --color-text-empty-fjv325:#5f6b7a;
  --color-text-expandable-section-default-k03p1p:#000716;
  --color-text-expandable-section-hover-73g3du:#0972d3;
  --color-text-expandable-section-navigation-icon-default-lb9rm0:#414d5c;
  --color-text-form-default-yd29ox:#000716;
  --color-text-form-label-k3j0b4:#000716;
  --color-text-form-secondary-ih9x7l:#5f6b7a;
  --color-text-group-label-w9to2i:#414d5c;
  --color-text-heading-default-jopz0f:#000716;
  --color-text-heading-secondary-corkaj:#414d5c;
  --color-text-home-header-default-1d71y3:#ffffff;
  --color-text-home-header-secondary-qfyemi:#d1d5db;
  --color-text-icon-subtle-bz5b7h:#5f6b7a;
  --color-text-input-disabled-oawyzo:#9ba7b6;
  --color-text-input-placeholder-j53j3c:#5f6b7a;
  --color-text-input-placeholder-disabled-6928j8:#9ba7b6;
  --color-text-interactive-active-vol84d:#000716;
  --color-text-interactive-default-eg5fsa:#414d5c;
  --color-text-interactive-disabled-3pbb07:#9ba7b6;
  --color-text-interactive-hover-v3lasm:#000716;
  --color-text-interactive-inverted-default-phki5u:#d1d5db;
  --color-text-interactive-inverted-hover-g2obc6:#fbfbfb;
  --color-text-inverted-821sko:#ffffff;
  --color-text-label-gxskdy:#000716;
  --color-text-layout-toggle-20sb9r:#ffffff;
  --color-text-layout-toggle-selected-tzwifo:#ffffff;
  --color-text-link-default-5f186r:#0972d3;
  --color-text-link-hover-kololx:#033160;
  --color-text-link-inverted-hover-5tnsdi:#ffffff;
  --color-text-link-button-underline-8mp92t:transparent;
  --color-text-link-button-underline-hover-ei09le:transparent;
  --color-text-link-primary-underline-ga7rq8:#0972d3;
  --color-text-notification-default-2kpcxy:#fbfbfb;
  --color-text-pagination-page-number-active-disabled-sjs1t5:#9ba7b6;
  --color-text-pagination-page-number-default-z6gnaa:#414d5c;
  --color-text-segment-active-gc2jjl:#ffffff;
  --color-text-segment-default-bf7rka:#414d5c;
  --color-text-segment-hover-5pccvg:#0972d3;
  --color-text-small-le1y42:#5f6b7a;
  --color-text-status-error-5sesl6:#d91515;
  --color-text-status-inactive-5ei55p:#5f6b7a;
  --color-text-status-info-utupcz:#0972d3;
  --color-text-status-success-0fzhv9:#037f0c;
  --color-text-status-warning-27nlyh:#d91515;
  --color-text-top-navigation-title-oypxe3:#000716;
  --color-board-placeholder-active-vaxzdf:#e9ebed;
  --color-board-placeholder-hover-v1s7kq:#d3e7f9;
  --font-body-m-line-height-i7xxvv:22px;
  --font-body-m-size-sregvd:14px;
  --font-body-s-letter-spacing-cy0oxj:0.005em;
  --font-body-s-line-height-kdsbrl:16px;
  --font-body-s-size-ukw2p9:12px;
  --font-button-letter-spacing-8y5poz:0.005em;
  --font-button-weight-7rj5fx:800;
  --font-chart-detail-size-pm0amk:12px;
  --font-display-l-letter-spacing-4ff418:-0.03em;
  --font-display-l-line-height-71tnj3:54px;
  --font-display-l-size-03lgcb:48px;
  --font-display-label-weight-m18hjh:700;
  --font-expandable-heading-size-m2wptt:16px;
  --font-family-base-qnistn:"Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  --font-family-monospace-iz223z:Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  --font-header-h2-description-line-height-gjrb86:22px;
  --font-header-h2-description-size-pqrrui:14px;
  --font-heading-l-letter-spacing-f6wvtk:-0.015em;
  --font-heading-l-line-height-gev4n1:30px;
  --font-heading-l-size-otmxys:24px;
  --font-heading-l-weight-xm7ks9:800;
  --font-heading-m-letter-spacing-r00pg3:-0.010em;
  --font-heading-m-line-height-u3t54f:24px;
  --font-heading-m-size-6e3jmg:20px;
  --font-heading-m-weight-hi5n0j:800;
  --font-heading-s-letter-spacing-4d69jc:-0.005em;
  --font-heading-s-line-height-5b7f0q:20px;
  --font-heading-s-size-9clg8y:16px;
  --font-heading-s-weight-k8ys41:800;
  --font-heading-xl-letter-spacing-9tcsmy:-0.02em;
  --font-heading-xl-line-height-doxfdj:40px;
  --font-heading-xl-size-d0vjbk:32px;
  --font-heading-xl-weight-772870:800;
  --font-heading-xs-line-height-5x131j:18px;
  --font-heading-xs-size-fryxro:14px;
  --font-heading-xs-weight-wwznty:800;
  --font-box-value-large-weight-dyt1pj:800;
  --font-link-button-letter-spacing-58wset:0.005em;
  --font-link-button-weight-4f1hjr:800;
  --font-link-primary-decoration-wfgjh2:underline;
  --font-link-primary-letter-spacing-vmsgcx:"inherit";
  --font-link-primary-weight-91pybi:"inherit";
  --font-panel-header-line-height-x2226j:24px;
  --font-panel-header-size-0vdgjc:20px;
  --font-smoothing-webkit-vu3hx4:antialiased;
  --font-smoothing-moz-osx-nkh1jb:grayscale;
  --font-tabs-disabled-weight-imwq16:800;
  --font-tabs-line-height-qo8cr6:20px;
  --font-tabs-size-01zslx:16px;
  --font-wayfinding-link-active-weight-l3fid7:800;
  --font-weight-heavy-ydka9r:800;
  --border-active-width-ce5ls7:4px;
  --border-code-editor-status-divider-width-cf5jzt:2px;
  --border-container-sticky-width-i7t7xe:0px;
  --border-container-top-width-jk6fhm:0px;
  --border-control-focus-ring-shadow-spread-srzyiu:0px;
  --border-control-invalid-focus-ring-shadow-spread-omt6il:2px;
  --border-divider-list-width-hacikr:1px;
  --border-divider-section-width-4wm2it:2px;
  --border-dropdown-virtual-offset-width-fzhepk:2px;
  --border-field-width-idlekx:2px;
  --border-invalid-width-5jpdqn:8px;
  --border-item-width-qbbbsa:2px;
  --border-line-chart-dash-array-4wa4ab:3 5;
  --border-line-chart-line-join-nan7pn:round;
  --border-line-chart-width-nj0hc5:2px;
  --border-panel-header-width-6fmoo4:2px;
  --border-panel-top-width-00mwcl:1px;
  --border-radius-alert-n0skwy:12px;
  --border-radius-badge-x5oehc:4px;
  --border-radius-button-axewzv:20px;
  --border-radius-calendar-day-focus-ring-a8mlqd:3px;
  --border-radius-code-editor-fuck6b:8px;
  --border-radius-container-gh9ysk:16px;
  --border-radius-control-circular-focus-ring-qawzn7:4px;
  --border-radius-control-default-focus-ring-7661kz:4px;
  --border-radius-dropdown-ujknaf:8px;
  --border-radius-flashbar-i7jm55:12px;
  --border-radius-item-u2ibpi:8px;
  --border-radius-input-txq8zy:8px;
  --border-radius-popover-8g1bwn:8px;
  --border-radius-tabs-focus-ring-j968on:20px;
  --border-radius-tiles-kl8mtb:8px;
  --border-radius-token-wohc9e:8px;
  --border-radius-tutorial-panel-item-5wuccf:8px;
  --border-table-sticky-width-h1tlxy:2px;
  --border-link-focus-ring-outline-k2wccv:0;
  --border-link-focus-ring-shadow-spread-nv4ahb:2px;
  --motion-duration-extra-slow-8ks5rs:270ms;
  --motion-duration-fast-nk2ap0:90ms;
  --motion-duration-moderate-vgx1te:135ms;
  --motion-duration-refresh-only-fast-addxjd:115ms;
  --motion-duration-refresh-only-medium-5flen2:165ms;
  --motion-duration-refresh-only-slow-6gdedh:250ms;
  --motion-duration-rotate-180-ofa1ir:135ms;
  --motion-duration-rotate-90-ux18sr:135ms;
  --motion-duration-show-paced-7wspqf:180ms;
  --motion-duration-show-quick-tyaalm:135ms;
  --motion-duration-slow-0273ow:180ms;
  --motion-duration-transition-quick-x85tae:90ms;
  --motion-duration-transition-show-paced-ai4wlf:180ms;
  --motion-easing-ease-out-quart-z687ml:cubic-bezier(0.165, 0.84, 0.44, 1);
  --motion-easing-refresh-only-a-sy0g8m:cubic-bezier(0, 0, 0, 1);
  --motion-easing-refresh-only-b-nspng9:cubic-bezier(1, 0, 0.83, 1);
  --motion-easing-refresh-only-c-ic6jr0:cubic-bezier(0.84, 0, 0.16, 1);
  --motion-easing-rotate-180-3rbbga:cubic-bezier(0.165, 0.84, 0.44, 1);
  --motion-easing-rotate-90-eoew89:cubic-bezier(0.165, 0.84, 0.44, 1);
  --motion-easing-show-paced-wj4ybn:ease-out;
  --motion-easing-show-quick-1fcgbv:ease-out;
  --motion-easing-transition-quick-lukbd8:linear;
  --motion-easing-transition-show-paced-bg1hyg:ease-out;
  --motion-easing-responsive-5yj747:cubic-bezier(0, 0, 0, 1);
  --motion-easing-sticky-yt83ho:cubic-bezier(1, 0, 0.83, 1);
  --motion-easing-expressive-zns6oe:cubic-bezier(0.84, 0, 0.16, 1);
  --motion-duration-responsive-dvittz:115ms;
  --motion-duration-expressive-96gzp7:165ms;
  --motion-duration-complex-8zbvzy:250ms;
  --motion-keyframes-fade-in-9ud20b:awsui-fade-in-35003c;
  --motion-keyframes-fade-out-1ayoyt:awsui-fade-out-35003c;
  --motion-keyframes-status-icon-error-ti988k:awsui-status-icon-error-35003c;
  --motion-keyframes-scale-popup-3oea16:awsui-scale-popup-35003c;
  --size-calendar-grid-width-lxsx0p:238px;
  --size-control-n9i4yo:16px;
  --size-icon-big-cu55wt:32px;
  --size-icon-large-9vh494:48px;
  --size-icon-medium-fvz0oi:20px;
  --size-icon-normal-j1z5fn:16px;
  --size-table-selection-horizontal-o8c8gs:40px;
  --size-vertical-input-js5hfh:34px;
  --size-vertical-panel-icon-offset-ti40n4:15px;
  --space-alert-action-left-hi50rw:12px;
  --space-alert-horizontal-ulbnu9:16px;
  --space-alert-message-right-qop1p1:4px;
  --space-alert-vertical-jbva6z:8px;
  --space-button-focus-outline-gutter-4vaedu:4px;
  --space-button-icon-focus-outline-gutter-vertical-ysj0na:0px;
  --space-button-icon-only-horizontal-nidslr:7px;
  --space-button-inline-icon-focus-outline-gutter-f9qjev:0px;
  --space-button-modal-dismiss-vertical-r4lx2k:0px;
  --space-calendar-grid-focus-outline-gutter-v3dwvl:-5px;
  --space-calendar-grid-selected-focus-outline-gutter-gqgjqz:-5px;
  --space-card-horizontal-di7llb:20px;
  --space-code-editor-status-focus-outline-gutter-eivtla:-7px;
  --space-container-content-top-fsd8nr:0px;
  --space-container-header-vertical-zsjhel:12px;
  --space-container-horizontal-tlw03i:20px;
  --space-content-header-padding-bottom-nvqytg:24px;
  --space-dark-header-overlap-distance-hfejul:36px;
  --space-expandable-section-icon-offset-top-svbirl:4px;
  --space-field-horizontal-89h2yr:12px;
  --space-field-icon-offset-25xih0:36px;
  --space-filtering-token-dismiss-button-focus-outline-gutter-itb5fd:-5px;
  --space-filtering-token-operation-select-focus-outline-gutter-awvnzl:-5px;
  --space-flashbar-action-left-y2z8zt:12px;
  --space-flashbar-dismiss-right-cbaywa:0px;
  --space-flashbar-horizontal-ouyk4r:16px;
  --space-grid-gutter-0subky:20px;
  --space-layout-content-bottom-kg9l42:40px;
  --space-layout-toggle-diameter-hzvqtj:40px;
  --space-layout-toggle-padding-axl9jr:20px;
  --space-modal-content-bottom-cecclv:16px;
  --space-modal-horizontal-ah0xld:20px;
  --space-panel-nav-left-o5fvw3:28px;
  --space-panel-side-left-8ozqve:28px;
  --space-panel-side-right-kev4k9:24px;
  --space-panel-split-top-0y4u4f:20px;
  --space-segmented-control-focus-outline-gutter-99x5b9:4px;
  --space-table-header-focus-outline-gutter-rbx7m7:0px;
  --space-tabs-content-top-mn4c9x:12px;
  --space-table-horizontal-mj7h0h:20px;
  --space-table-header-horizontal-et2ko8:0px;
  --space-table-content-bottom-pg6yvn:4px;
  --space-table-content-top-t15dd1:12px;
  --space-table-embedded-content-bottom-7vc5vt:0px;
  --space-table-embedded-header-top-zf07fk:0px;
  --space-table-footer-horizontal-8o6sk8:0px;
  --space-tabs-focus-outline-gutter-64zu5e:-8px;
  --space-text-grid-vertical-tj8m1c:16px;
  --space-scaled-2x-xxs-t5qzmf:4px;
  --space-scaled-2x-s-9svxjy:12px;
  --space-scaled-2x-l-yejodm:20px;
  --space-scaled-2x-xl-x30c9w:24px;
  --space-scaled-2x-xxxl-lbp35j:40px;
  --space-scaled-none-cnv7sd:0;
  --space-scaled-xxxs-b6dm8t:2px;
  --space-scaled-xxs-95dhkm:4px;
  --space-scaled-xs-6859qs:8px;
  --space-scaled-s-cu1hzn:12px;
  --space-scaled-m-pv0fmt:16px;
  --space-scaled-l-t03y3z:20px;
  --space-scaled-xl-w2t504:24px;
  --space-scaled-xxl-pfgv96:32px;
  --space-scaled-xxxl-ffokcj:40px;
  --space-static-xxxs-k3qmdh:2px;
  --space-static-xxs-jy4jgd:4px;
  --space-static-xs-9adq92:8px;
  --space-static-s-phfs7n:12px;
  --space-static-m-pc0uz7:16px;
  --space-static-l-t7yjdj:20px;
  --space-static-xl-g2lpmk:24px;
  --space-static-xxl-m6qwv3:32px;
  --space-static-xxxl-66ty0h:40px;
  --space-none-esmv0r:0;
  --space-xxxs-k2w98v:2px;
  --space-xxs-ynfts5:4px;
  --space-xs-rsr2qu:8px;
  --space-s-hv8c1d:12px;
  --space-m-17eucw:16px;
  --space-l-4vl6xu:20px;
  --space-xl-a39hup:24px;
  --space-xxl-2nvmf1:32px;
  --space-xxxl-bo9z7f:40px;
  --shadow-container-fx563k:0px 0px 1px 1px #e9ebed, 0px 1px 8px 2px rgba(0, 7, 22, 0.12);
  --shadow-container-stacked-jfo2nn:-1px 1px 1px 0px #e9ebed, 1px 1px 1px 0px #e9ebed, 0px 9px 8px -7px rgb(0 7 22 / 12%), 8px 0px 8px -7px rgb(0 7 22 / 12%), -8px 0px 8px -7px rgb(0 7 22 / 12%);
  --shadow-container-active-l4kuxc:0px 1px 1px 1px #e9ebed, 0px 6px 36px #0007161a;
  --shadow-dropdown-99h0gz:0px 4px 20px 1px rgba(0, 7, 22, 0.10);
  --shadow-dropup-11esug:0px 4px 20px 1px rgba(0, 7, 22, 0.10);
  --shadow-flash-collapsed-d4s4kz:0px 4px 4px rgba(0, 0, 0, 0.25);
  --shadow-flash-sticky-xplpiu:0px 6px 36px rgba(0, 7, 22, 0.10);
  --shadow-modal-3qhlqt:0px 4px 20px 1px rgba(0, 7, 22, 0.10);
  --shadow-panel-gp3bf2:0px 1px 1px 1px #e9ebed, 0px 6px 36px rgba(0, 7, 22, 0.10);
  --shadow-panel-toggle-3fk0ch:0px 6px 12px 1px rgba(0, 7, 22, 0.12);
  --shadow-popover-kzy6rd:0px 4px 20px 1px rgba(0, 7, 22, 0.10);
  --shadow-split-bottom-otfetg:0px -36px 36px -36px rgba(0, 7, 22, 0.10);
  --shadow-split-side-qv353u:-1px 0px 1px 0px #e9ebed, -36px 6px 36px -36px rgba(0, 7, 22, 0.10);
  --shadow-sticky-aijlo6:0px 4px 20px 1px rgba(0, 7, 22, 0.10);
  --shadow-sticky-embedded-qit8ba:0px 2px 0px 0px #e9ebed, 0px 16px 16px -12px rgba(0, 7, 22, 0.10);
}

.awsui-dark-mode:not(#\9) {
  --color-charts-red-300-taex3d:#d63f38;
  --color-charts-red-400-xo1w45:#ed5958;
  --color-charts-red-500-8et8sv:#fe6e73;
  --color-charts-red-600-ej8iox:#ff8a8a;
  --color-charts-red-700-pe7xt7:#ffa09e;
  --color-charts-red-800-9wqz57:#ffb3b0;
  --color-charts-red-900-fkiox3:#ffc4c0;
  --color-charts-red-1000-xhb2ma:#ffd2cf;
  --color-charts-red-1100-zkhjl8:#ffe0dd;
  --color-charts-red-1200-t42yl7:#ffecea;
  --color-charts-orange-300-uh1x0z:#c55305;
  --color-charts-orange-400-rg9ft0:#de6923;
  --color-charts-orange-500-6dcz8x:#f27c36;
  --color-charts-orange-600-psg5i7:#f89256;
  --color-charts-orange-700-6rlkwo:#fca572;
  --color-charts-orange-800-aqh9u5:#ffb68b;
  --color-charts-orange-900-71d364:#ffc6a4;
  --color-charts-orange-1000-p3xy9d:#ffd4bb;
  --color-charts-orange-1100-plp0ix:#ffe1cf;
  --color-charts-orange-1200-msf9v8:#ffede2;
  --color-charts-yellow-300-zgwohc:#977001;
  --color-charts-yellow-400-z6s56e:#b08400;
  --color-charts-yellow-500-f52bpo:#c59600;
  --color-charts-yellow-600-gzedvz:#d3a61c;
  --color-charts-yellow-700-uq1jq9:#dfb52c;
  --color-charts-yellow-800-esbvam:#eac33a;
  --color-charts-yellow-900-e2ugyo:#f1cf65;
  --color-charts-yellow-1000-myf1mq:#f7db8a;
  --color-charts-yellow-1100-fi5c3g:#fce5a8;
  --color-charts-yellow-1200-faeu24:#ffefc9;
  --color-charts-green-300-8q7o65:#48851a;
  --color-charts-green-400-b5efwo:#5a9b29;
  --color-charts-green-500-o7urau:#69ae34;
  --color-charts-green-600-8e3las:#7dbd4c;
  --color-charts-green-700-h4agmh:#8fca61;
  --color-charts-green-800-jp1xwf:#9fd673;
  --color-charts-green-900-czmqk3:#b2df8d;
  --color-charts-green-1000-wrs7fm:#c5e7a8;
  --color-charts-green-1100-d8mgjl:#d5efbe;
  --color-charts-green-1200-jyfc23:#e4f7d5;
  --color-charts-teal-300-egfaot:#018977;
  --color-charts-teal-400-ikctdb:#009d89;
  --color-charts-teal-500-v952sw:#00b09b;
  --color-charts-teal-600-9ffc3q:#40bfa9;
  --color-charts-teal-700-nn7a5u:#5fccb7;
  --color-charts-teal-800-97l4n7:#77d7c3;
  --color-charts-teal-900-zq1u0w:#94e0d0;
  --color-charts-teal-1000-u4e4vv:#ace9db;
  --color-charts-teal-1100-cdt1d8:#c2f0e6;
  --color-charts-teal-1200-3ozaok:#d7f7f0;
  --color-charts-blue-1-300-4x8rnx:#00819c;
  --color-charts-blue-1-400-owg3dt:#0497ba;
  --color-charts-blue-1-500-sl8chl:#08aad2;
  --color-charts-blue-1-600-3824a7:#44b9dd;
  --color-charts-blue-1-700-e3d0uw:#63c6e7;
  --color-charts-blue-1-800-vnypf9:#79d2f0;
  --color-charts-blue-1-900-rs4k5z:#98dcf5;
  --color-charts-blue-1-1000-wjr7hn:#b3e4f8;
  --color-charts-blue-1-1100-nmn22x:#caedfc;
  --color-charts-blue-1-1200-od4mlj:#ddf4ff;
  --color-charts-blue-2-300-6yvfip:#486de8;
  --color-charts-blue-2-400-iba8cu:#6384f5;
  --color-charts-blue-2-500-vtdvkt:#7698fe;
  --color-charts-blue-2-600-tpo4rj:#8ea9ff;
  --color-charts-blue-2-700-p6tu56:#a2b8ff;
  --color-charts-blue-2-800-08f3pp:#b1c5ff;
  --color-charts-blue-2-900-3aq93e:#c3d1ff;
  --color-charts-blue-2-1000-6b05hm:#d2dcff;
  --color-charts-blue-2-1100-0014sa:#dfe6ff;
  --color-charts-blue-2-1200-tzr398:#ecf0ff;
  --color-charts-purple-300-yatxj8:#8d59de;
  --color-charts-purple-400-d384pt:#a173ea;
  --color-charts-purple-500-skntta:#b088f5;
  --color-charts-purple-600-8iqvzu:#bf9bf9;
  --color-charts-purple-700-3qzy9s:#cbabfc;
  --color-charts-purple-800-ucyxda:#d6baff;
  --color-charts-purple-900-tlepdb:#dfc8ff;
  --color-charts-purple-1000-3pjp6l:#e8d5ff;
  --color-charts-purple-1100-t0m9qa:#efe2ff;
  --color-charts-purple-1200-003tqq:#f5edff;
  --color-charts-pink-300-0gug9u:#c64a70;
  --color-charts-pink-400-jh84a9:#d56889;
  --color-charts-pink-500-c97yed:#e07f9d;
  --color-charts-pink-600-rpo3pi:#eb92ad;
  --color-charts-pink-700-mshe2z:#f5a2bb;
  --color-charts-pink-800-bph464:#ffb0c8;
  --color-charts-pink-900-mvfm0y:#ffc1d4;
  --color-charts-pink-1000-kq8pu0:#ffd1de;
  --color-charts-pink-1100-pi0m3u:#ffdfe8;
  --color-charts-pink-1200-hukz4p:#ffecf1;
  --color-charts-status-critical-268let:#d63f38;
  --color-charts-status-high-vsd25n:#fe6e73;
  --color-charts-status-medium-371m8g:#f89256;
  --color-charts-status-low-lfwc29:#dfb52c;
  --color-charts-status-positive-2ozsqj:#69ae34;
  --color-charts-status-info-l8g84h:#08aad2;
  --color-charts-threshold-negative-y7lrhf:#eb6f6f;
  --color-charts-threshold-positive-42ybvt:#29ad32;
  --color-charts-threshold-info-kcjduw:#89bdee;
  --color-charts-threshold-neutral-k14cuc:#8d99a8;
  --color-charts-line-grid-6wil4m:#414d5c;
  --color-charts-line-tick-yidpvu:#414d5c;
  --color-charts-line-axis-yo865k:#414d5c;
  --color-charts-palette-categorical-1-bonb16:#486de8;
  --color-charts-palette-categorical-2-emtquh:#e07f9d;
  --color-charts-palette-categorical-3-imrqvq:#018977;
  --color-charts-palette-categorical-4-g31h69:#b088f5;
  --color-charts-palette-categorical-5-64f3p2:#c55305;
  --color-charts-palette-categorical-6-msr86m:#8ea9ff;
  --color-charts-palette-categorical-7-kdjms7:#ffb0c8;
  --color-charts-palette-categorical-8-79776a:#40bfa9;
  --color-charts-palette-categorical-9-s4w3dd:#d6baff;
  --color-charts-palette-categorical-10-cuicpx:#f89256;
  --color-charts-palette-categorical-11-no2uu5:#c3d1ff;
  --color-charts-palette-categorical-12-jq9rcw:#ffdfe8;
  --color-charts-palette-categorical-13-4cawsz:#94e0d0;
  --color-charts-palette-categorical-14-i1n5cl:#efe2ff;
  --color-charts-palette-categorical-15-9pgabq:#ffc6a4;
  --color-charts-palette-categorical-16-wnvn1q:#ecf0ff;
  --color-charts-palette-categorical-17-y2duhb:#d56889;
  --color-charts-palette-categorical-18-xehfp2:#d7f7f0;
  --color-charts-palette-categorical-19-v1x5n3:#a173ea;
  --color-charts-palette-categorical-20-q5g6qv:#ffede2;
  --color-charts-palette-categorical-21-z2fito:#7698fe;
  --color-charts-palette-categorical-22-mdbc68:#f5a2bb;
  --color-charts-palette-categorical-23-9iyat0:#00b09b;
  --color-charts-palette-categorical-24-17kvjc:#cbabfc;
  --color-charts-palette-categorical-25-c2vakt:#f27c36;
  --color-charts-palette-categorical-26-g98tv5:#b1c5ff;
  --color-charts-palette-categorical-27-lo8vzl:#ffd1de;
  --color-charts-palette-categorical-28-2ppjnc:#77d7c3;
  --color-charts-palette-categorical-29-68gzom:#e8d5ff;
  --color-charts-palette-categorical-30-9pbejo:#ffb68b;
  --color-charts-palette-categorical-31-8odgjs:#dfe6ff;
  --color-charts-palette-categorical-32-xug28a:#c64a70;
  --color-charts-palette-categorical-33-cr6l8a:#c2f0e6;
  --color-charts-palette-categorical-34-209022:#8d59de;
  --color-charts-palette-categorical-35-twgdku:#ffe1cf;
  --color-charts-palette-categorical-36-g2mar5:#6384f5;
  --color-charts-palette-categorical-37-9deaq2:#eb92ad;
  --color-charts-palette-categorical-38-5fg2jv:#009d89;
  --color-charts-palette-categorical-39-c8yeil:#bf9bf9;
  --color-charts-palette-categorical-40-vlagy9:#de6923;
  --color-charts-palette-categorical-41-o49tuv:#a2b8ff;
  --color-charts-palette-categorical-42-5irquw:#ffc1d4;
  --color-charts-palette-categorical-43-8pxo9j:#5fccb7;
  --color-charts-palette-categorical-44-w3yd1t:#dfc8ff;
  --color-charts-palette-categorical-45-32a8x6:#fca572;
  --color-charts-palette-categorical-46-4ikr9l:#d2dcff;
  --color-charts-palette-categorical-47-wg3s7g:#ffecf1;
  --color-charts-palette-categorical-48-7no3ly:#ace9db;
  --color-charts-palette-categorical-49-qy0tr5:#f5edff;
  --color-charts-palette-categorical-50-gkixcb:#ffd4bb;
  --color-background-button-link-active-t13dka:#354150;
  --color-background-button-link-hover-69sbpz:#192534;
  --color-background-button-normal-active-9lyks0:#354150;
  --color-background-button-normal-default-syw2p1:#0f1b2a;
  --color-background-button-normal-disabled-z5vd4i:#0f1b2a;
  --color-background-button-normal-hover-c3qbgm:#192534;
  --color-background-button-primary-active-vg0knj:#539fe5;
  --color-background-button-primary-default-pwn1g1:#539fe5;
  --color-background-button-primary-disabled-eon7km:#354150;
  --color-background-button-primary-hover-qu1zio:#89bdee;
  --color-background-calendar-today-762czq:#354150;
  --color-background-cell-shaded-26ubfj:#232f3e;
  --color-background-code-editor-gutter-active-line-default-p2p8kl:#7d8998;
  --color-background-code-editor-gutter-active-line-error-lfh0u4:#eb6f6f;
  --color-background-code-editor-gutter-default-l5k65m:#192534;
  --color-background-code-editor-loading-4cqamf:#192534;
  --color-background-code-editor-pane-item-hover-lrigi6:#354150;
  --color-background-code-editor-status-bar-qgcwj5:#192534;
  --color-background-container-content-i8i4a0:#0f1b2a;
  --color-background-container-header-4flbq5:#0f1b2a;
  --color-background-control-checked-9admlu:#539fe5;
  --color-background-control-default-4pa05r:#0f1b2a;
  --color-background-control-disabled-zplipy:#354150;
  --color-background-dropdown-item-default-fzdqsg:#192534;
  --color-background-dropdown-item-filter-match-6ac2mw:#354150;
  --color-background-dropdown-item-hover-mqsxkv:#354150;
  --color-background-dropdown-item-selected-8l4mpk:#192534;
  --color-background-input-default-u57378:#0f1b2a;
  --color-background-input-disabled-wu9j6u:#192534;
  --color-background-item-selected-ebt4bi:#192534;
  --color-background-layout-main-sfhm4y:#0f1b2a;
  --color-background-layout-panel-content-808qum:#0f1b2a;
  --color-background-layout-panel-hover-0bg3gq:#354150;
  --color-background-layout-toggle-selected-active-9w7pk3:#539fe5;
  --color-background-layout-toggle-selected-default-e1giv3:#539fe5;
  --color-background-layout-toggle-selected-hover-mes33s:#89bdee;
  --color-background-modal-overlay-17dapf:rgba(0, 7, 22, 0.7);
  --color-background-notification-grey-ua4ep5:#5f6b7a;
  --color-background-popover-mn5o9k:#192534;
  --color-background-progress-bar-content-default-r4tyyh:#539fe5;
  --color-background-progress-bar-layout-default-9xbpym:#354150;
  --color-background-segment-active-bl0u6r:#539fe5;
  --color-background-segment-default-5k37bj:#0f1b2a;
  --color-background-segment-disabled-hgka10:#0f1b2a;
  --color-background-segment-hover-s3f27a:#0f1b2a;
  --color-background-status-error-pe1hux:#1a0000;
  --color-background-status-info-usb30e:#00142b;
  --color-background-status-success-xnxxn4:#001a02;
  --color-background-status-warning-zz4oi6:#0f1b2a;
  --color-background-table-header-i1pa1g:#0f1b2a;
  --color-background-tiles-disabled-byt5lx:#192534;
  --color-background-toggle-checked-disabled-07lf06:#033160;
  --color-background-toggle-default-w4ftus:#7d8998;
  --color-border-button-normal-active-zbmaft:#89bdee;
  --color-border-button-normal-default-djv8f5:#539fe5;
  --color-border-button-normal-disabled-262a0x:#5f6b7a;
  --color-border-button-normal-hover-e1d0kh:#89bdee;
  --color-border-button-primary-disabled-6gwabt:#354150;
  --color-border-calendar-grid-selected-focus-ring-cxqa0s:#0f1b2a;
  --color-border-code-editor-default-l9vmjf:#5f6b7a;
  --color-border-code-editor-pane-item-hover-xephrt:#5f6b7a;
  --color-border-control-checked-4xvndc:#539fe5;
  --color-border-control-disabled-vx8bco:#354150;
  --color-border-divider-default-7s2wjw:#414d5c;
  --color-border-divider-panel-bottom-zrjpck:rgba(0, 7, 22, 1);
  --color-border-divider-panel-side-yjkfcr:#414d5c;
  --color-border-dropdown-container-tyn0js:#5f6b7a;
  --color-border-dropdown-group-0utpsr:#414d5c;
  --color-border-dropdown-item-default-faosd8:#414d5c;
  --color-border-dropdown-item-hover-tyzq9m:#5f6b7a;
  --color-border-input-default-e47bkv:#5f6b7a;
  --color-border-input-disabled-x2lopj:#192534;
  --color-border-item-focused-ap3b6s:#539fe5;
  --color-border-item-placeholder-jgzhnb:#539fe5;
  --color-border-item-selected-ppkssz:#539fe5;
  --color-border-layout-wf5alz:#354150;
  --color-border-popover-5pygk7:#5f6b7a;
  --color-border-segment-active-kdy5tm:#d1d5db;
  --color-border-segment-default-091088:#d1d5db;
  --color-border-segment-disabled-6v5zlt:#d1d5db;
  --color-border-segment-hover-9notuw:#d1d5db;
  --color-border-status-error-mfrddc:#eb6f6f;
  --color-border-status-info-546i7i:#539fe5;
  --color-border-status-success-cqi45f:#29ad32;
  --color-border-status-warning-hg85ut:#8d99a8;
  --color-border-divider-interactive-default-rcasvw:#414d5c;
  --color-border-tabs-divider-69fx5i:#414d5c;
  --color-border-tabs-shadow-4lmh2z:rgba(0, 7, 22, 1);
  --color-border-tabs-underline-9bcfzu:#539fe5;
  --color-border-tiles-disabled-dp4h7y:#192534;
  --color-border-tutorial-4ioksw:#414d5c;
  --color-foreground-control-default-7ajdem:#000716;
  --color-foreground-control-disabled-v6a97u:#0f1b2a;
  --color-shadow-default-3se70f:rgba(0, 7, 22, 1);
  --color-text-accent-s1eqko:#539fe5;
  --color-text-body-default-ajf1h5:#d1d5db;
  --color-text-body-secondary-fdstdf:#d1d5db;
  --color-text-breadcrumb-current-mum16s:#7d8998;
  --color-text-breadcrumb-icon-mlugp6:#5f6b7a;
  --color-text-button-inline-icon-default-w65lqn:#539fe5;
  --color-text-button-inline-icon-disabled-gfhvz7:#5f6b7a;
  --color-text-button-inline-icon-hover-wd5icp:#89bdee;
  --color-text-button-normal-active-6obrzh:#89bdee;
  --color-text-button-normal-default-u8647m:#539fe5;
  --color-text-button-normal-hover-kxmvcc:#89bdee;
  --color-text-link-button-normal-default-2yrp9u:#539fe5;
  --color-text-link-button-normal-hover-gz8y5b:#89bdee;
  --color-text-link-button-normal-active-8lhs8f:#89bdee;
  --color-text-button-primary-active-vems2x:#000716;
  --color-text-button-primary-default-340wh6:#000716;
  --color-text-button-primary-hover-vzqjpi:#000716;
  --color-text-calendar-day-hover-graajh:#d1d5db;
  --color-text-calendar-month-elf7e9:#8d99a8;
  --color-text-code-editor-gutter-active-line-3r48bk:#000716;
  --color-text-code-editor-gutter-default-q46j79:#d1d5db;
  --color-text-code-editor-status-bar-disabled-6bhuub:#5f6b7a;
  --color-text-code-editor-tab-button-error-vez6ll:#000716;
  --color-text-column-header-u472sv:#9ba7b6;
  --color-text-column-sorting-icon-q5sr09:#9ba7b6;
  --color-text-control-disabled-3z3eul:#5f6b7a;
  --color-text-counter-jwdegc:#8d99a8;
  --color-text-disabled-a2nkh4:#5f6b7a;
  --color-text-dropdown-footer-rj6jvf:#8d99a8;
  --color-text-dropdown-group-label-eaqlcl:#8d99a8;
  --color-text-dropdown-header-6e05nm:#fbfbfb;
  --color-text-dropdown-item-default-7tg9p2:#d1d5db;
  --color-text-dropdown-item-dimmed-jyqdrs:#5f6b7a;
  --color-text-dropdown-item-disabled-varol7:#5f6b7a;
  --color-text-dropdown-item-filter-match-x302if:#89bdee;
  --color-text-dropdown-item-highlighted-oaabyk:#e9ebed;
  --color-text-dropdown-item-secondary-0me7js:#8d99a8;
  --color-text-dropdown-item-secondary-hover-7ii1ru:colorGrey300;
  --color-text-empty-fjv325:#d1d5db;
  --color-text-expandable-section-default-k03p1p:#fbfbfb;
  --color-text-expandable-section-hover-73g3du:#539fe5;
  --color-text-expandable-section-navigation-icon-default-lb9rm0:#d1d5db;
  --color-text-form-default-yd29ox:#d1d5db;
  --color-text-form-label-k3j0b4:#d1d5db;
  --color-text-form-secondary-ih9x7l:#8d99a8;
  --color-text-group-label-w9to2i:#8d99a8;
  --color-text-heading-default-jopz0f:#e9ebed;
  --color-text-heading-secondary-corkaj:#8d99a8;
  --color-text-icon-subtle-bz5b7h:#9ba7b6;
  --color-text-input-disabled-oawyzo:#5f6b7a;
  --color-text-input-placeholder-j53j3c:#7d8998;
  --color-text-input-placeholder-disabled-6928j8:#5f6b7a;
  --color-text-interactive-active-vol84d:#fbfbfb;
  --color-text-interactive-default-eg5fsa:#d1d5db;
  --color-text-interactive-disabled-3pbb07:#5f6b7a;
  --color-text-interactive-hover-v3lasm:#fbfbfb;
  --color-text-inverted-821sko:#000716;
  --color-text-label-gxskdy:#d1d5db;
  --color-text-layout-toggle-selected-tzwifo:#000716;
  --color-text-link-default-5f186r:#539fe5;
  --color-text-link-hover-kololx:#89bdee;
  --color-text-link-primary-underline-ga7rq8:#539fe5;
  --color-text-pagination-page-number-active-disabled-sjs1t5:#5f6b7a;
  --color-text-pagination-page-number-default-z6gnaa:#9ba7b6;
  --color-text-segment-active-gc2jjl:#000716;
  --color-text-segment-default-bf7rka:#d1d5db;
  --color-text-segment-hover-5pccvg:#539fe5;
  --color-text-small-le1y42:#8d99a8;
  --color-text-status-error-5sesl6:#eb6f6f;
  --color-text-status-inactive-5ei55p:#8d99a8;
  --color-text-status-info-utupcz:#539fe5;
  --color-text-status-success-0fzhv9:#29ad32;
  --color-text-status-warning-27nlyh:#eb6f6f;
  --color-text-top-navigation-title-oypxe3:#fbfbfb;
  --color-board-placeholder-active-vaxzdf:#5f6b7a;
  --color-board-placeholder-hover-v1s7kq:#0972d3;
  --shadow-container-fx563k:0px 1px 8px 2px rgba(0, 7, 22, 0.6);
  --shadow-container-stacked-jfo2nn:0px 9px 8px -7px rgb(0 7 22 / 60%), 8px 0px 8px -7px rgb(0 7 22 / 60%), -8px 0px 8px -7px rgb(0 7 22 / 60%);
  --shadow-container-active-l4kuxc:0px 1px 1px 1px #192534, 0px 6px 36px #000716;
  --shadow-dropdown-99h0gz:0px 4px 20px 1px rgba(0, 7, 22, 1);
  --shadow-dropup-11esug:0px 4px 20px 1px rgba(0, 7, 22, 1);
  --shadow-flash-sticky-xplpiu:0px 6px 36px rgba(0, 7, 22, 1);
  --shadow-modal-3qhlqt:0px 4px 20px 1px rgba(0, 7, 22, 1);
  --shadow-panel-gp3bf2:0px 1px 1px 1px #192534, 0px 6px 36px rgba(0, 7, 22, 1);
  --shadow-panel-toggle-3fk0ch:0px 6px 12px 1px rgba(0, 7, 22, 1);
  --shadow-popover-kzy6rd:0px 4px 20px 1px rgba(0, 7, 22, 1);
  --shadow-split-bottom-otfetg:0px -36px 36px -36px rgba(0, 7, 22, 1);
  --shadow-split-side-qv353u:-1px 0px 1px 0px #192534, -36px 6px 36px -36px rgba(0, 7, 22, 1);
  --shadow-sticky-aijlo6:0px 4px 20px 1px rgba(0, 7, 22, 1);
  --shadow-sticky-embedded-qit8ba:0px 2px 0px 0px #414d5c, 0px 16px 16px -12px rgba(0, 7, 22, 1);
}

.awsui-compact-mode:not(#\9) {
  --size-vertical-input-js5hfh:30px;
  --size-vertical-panel-icon-offset-ti40n4:13px;
  --space-alert-vertical-jbva6z:4px;
  --space-button-icon-only-horizontal-nidslr:5px;
  --space-content-header-padding-bottom-nvqytg:12px;
  --space-dark-header-overlap-distance-hfejul:32px;
  --space-expandable-section-icon-offset-top-svbirl:0;
  --space-grid-gutter-0subky:16px;
  --space-layout-content-bottom-kg9l42:24px;
  --space-layout-toggle-padding-axl9jr:16px;
  --space-modal-content-bottom-cecclv:8px;
  --space-panel-side-right-kev4k9:20px;
  --space-panel-split-top-0y4u4f:16px;
  --space-table-header-focus-outline-gutter-rbx7m7:-1px;
  --space-tabs-content-top-mn4c9x:8px;
  --space-table-content-top-t15dd1:8px;
  --space-text-grid-vertical-tj8m1c:12px;
  --space-scaled-2x-xxs-t5qzmf:0;
  --space-scaled-2x-s-9svxjy:4px;
  --space-scaled-2x-l-yejodm:12px;
  --space-scaled-2x-xl-x30c9w:16px;
  --space-scaled-2x-xxxl-lbp35j:24px;
  --space-scaled-xxxs-b6dm8t:0;
  --space-scaled-xxs-95dhkm:2px;
  --space-scaled-xs-6859qs:4px;
  --space-scaled-s-cu1hzn:8px;
  --space-scaled-m-pv0fmt:12px;
  --space-scaled-l-t03y3z:16px;
  --space-scaled-xl-w2t504:20px;
  --space-scaled-xxl-pfgv96:24px;
  --space-scaled-xxxl-ffokcj:32px;
}

.awsui-motion-disabled:not(#\9) {
  --motion-duration-extra-slow-8ks5rs:0ms;
  --motion-duration-fast-nk2ap0:0ms;
  --motion-duration-moderate-vgx1te:0ms;
  --motion-duration-refresh-only-fast-addxjd:0ms;
  --motion-duration-refresh-only-medium-5flen2:0ms;
  --motion-duration-refresh-only-slow-6gdedh:0ms;
  --motion-duration-rotate-180-ofa1ir:0ms;
  --motion-duration-rotate-90-ux18sr:0ms;
  --motion-duration-show-paced-7wspqf:0ms;
  --motion-duration-show-quick-tyaalm:0ms;
  --motion-duration-slow-0273ow:0ms;
  --motion-duration-transition-quick-x85tae:0ms;
  --motion-duration-transition-show-paced-ai4wlf:0ms;
  --motion-duration-responsive-dvittz:0ms;
  --motion-duration-expressive-96gzp7:0ms;
  --motion-duration-complex-8zbvzy:0ms;
}

.awsui-context-compact-table:not(#\9) {
  --size-vertical-input-js5hfh:30px;
  --space-alert-vertical-jbva6z:4px;
  --space-layout-toggle-padding-axl9jr:16px;
  --space-panel-side-right-kev4k9:20px;
  --space-panel-split-top-0y4u4f:16px;
  --space-tabs-content-top-mn4c9x:8px;
  --space-table-content-top-t15dd1:8px;
  --space-text-grid-vertical-tj8m1c:12px;
  --space-scaled-xxxs-b6dm8t:0;
  --space-scaled-xxs-95dhkm:2px;
  --space-scaled-xs-6859qs:4px;
  --space-scaled-s-cu1hzn:8px;
  --space-scaled-m-pv0fmt:12px;
  --space-scaled-l-t03y3z:16px;
  --space-scaled-xl-w2t504:20px;
  --space-scaled-xxl-pfgv96:24px;
  --space-scaled-xxxl-ffokcj:32px;
}

.awsui-context-top-navigation:not(#\9) {
  --color-background-button-link-active-t13dka:#354150;
  --color-background-button-link-hover-69sbpz:#192534;
  --color-background-button-normal-active-9lyks0:#354150;
  --color-background-button-normal-default-syw2p1:#0f1b2a;
  --color-background-button-normal-disabled-z5vd4i:#0f1b2a;
  --color-background-button-normal-hover-c3qbgm:#192534;
  --color-background-button-primary-active-vg0knj:#539fe5;
  --color-background-button-primary-default-pwn1g1:#539fe5;
  --color-background-button-primary-disabled-eon7km:#354150;
  --color-background-button-primary-hover-qu1zio:#89bdee;
  --color-background-calendar-today-762czq:#354150;
  --color-background-cell-shaded-26ubfj:#232f3e;
  --color-background-code-editor-gutter-active-line-default-p2p8kl:#7d8998;
  --color-background-code-editor-gutter-active-line-error-lfh0u4:#eb6f6f;
  --color-background-code-editor-gutter-default-l5k65m:#192534;
  --color-background-code-editor-loading-4cqamf:#192534;
  --color-background-code-editor-pane-item-hover-lrigi6:#354150;
  --color-background-code-editor-status-bar-qgcwj5:#192534;
  --color-background-container-content-i8i4a0:#0f1b2a;
  --color-background-container-header-4flbq5:#0f1b2a;
  --color-background-control-checked-9admlu:#539fe5;
  --color-background-control-default-4pa05r:#0f1b2a;
  --color-background-control-disabled-zplipy:#354150;
  --color-background-dropdown-item-default-fzdqsg:#0f1b2a;
  --color-background-dropdown-item-filter-match-6ac2mw:#354150;
  --color-background-dropdown-item-hover-mqsxkv:#354150;
  --color-background-dropdown-item-selected-8l4mpk:#192534;
  --color-background-input-default-u57378:#0f1b2a;
  --color-background-input-disabled-wu9j6u:#192534;
  --color-background-item-selected-ebt4bi:#192534;
  --color-background-layout-main-sfhm4y:#0f1b2a;
  --color-background-layout-panel-content-808qum:#0f1b2a;
  --color-background-layout-panel-hover-0bg3gq:#354150;
  --color-background-layout-toggle-selected-active-9w7pk3:#539fe5;
  --color-background-layout-toggle-selected-default-e1giv3:#539fe5;
  --color-background-layout-toggle-selected-hover-mes33s:#89bdee;
  --color-background-modal-overlay-17dapf:rgba(0, 7, 22, 0.7);
  --color-background-notification-grey-ua4ep5:#5f6b7a;
  --color-background-popover-mn5o9k:#192534;
  --color-background-progress-bar-content-default-r4tyyh:#539fe5;
  --color-background-progress-bar-layout-default-9xbpym:#354150;
  --color-background-segment-active-bl0u6r:#539fe5;
  --color-background-segment-default-5k37bj:#0f1b2a;
  --color-background-segment-disabled-hgka10:#0f1b2a;
  --color-background-segment-hover-s3f27a:#0f1b2a;
  --color-background-status-error-pe1hux:#1a0000;
  --color-background-status-info-usb30e:#00142b;
  --color-background-status-success-xnxxn4:#001a02;
  --color-background-status-warning-zz4oi6:#0f1b2a;
  --color-background-table-header-i1pa1g:#0f1b2a;
  --color-background-tiles-disabled-byt5lx:#192534;
  --color-background-toggle-checked-disabled-07lf06:#033160;
  --color-background-toggle-default-w4ftus:#7d8998;
  --color-border-button-normal-active-zbmaft:#89bdee;
  --color-border-button-normal-default-djv8f5:#539fe5;
  --color-border-button-normal-disabled-262a0x:#5f6b7a;
  --color-border-button-normal-hover-e1d0kh:#89bdee;
  --color-border-button-primary-disabled-6gwabt:#354150;
  --color-border-calendar-grid-selected-focus-ring-cxqa0s:#0f1b2a;
  --color-border-code-editor-default-l9vmjf:#5f6b7a;
  --color-border-code-editor-pane-item-hover-xephrt:#5f6b7a;
  --color-border-control-checked-4xvndc:#539fe5;
  --color-border-control-disabled-vx8bco:#354150;
  --color-border-divider-default-7s2wjw:#414d5c;
  --color-border-divider-panel-bottom-zrjpck:rgba(0, 7, 22, 1);
  --color-border-divider-panel-side-yjkfcr:#414d5c;
  --color-border-dropdown-container-tyn0js:#5f6b7a;
  --color-border-dropdown-group-0utpsr:#414d5c;
  --color-border-dropdown-item-default-faosd8:#414d5c;
  --color-border-dropdown-item-hover-tyzq9m:#5f6b7a;
  --color-border-input-default-e47bkv:#5f6b7a;
  --color-border-input-disabled-x2lopj:#192534;
  --color-border-item-focused-ap3b6s:#539fe5;
  --color-border-item-placeholder-jgzhnb:#539fe5;
  --color-border-item-selected-ppkssz:#539fe5;
  --color-border-layout-wf5alz:#354150;
  --color-border-popover-5pygk7:#5f6b7a;
  --color-border-segment-active-kdy5tm:#d1d5db;
  --color-border-segment-default-091088:#d1d5db;
  --color-border-segment-disabled-6v5zlt:#d1d5db;
  --color-border-segment-hover-9notuw:#d1d5db;
  --color-border-status-error-mfrddc:#eb6f6f;
  --color-border-status-info-546i7i:#539fe5;
  --color-border-status-success-cqi45f:#29ad32;
  --color-border-status-warning-hg85ut:#8d99a8;
  --color-border-divider-interactive-default-rcasvw:#414d5c;
  --color-border-tabs-divider-69fx5i:#414d5c;
  --color-border-tabs-shadow-4lmh2z:rgba(0, 7, 22, 1);
  --color-border-tabs-underline-9bcfzu:#539fe5;
  --color-border-tiles-disabled-dp4h7y:#192534;
  --color-border-tutorial-4ioksw:#414d5c;
  --color-foreground-control-default-7ajdem:#000716;
  --color-foreground-control-disabled-v6a97u:#0f1b2a;
  --color-shadow-default-3se70f:rgba(0, 7, 22, 1);
  --color-text-accent-s1eqko:#539fe5;
  --color-text-body-default-ajf1h5:#d1d5db;
  --color-text-body-secondary-fdstdf:#d1d5db;
  --color-text-breadcrumb-current-mum16s:#7d8998;
  --color-text-breadcrumb-icon-mlugp6:#5f6b7a;
  --color-text-button-inline-icon-default-w65lqn:#539fe5;
  --color-text-button-inline-icon-disabled-gfhvz7:#5f6b7a;
  --color-text-button-inline-icon-hover-wd5icp:#89bdee;
  --color-text-button-normal-active-6obrzh:#89bdee;
  --color-text-button-normal-default-u8647m:#539fe5;
  --color-text-button-normal-hover-kxmvcc:#89bdee;
  --color-text-link-button-normal-default-2yrp9u:#539fe5;
  --color-text-link-button-normal-hover-gz8y5b:#89bdee;
  --color-text-link-button-normal-active-8lhs8f:#89bdee;
  --color-text-button-primary-active-vems2x:#000716;
  --color-text-button-primary-default-340wh6:#000716;
  --color-text-button-primary-hover-vzqjpi:#000716;
  --color-text-calendar-day-hover-graajh:#d1d5db;
  --color-text-calendar-month-elf7e9:#8d99a8;
  --color-text-code-editor-gutter-active-line-3r48bk:#000716;
  --color-text-code-editor-gutter-default-q46j79:#d1d5db;
  --color-text-code-editor-status-bar-disabled-6bhuub:#5f6b7a;
  --color-text-code-editor-tab-button-error-vez6ll:#000716;
  --color-text-column-header-u472sv:#9ba7b6;
  --color-text-column-sorting-icon-q5sr09:#9ba7b6;
  --color-text-control-disabled-3z3eul:#5f6b7a;
  --color-text-counter-jwdegc:#8d99a8;
  --color-text-disabled-a2nkh4:#5f6b7a;
  --color-text-dropdown-footer-rj6jvf:#8d99a8;
  --color-text-dropdown-group-label-eaqlcl:#8d99a8;
  --color-text-dropdown-header-6e05nm:#fbfbfb;
  --color-text-dropdown-item-default-7tg9p2:#d1d5db;
  --color-text-dropdown-item-dimmed-jyqdrs:#5f6b7a;
  --color-text-dropdown-item-disabled-varol7:#5f6b7a;
  --color-text-dropdown-item-filter-match-x302if:#89bdee;
  --color-text-dropdown-item-highlighted-oaabyk:#e9ebed;
  --color-text-dropdown-item-secondary-0me7js:#8d99a8;
  --color-text-dropdown-item-secondary-hover-7ii1ru:colorGrey300;
  --color-text-empty-fjv325:#d1d5db;
  --color-text-expandable-section-default-k03p1p:#fbfbfb;
  --color-text-expandable-section-hover-73g3du:#539fe5;
  --color-text-expandable-section-navigation-icon-default-lb9rm0:#d1d5db;
  --color-text-form-default-yd29ox:#d1d5db;
  --color-text-form-label-k3j0b4:#d1d5db;
  --color-text-form-secondary-ih9x7l:#8d99a8;
  --color-text-group-label-w9to2i:#8d99a8;
  --color-text-heading-default-jopz0f:#e9ebed;
  --color-text-heading-secondary-corkaj:#8d99a8;
  --color-text-icon-subtle-bz5b7h:#9ba7b6;
  --color-text-input-disabled-oawyzo:#5f6b7a;
  --color-text-input-placeholder-j53j3c:#7d8998;
  --color-text-input-placeholder-disabled-6928j8:#5f6b7a;
  --color-text-interactive-active-vol84d:#fbfbfb;
  --color-text-interactive-default-eg5fsa:#d1d5db;
  --color-text-interactive-disabled-3pbb07:#5f6b7a;
  --color-text-interactive-hover-v3lasm:#fbfbfb;
  --color-text-inverted-821sko:#000716;
  --color-text-label-gxskdy:#d1d5db;
  --color-text-layout-toggle-selected-tzwifo:#000716;
  --color-text-link-default-5f186r:#539fe5;
  --color-text-link-hover-kololx:#89bdee;
  --color-text-link-primary-underline-ga7rq8:#539fe5;
  --color-text-pagination-page-number-active-disabled-sjs1t5:#5f6b7a;
  --color-text-pagination-page-number-default-z6gnaa:#9ba7b6;
  --color-text-segment-active-gc2jjl:#000716;
  --color-text-segment-default-bf7rka:#d1d5db;
  --color-text-segment-hover-5pccvg:#539fe5;
  --color-text-small-le1y42:#8d99a8;
  --color-text-status-error-5sesl6:#eb6f6f;
  --color-text-status-inactive-5ei55p:#8d99a8;
  --color-text-status-info-utupcz:#539fe5;
  --color-text-status-success-0fzhv9:#29ad32;
  --color-text-status-warning-27nlyh:#eb6f6f;
  --color-text-top-navigation-title-oypxe3:#fbfbfb;
  --color-board-placeholder-active-vaxzdf:#5f6b7a;
  --color-board-placeholder-hover-v1s7kq:#0972d3;
}

.awsui-context-content-header:not(#\9) {
  --color-background-button-link-active-t13dka:#354150;
  --color-background-button-link-hover-69sbpz:#192534;
  --color-background-button-normal-active-9lyks0:#354150;
  --color-background-button-normal-default-syw2p1:#000716;
  --color-background-button-normal-disabled-z5vd4i:#000716;
  --color-background-button-normal-hover-c3qbgm:#192534;
  --color-background-button-primary-active-vg0knj:#539fe5;
  --color-background-button-primary-default-pwn1g1:#539fe5;
  --color-background-button-primary-disabled-eon7km:#354150;
  --color-background-button-primary-hover-qu1zio:#89bdee;
  --color-background-calendar-today-762czq:#354150;
  --color-background-cell-shaded-26ubfj:#232f3e;
  --color-background-code-editor-gutter-active-line-default-p2p8kl:#7d8998;
  --color-background-code-editor-gutter-active-line-error-lfh0u4:#eb6f6f;
  --color-background-code-editor-gutter-default-l5k65m:#192534;
  --color-background-code-editor-loading-4cqamf:#192534;
  --color-background-code-editor-pane-item-hover-lrigi6:#354150;
  --color-background-code-editor-status-bar-qgcwj5:#192534;
  --color-background-container-content-i8i4a0:#0f1b2a;
  --color-background-container-header-4flbq5:#0f1b2a;
  --color-background-control-checked-9admlu:#539fe5;
  --color-background-control-default-4pa05r:#000716;
  --color-background-control-disabled-zplipy:#354150;
  --color-background-dropdown-item-default-fzdqsg:#192534;
  --color-background-dropdown-item-filter-match-6ac2mw:#354150;
  --color-background-dropdown-item-hover-mqsxkv:#354150;
  --color-background-dropdown-item-selected-8l4mpk:#192534;
  --color-background-input-default-u57378:#000716;
  --color-background-input-disabled-wu9j6u:#192534;
  --color-background-item-selected-ebt4bi:#192534;
  --color-background-layout-main-sfhm4y:#000716;
  --color-background-layout-panel-content-808qum:#0f1b2a;
  --color-background-layout-panel-hover-0bg3gq:#354150;
  --color-background-layout-toggle-selected-active-9w7pk3:#539fe5;
  --color-background-layout-toggle-selected-default-e1giv3:#539fe5;
  --color-background-layout-toggle-selected-hover-mes33s:#89bdee;
  --color-background-modal-overlay-17dapf:rgba(0, 7, 22, 0.7);
  --color-background-notification-grey-ua4ep5:#5f6b7a;
  --color-background-popover-mn5o9k:#192534;
  --color-background-progress-bar-content-default-r4tyyh:#539fe5;
  --color-background-progress-bar-layout-default-9xbpym:#354150;
  --color-background-segment-active-bl0u6r:#539fe5;
  --color-background-segment-default-5k37bj:#000716;
  --color-background-segment-disabled-hgka10:#000716;
  --color-background-segment-hover-s3f27a:#000716;
  --color-background-status-error-pe1hux:#1a0000;
  --color-background-status-info-usb30e:#00142b;
  --color-background-status-success-xnxxn4:#001a02;
  --color-background-status-warning-zz4oi6:#0f1b2a;
  --color-background-table-header-i1pa1g:#000716;
  --color-background-tiles-disabled-byt5lx:#192534;
  --color-background-toggle-checked-disabled-07lf06:#033160;
  --color-background-toggle-default-w4ftus:#7d8998;
  --color-border-button-normal-active-zbmaft:#89bdee;
  --color-border-button-normal-default-djv8f5:#539fe5;
  --color-border-button-normal-disabled-262a0x:#5f6b7a;
  --color-border-button-normal-hover-e1d0kh:#89bdee;
  --color-border-button-primary-disabled-6gwabt:#354150;
  --color-border-calendar-grid-selected-focus-ring-cxqa0s:#0f1b2a;
  --color-border-code-editor-default-l9vmjf:#5f6b7a;
  --color-border-code-editor-pane-item-hover-xephrt:#5f6b7a;
  --color-border-control-checked-4xvndc:#539fe5;
  --color-border-control-disabled-vx8bco:#354150;
  --color-border-divider-default-7s2wjw:#414d5c;
  --color-border-divider-panel-bottom-zrjpck:rgba(0, 7, 22, 1);
  --color-border-divider-panel-side-yjkfcr:#414d5c;
  --color-border-dropdown-container-tyn0js:#5f6b7a;
  --color-border-dropdown-group-0utpsr:#414d5c;
  --color-border-dropdown-item-default-faosd8:#414d5c;
  --color-border-dropdown-item-hover-tyzq9m:#5f6b7a;
  --color-border-input-default-e47bkv:#5f6b7a;
  --color-border-input-disabled-x2lopj:#192534;
  --color-border-item-focused-ap3b6s:#539fe5;
  --color-border-item-placeholder-jgzhnb:#539fe5;
  --color-border-item-selected-ppkssz:#539fe5;
  --color-border-layout-wf5alz:#354150;
  --color-border-popover-5pygk7:#5f6b7a;
  --color-border-segment-active-kdy5tm:#d1d5db;
  --color-border-segment-default-091088:#d1d5db;
  --color-border-segment-disabled-6v5zlt:#d1d5db;
  --color-border-segment-hover-9notuw:#d1d5db;
  --color-border-status-error-mfrddc:#eb6f6f;
  --color-border-status-info-546i7i:#539fe5;
  --color-border-status-success-cqi45f:#29ad32;
  --color-border-status-warning-hg85ut:#8d99a8;
  --color-border-divider-interactive-default-rcasvw:#414d5c;
  --color-border-tabs-divider-69fx5i:#414d5c;
  --color-border-tabs-shadow-4lmh2z:rgba(0, 7, 22, 1);
  --color-border-tabs-underline-9bcfzu:#539fe5;
  --color-border-tiles-disabled-dp4h7y:#192534;
  --color-border-tutorial-4ioksw:#414d5c;
  --color-foreground-control-default-7ajdem:#000716;
  --color-foreground-control-disabled-v6a97u:#0f1b2a;
  --color-shadow-default-3se70f:rgba(0, 7, 22, 1);
  --color-text-accent-s1eqko:#539fe5;
  --color-text-body-default-ajf1h5:#d1d5db;
  --color-text-body-secondary-fdstdf:#d1d5db;
  --color-text-breadcrumb-current-mum16s:#7d8998;
  --color-text-breadcrumb-icon-mlugp6:#5f6b7a;
  --color-text-button-inline-icon-default-w65lqn:#539fe5;
  --color-text-button-inline-icon-disabled-gfhvz7:#5f6b7a;
  --color-text-button-inline-icon-hover-wd5icp:#89bdee;
  --color-text-button-normal-active-6obrzh:#89bdee;
  --color-text-button-normal-default-u8647m:#539fe5;
  --color-text-button-normal-hover-kxmvcc:#89bdee;
  --color-text-link-button-normal-default-2yrp9u:#539fe5;
  --color-text-link-button-normal-hover-gz8y5b:#89bdee;
  --color-text-link-button-normal-active-8lhs8f:#89bdee;
  --color-text-button-primary-active-vems2x:#000716;
  --color-text-button-primary-default-340wh6:#000716;
  --color-text-button-primary-hover-vzqjpi:#000716;
  --color-text-calendar-day-hover-graajh:#d1d5db;
  --color-text-calendar-month-elf7e9:#8d99a8;
  --color-text-code-editor-gutter-active-line-3r48bk:#000716;
  --color-text-code-editor-gutter-default-q46j79:#d1d5db;
  --color-text-code-editor-status-bar-disabled-6bhuub:#5f6b7a;
  --color-text-code-editor-tab-button-error-vez6ll:#000716;
  --color-text-column-header-u472sv:#9ba7b6;
  --color-text-column-sorting-icon-q5sr09:#9ba7b6;
  --color-text-control-disabled-3z3eul:#5f6b7a;
  --color-text-counter-jwdegc:#8d99a8;
  --color-text-disabled-a2nkh4:#5f6b7a;
  --color-text-dropdown-footer-rj6jvf:#8d99a8;
  --color-text-dropdown-group-label-eaqlcl:#8d99a8;
  --color-text-dropdown-header-6e05nm:#fbfbfb;
  --color-text-dropdown-item-default-7tg9p2:#d1d5db;
  --color-text-dropdown-item-dimmed-jyqdrs:#5f6b7a;
  --color-text-dropdown-item-disabled-varol7:#5f6b7a;
  --color-text-dropdown-item-filter-match-x302if:#89bdee;
  --color-text-dropdown-item-highlighted-oaabyk:#e9ebed;
  --color-text-dropdown-item-secondary-0me7js:#8d99a8;
  --color-text-dropdown-item-secondary-hover-7ii1ru:colorGrey300;
  --color-text-empty-fjv325:#d1d5db;
  --color-text-expandable-section-default-k03p1p:#fbfbfb;
  --color-text-expandable-section-hover-73g3du:#539fe5;
  --color-text-expandable-section-navigation-icon-default-lb9rm0:#d1d5db;
  --color-text-form-default-yd29ox:#d1d5db;
  --color-text-form-label-k3j0b4:#d1d5db;
  --color-text-form-secondary-ih9x7l:#8d99a8;
  --color-text-group-label-w9to2i:#8d99a8;
  --color-text-heading-default-jopz0f:#e9ebed;
  --color-text-heading-secondary-corkaj:#8d99a8;
  --color-text-icon-subtle-bz5b7h:#9ba7b6;
  --color-text-input-disabled-oawyzo:#5f6b7a;
  --color-text-input-placeholder-j53j3c:#7d8998;
  --color-text-input-placeholder-disabled-6928j8:#5f6b7a;
  --color-text-interactive-active-vol84d:#fbfbfb;
  --color-text-interactive-default-eg5fsa:#d1d5db;
  --color-text-interactive-disabled-3pbb07:#5f6b7a;
  --color-text-interactive-hover-v3lasm:#fbfbfb;
  --color-text-inverted-821sko:#000716;
  --color-text-label-gxskdy:#d1d5db;
  --color-text-layout-toggle-selected-tzwifo:#000716;
  --color-text-link-default-5f186r:#539fe5;
  --color-text-link-hover-kololx:#89bdee;
  --color-text-link-primary-underline-ga7rq8:#539fe5;
  --color-text-pagination-page-number-active-disabled-sjs1t5:#5f6b7a;
  --color-text-pagination-page-number-default-z6gnaa:#9ba7b6;
  --color-text-segment-active-gc2jjl:#000716;
  --color-text-segment-default-bf7rka:#d1d5db;
  --color-text-segment-hover-5pccvg:#539fe5;
  --color-text-small-le1y42:#8d99a8;
  --color-text-status-error-5sesl6:#eb6f6f;
  --color-text-status-inactive-5ei55p:#8d99a8;
  --color-text-status-info-utupcz:#539fe5;
  --color-text-status-success-0fzhv9:#29ad32;
  --color-text-status-warning-27nlyh:#eb6f6f;
  --color-text-top-navigation-title-oypxe3:#fbfbfb;
  --color-board-placeholder-active-vaxzdf:#5f6b7a;
  --color-board-placeholder-hover-v1s7kq:#0972d3;
  --shadow-container-fx563k:none;
  --shadow-container-stacked-jfo2nn:0px 9px 8px -7px rgb(0 7 22 / 60%), 8px 0px 8px -7px rgb(0 7 22 / 60%), -8px 0px 8px -7px rgb(0 7 22 / 60%);
  --shadow-container-active-l4kuxc:0px 1px 1px 1px #192534, 0px 6px 36px #000716;
  --shadow-dropdown-99h0gz:0px 4px 20px 1px rgba(0, 7, 22, 1);
  --shadow-dropup-11esug:0px 4px 20px 1px rgba(0, 7, 22, 1);
  --shadow-modal-3qhlqt:0px 4px 20px 1px rgba(0, 7, 22, 1);
  --shadow-popover-kzy6rd:0px 4px 20px 1px rgba(0, 7, 22, 1);
  --shadow-split-bottom-otfetg:0px -36px 36px -36px rgba(0, 7, 22, 1);
  --shadow-split-side-qv353u:-1px 0px 1px 0px #192534, -36px 6px 36px -36px rgba(0, 7, 22, 1);
  --shadow-sticky-aijlo6:0px 4px 20px 1px rgba(0, 7, 22, 1);
  --shadow-sticky-embedded-qit8ba:0px 2px 0px 0px #414d5c, 0px 16px 16px -12px rgba(0, 7, 22, 1);
}

.awsui-context-flashbar:not(#\9) {
  --color-background-button-normal-active-9lyks0:rgba(0, 7, 22, 0.2);
  --color-background-button-normal-default-syw2p1:transparent;
  --color-background-button-normal-hover-c3qbgm:rgba(0, 7, 22, 0.15);
  --color-background-button-primary-default-pwn1g1:#fbfbfb;
  --color-background-button-primary-hover-qu1zio:#ffffff;
  --color-background-segment-default-5k37bj:transparent;
  --color-background-segment-hover-s3f27a:transparent;
  --color-border-button-normal-active-zbmaft:#ffffff;
  --color-border-button-normal-default-djv8f5:#fbfbfb;
  --color-border-button-normal-hover-e1d0kh:#ffffff;
  --color-border-item-focused-ap3b6s:#fbfbfb;
  --color-text-button-normal-active-6obrzh:#ffffff;
  --color-text-button-normal-default-u8647m:#fbfbfb;
  --color-text-button-normal-hover-kxmvcc:#ffffff;
  --color-text-link-button-normal-default-2yrp9u:#fbfbfb;
  --color-text-link-button-normal-hover-gz8y5b:#ffffff;
  --color-text-link-button-normal-active-8lhs8f:#ffffff;
}

.awsui-context-alert:not(#\9) {
  --color-background-button-normal-active-9lyks0:rgba(0, 7, 22, 0.1);
  --color-background-button-normal-default-syw2p1:transparent;
  --color-background-button-normal-hover-c3qbgm:rgba(0, 7, 22, 0.05);
  --color-background-button-primary-default-pwn1g1:#414d5c;
  --color-background-button-primary-hover-qu1zio:#000716;
  --color-background-segment-default-5k37bj:transparent;
  --color-background-segment-hover-s3f27a:transparent;
  --color-border-button-normal-active-zbmaft:#000716;
  --color-border-button-normal-default-djv8f5:#414d5c;
  --color-border-button-normal-hover-e1d0kh:#000716;
  --color-text-button-normal-active-6obrzh:#000716;
  --color-text-button-normal-default-u8647m:#414d5c;
  --color-text-button-normal-hover-kxmvcc:#000716;
  --color-text-link-button-normal-active-8lhs8f:#000716;
}

.awsui-dark-mode .awsui-context-alert:not(#\9) {
  --color-background-button-normal-active-9lyks0:rgba(255, 255, 255, 0.15);
  --color-background-button-normal-hover-c3qbgm:rgba(255, 255, 255, 0.1);
  --color-background-button-primary-default-pwn1g1:#d1d5db;
  --color-background-button-primary-hover-qu1zio:#ffffff;
  --color-border-button-normal-active-zbmaft:#ffffff;
  --color-border-button-normal-default-djv8f5:#d1d5db;
  --color-border-button-normal-hover-e1d0kh:#ffffff;
  --color-border-item-focused-ap3b6s:#fbfbfb;
  --color-text-button-normal-active-6obrzh:#ffffff;
  --color-text-button-normal-default-u8647m:#d1d5db;
  --color-text-button-normal-hover-kxmvcc:#ffffff;
  --color-text-link-button-normal-active-8lhs8f:#ffffff;
}

.awsui-context-alert.awsui-dark-mode:not(#\9) {
  --color-background-button-normal-active-9lyks0:rgba(255, 255, 255, 0.15);
  --color-background-button-normal-hover-c3qbgm:rgba(255, 255, 255, 0.1);
  --color-background-button-primary-default-pwn1g1:#d1d5db;
  --color-background-button-primary-hover-qu1zio:#ffffff;
  --color-border-button-normal-active-zbmaft:#ffffff;
  --color-border-button-normal-default-djv8f5:#d1d5db;
  --color-border-button-normal-hover-e1d0kh:#ffffff;
  --color-border-item-focused-ap3b6s:#fbfbfb;
  --color-text-button-normal-active-6obrzh:#ffffff;
  --color-text-button-normal-default-u8647m:#d1d5db;
  --color-text-button-normal-hover-kxmvcc:#ffffff;
  --color-text-link-button-normal-active-8lhs8f:#ffffff;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
@-webkit-keyframes awsui-fade-in-35003c {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes awsui-fade-in-35003c {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes awsui-fade-out-35003c {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes awsui-fade-out-35003c {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes awsui-status-icon-error-35003c {
  0% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  50% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
            animation-timing-function: cubic-bezier(0, 0, 0, 1);
    -webkit-animation-timing-function: var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
            animation-timing-function: var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}
@keyframes awsui-status-icon-error-35003c {
  0% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  50% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
            animation-timing-function: cubic-bezier(0, 0, 0, 1);
    -webkit-animation-timing-function: var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
            animation-timing-function: var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}
@-webkit-keyframes awsui-scale-popup-35003c {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes awsui-scale-popup-35003c {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_18582_1f6cg_93:not(#\9) {
  display: flex;
}

.awsui_child_18582_1f6cg_97:not(#\9) {
  /* used in test-utils */
}

.awsui_child_18582_1f6cg_97:not(#\9):empty {
  display: none;
}

/*
 * Horizontal variant
 */
.awsui_horizontal_18582_1f6cg_108:not(#\9) {
  flex-direction: row;
  flex-wrap: wrap;
}
.awsui_horizontal-xxxs_18582_1f6cg_112:not(#\9) {
  margin-left: calc(-1 * 2px);
  margin-left: calc(-1 * var(--space-xxxs-k2w98v, 2px));
  margin-bottom: calc(-1 * 2px);
  margin-bottom: calc(-1 * var(--space-xxxs-k2w98v, 2px));
}
.awsui_horizontal-xxs_18582_1f6cg_116:not(#\9) {
  margin-left: calc(-1 * 4px);
  margin-left: calc(-1 * var(--space-xxs-ynfts5, 4px));
  margin-bottom: calc(-1 * 4px);
  margin-bottom: calc(-1 * var(--space-xxs-ynfts5, 4px));
}
.awsui_horizontal-xs_18582_1f6cg_120:not(#\9) {
  margin-left: calc(-1 * 8px);
  margin-left: calc(-1 * var(--space-xs-rsr2qu, 8px));
  margin-bottom: calc(-1 * 8px);
  margin-bottom: calc(-1 * var(--space-xs-rsr2qu, 8px));
}
.awsui_horizontal-s_18582_1f6cg_124:not(#\9) {
  margin-left: calc(-1 * 12px);
  margin-left: calc(-1 * var(--space-s-hv8c1d, 12px));
  margin-bottom: calc(-1 * 12px);
  margin-bottom: calc(-1 * var(--space-s-hv8c1d, 12px));
}
.awsui_horizontal-m_18582_1f6cg_128:not(#\9) {
  margin-left: calc(-1 * 16px);
  margin-left: calc(-1 * var(--space-m-17eucw, 16px));
  margin-bottom: calc(-1 * 16px);
  margin-bottom: calc(-1 * var(--space-m-17eucw, 16px));
}
.awsui_horizontal-l_18582_1f6cg_132:not(#\9) {
  margin-left: calc(-1 * 20px);
  margin-left: calc(-1 * var(--space-l-4vl6xu, 20px));
  margin-bottom: calc(-1 * 20px);
  margin-bottom: calc(-1 * var(--space-l-4vl6xu, 20px));
}
.awsui_horizontal-xl_18582_1f6cg_136:not(#\9) {
  margin-left: calc(-1 * 24px);
  margin-left: calc(-1 * var(--space-xl-a39hup, 24px));
  margin-bottom: calc(-1 * 24px);
  margin-bottom: calc(-1 * var(--space-xl-a39hup, 24px));
}
.awsui_horizontal-xxl_18582_1f6cg_140:not(#\9) {
  margin-left: calc(-1 * 32px);
  margin-left: calc(-1 * var(--space-xxl-2nvmf1, 32px));
  margin-bottom: calc(-1 * 32px);
  margin-bottom: calc(-1 * var(--space-xxl-2nvmf1, 32px));
}

.awsui_child-horizontal-xxxs_18582_1f6cg_145:not(#\9) {
  margin-left: 2px;
  margin-left: var(--space-xxxs-k2w98v, 2px);
  margin-bottom: 2px;
  margin-bottom: var(--space-xxxs-k2w98v, 2px);
  min-width: 1px;
}

.awsui_child-horizontal-xxs_18582_1f6cg_151:not(#\9) {
  margin-left: 4px;
  margin-left: var(--space-xxs-ynfts5, 4px);
  margin-bottom: 4px;
  margin-bottom: var(--space-xxs-ynfts5, 4px);
  min-width: 1px;
}

.awsui_child-horizontal-xs_18582_1f6cg_157:not(#\9) {
  margin-left: 8px;
  margin-left: var(--space-xs-rsr2qu, 8px);
  margin-bottom: 8px;
  margin-bottom: var(--space-xs-rsr2qu, 8px);
  min-width: 1px;
}

.awsui_child-horizontal-s_18582_1f6cg_163:not(#\9) {
  margin-left: 12px;
  margin-left: var(--space-s-hv8c1d, 12px);
  margin-bottom: 12px;
  margin-bottom: var(--space-s-hv8c1d, 12px);
  min-width: 1px;
}

.awsui_child-horizontal-m_18582_1f6cg_169:not(#\9) {
  margin-left: 16px;
  margin-left: var(--space-m-17eucw, 16px);
  margin-bottom: 16px;
  margin-bottom: var(--space-m-17eucw, 16px);
  min-width: 1px;
}

.awsui_child-horizontal-l_18582_1f6cg_175:not(#\9) {
  margin-left: 20px;
  margin-left: var(--space-l-4vl6xu, 20px);
  margin-bottom: 20px;
  margin-bottom: var(--space-l-4vl6xu, 20px);
  min-width: 1px;
}

.awsui_child-horizontal-xl_18582_1f6cg_181:not(#\9) {
  margin-left: 24px;
  margin-left: var(--space-xl-a39hup, 24px);
  margin-bottom: 24px;
  margin-bottom: var(--space-xl-a39hup, 24px);
  min-width: 1px;
}

.awsui_child-horizontal-xxl_18582_1f6cg_187:not(#\9) {
  margin-left: 32px;
  margin-left: var(--space-xxl-2nvmf1, 32px);
  margin-bottom: 32px;
  margin-bottom: var(--space-xxl-2nvmf1, 32px);
  min-width: 1px;
}

/*
 * Vertical variant
 */
.awsui_vertical_18582_1f6cg_196:not(#\9) {
  flex-direction: column;
}

.awsui_child-vertical-xxxs_18582_1f6cg_200:not(#\9):not(:last-child) {
  margin-bottom: 2px;
  margin-bottom: var(--space-xxxs-k2w98v, 2px);
}

.awsui_child-vertical-xxs_18582_1f6cg_204:not(#\9):not(:last-child) {
  margin-bottom: 4px;
  margin-bottom: var(--space-xxs-ynfts5, 4px);
}

.awsui_child-vertical-xs_18582_1f6cg_208:not(#\9):not(:last-child) {
  margin-bottom: 8px;
  margin-bottom: var(--space-xs-rsr2qu, 8px);
}

.awsui_child-vertical-s_18582_1f6cg_212:not(#\9):not(:last-child) {
  margin-bottom: 12px;
  margin-bottom: var(--space-scaled-s-cu1hzn, 12px);
}

.awsui_child-vertical-m_18582_1f6cg_216:not(#\9):not(:last-child) {
  margin-bottom: 16px;
  margin-bottom: var(--space-scaled-m-pv0fmt, 16px);
}

.awsui_child-vertical-l_18582_1f6cg_220:not(#\9):not(:last-child) {
  margin-bottom: 20px;
  margin-bottom: var(--space-scaled-l-t03y3z, 20px);
}

.awsui_child-vertical-xl_18582_1f6cg_224:not(#\9):not(:last-child) {
  margin-bottom: 24px;
  margin-bottom: var(--space-scaled-xl-w2t504, 24px);
}

.awsui_child-vertical-xxl_18582_1f6cg_228:not(#\9):not(:last-child) {
  margin-bottom: 32px;
  margin-bottom: var(--space-scaled-xxl-pfgv96, 32px);
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_badge_1yjyg_ih6bq_93:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: #000716;
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  font-size: 12px;
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: 16px;
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: 0.005em;
  letter-spacing: var(--font-body-s-letter-spacing-cy0oxj, 0.005em);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  display: inline-block;
  border-radius: 4px;
  border-radius: var(--border-radius-badge-x5oehc, 4px);
  padding: 0 8px;
  padding: 0 var(--space-xs-rsr2qu, 8px);
  color: #fbfbfb;
  color: var(--color-text-notification-default-2kpcxy, #fbfbfb);
}
.awsui_badge_1yjyg_ih6bq_93.awsui_badge-color-grey_1yjyg_ih6bq_113:not(#\9) {
  background-color: #414d5c;
  background-color: var(--color-background-notification-grey-ua4ep5, #414d5c);
}
.awsui_badge_1yjyg_ih6bq_93.awsui_badge-color-green_1yjyg_ih6bq_116:not(#\9) {
  background-color: #037f0c;
  background-color: var(--color-background-notification-green-0d9ow8, #037f0c);
}
.awsui_badge_1yjyg_ih6bq_93.awsui_badge-color-blue_1yjyg_ih6bq_119:not(#\9) {
  background-color: #0972d3;
  background-color: var(--color-background-notification-blue-vqb59v, #0972d3);
}
.awsui_badge_1yjyg_ih6bq_93.awsui_badge-color-red_1yjyg_ih6bq_122:not(#\9) {
  background-color: #d91515;
  background-color: var(--color-background-notification-red-v7js1f, #d91515);
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-implicit-descendant, selector-max-type */
.awsui_text-content_6absk_lv8vb_94:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: #000716;
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  /* stylelint-disable no-descending-specificity */
  /* stylelint-enable no-descending-specificity */
}
.awsui_text-content_6absk_lv8vb_94 h1:not(#\9),
.awsui_text-content_6absk_lv8vb_94 h2:not(#\9),
.awsui_text-content_6absk_lv8vb_94 h3:not(#\9),
.awsui_text-content_6absk_lv8vb_94 h4:not(#\9),
.awsui_text-content_6absk_lv8vb_94 h5:not(#\9),
.awsui_text-content_6absk_lv8vb_94 p:not(#\9) {
  font-family: inherit;
  font-weight: normal;
  text-decoration: none;
  margin: 0;
  padding: 4px 0;
  padding: var(--space-xxs-ynfts5, 4px) 0;
}
.awsui_text-content_6absk_lv8vb_94 h1:not(#\9),
.awsui_text-content_6absk_lv8vb_94 h2:not(#\9),
.awsui_text-content_6absk_lv8vb_94 h3:not(#\9),
.awsui_text-content_6absk_lv8vb_94 h4:not(#\9),
.awsui_text-content_6absk_lv8vb_94 h5:not(#\9) {
  color: #000716;
  color: var(--color-text-heading-default-jopz0f, #000716);
}
.awsui_text-content_6absk_lv8vb_94 h1:not(#\9) {
  font-size: 32px;
  font-size: var(--font-heading-xl-size-d0vjbk, 32px);
  line-height: 40px;
  line-height: var(--font-heading-xl-line-height-doxfdj, 40px);
  letter-spacing: -0.02em;
  letter-spacing: var(--font-heading-xl-letter-spacing-9tcsmy, -0.02em);
  font-weight: 800;
  font-weight: var(--font-heading-xl-weight-772870, 800);
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
}
.awsui_text-content_6absk_lv8vb_94 h2:not(#\9) {
  font-size: 24px;
  font-size: var(--font-heading-l-size-otmxys, 24px);
  line-height: 30px;
  line-height: var(--font-heading-l-line-height-gev4n1, 30px);
  letter-spacing: -0.015em;
  letter-spacing: var(--font-heading-l-letter-spacing-f6wvtk, -0.015em);
  font-weight: 800;
  font-weight: var(--font-heading-l-weight-xm7ks9, 800);
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
}
.awsui_text-content_6absk_lv8vb_94 h3:not(#\9) {
  font-size: 20px;
  font-size: var(--font-heading-m-size-6e3jmg, 20px);
  line-height: 24px;
  line-height: var(--font-heading-m-line-height-u3t54f, 24px);
  letter-spacing: -0.01em;
  letter-spacing: var(--font-heading-m-letter-spacing-r00pg3, -0.01em);
  font-weight: 800;
  font-weight: var(--font-heading-m-weight-hi5n0j, 800);
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
}
.awsui_text-content_6absk_lv8vb_94 h4:not(#\9) {
  font-size: 16px;
  font-size: var(--font-heading-s-size-9clg8y, 16px);
  line-height: 20px;
  line-height: var(--font-heading-s-line-height-5b7f0q, 20px);
  letter-spacing: -0.005em;
  letter-spacing: var(--font-heading-s-letter-spacing-4d69jc, -0.005em);
  font-weight: 800;
  font-weight: var(--font-heading-s-weight-k8ys41, 800);
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
}
.awsui_text-content_6absk_lv8vb_94 h5:not(#\9) {
  font-size: 14px;
  font-size: var(--font-heading-xs-size-fryxro, 14px);
  line-height: 18px;
  line-height: var(--font-heading-xs-line-height-5x131j, 18px);
  font-weight: 800;
  font-weight: var(--font-heading-xs-weight-wwznty, 800);
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
}
.awsui_text-content_6absk_lv8vb_94 b:not(#\9),
.awsui_text-content_6absk_lv8vb_94 strong:not(#\9) {
  font-weight: 700;
}
.awsui_text-content_6absk_lv8vb_94 p:not(#\9) {
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: inherit;
}
.awsui_text-content_6absk_lv8vb_94 small:not(#\9) {
  display: inline-block;
  font-size: 12px;
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: 16px;
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: 0.005em;
  letter-spacing: var(--font-body-s-letter-spacing-cy0oxj, 0.005em);
  color: #5f6b7a;
  color: var(--color-text-small-le1y42, #5f6b7a);
}
.awsui_text-content_6absk_lv8vb_94 small a:not(#\9) {
  font-size: inherit;
  color: #0972d3;
  color: var(--color-text-link-default-5f186r, #0972d3);
  font-weight: "inherit";
  font-weight: var(--font-link-primary-weight-91pybi, "inherit");
  letter-spacing: "inherit";
  letter-spacing: var(--font-link-primary-letter-spacing-vmsgcx, "inherit");
  -webkit-text-decoration: underline;
  -webkit-text-decoration: var(--font-link-primary-decoration-wfgjh2, underline);
          text-decoration: underline;
          text-decoration: var(--font-link-primary-decoration-wfgjh2, underline);
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-decoration-color: #0972d3;
          text-decoration-color: #0972d3;
  -webkit-text-decoration-color: var(--color-text-link-primary-underline-ga7rq8, #0972d3);
          text-decoration-color: var(--color-text-link-primary-underline-ga7rq8, #0972d3);
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: 165ms;
  transition-duration: var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_text-content_6absk_lv8vb_94 small a:not(#\9) {
    -webkit-animation: none;
            animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_text-content_6absk_lv8vb_94 small a:not(#\9), .awsui-mode-entering .awsui_text-content_6absk_lv8vb_94 small a:not(#\9) {
  -webkit-animation: none;
          animation: none;
  transition: none;
}
.awsui_text-content_6absk_lv8vb_94 small a:not(#\9):hover {
  cursor: pointer;
  color: #033160;
  color: var(--color-text-link-hover-kololx, #033160);
}
.awsui_text-content_6absk_lv8vb_94 small a:not(#\9):focus {
  outline: none;
}
.awsui_text-content_6absk_lv8vb_94 small a:not(#\9):active {
  color: #033160;
  color: var(--color-text-link-hover-kololx, #033160);
}
.awsui_text-content_6absk_lv8vb_94 small a:not(#\9):active, .awsui_text-content_6absk_lv8vb_94 small a:not(#\9):focus, .awsui_text-content_6absk_lv8vb_94 small a:not(#\9):hover {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-decoration-color: currentColor;
          text-decoration-color: currentColor;
}
.awsui_text-content_6absk_lv8vb_94 code:not(#\9),
.awsui_text-content_6absk_lv8vb_94 pre:not(#\9),
.awsui_text-content_6absk_lv8vb_94 samp:not(#\9) {
  font-family: Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  font-family: var(--font-family-monospace-iz223z, Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace);
  background: transparent;
}
.awsui_text-content_6absk_lv8vb_94 code:not(#\9) {
  font-size: 12px;
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: 16px;
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: 0.005em;
  letter-spacing: var(--font-body-s-letter-spacing-cy0oxj, 0.005em);
}
.awsui_text-content_6absk_lv8vb_94 a:not(#\9) {
  color: #0972d3;
  color: var(--color-text-link-default-5f186r, #0972d3);
  font-weight: inherit;
  letter-spacing: normal;
  text-decoration: none;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: 165ms;
  transition-duration: var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_text-content_6absk_lv8vb_94 a:not(#\9) {
    -webkit-animation: none;
            animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_text-content_6absk_lv8vb_94 a:not(#\9), .awsui-mode-entering .awsui_text-content_6absk_lv8vb_94 a:not(#\9) {
  -webkit-animation: none;
          animation: none;
  transition: none;
}
.awsui_text-content_6absk_lv8vb_94 a:not(#\9):hover {
  cursor: pointer;
  color: #033160;
  color: var(--color-text-link-hover-kololx, #033160);
}
.awsui_text-content_6absk_lv8vb_94 a:not(#\9):focus {
  outline: none;
}
.awsui_text-content_6absk_lv8vb_94 a:not(#\9):active {
  color: #033160;
  color: var(--color-text-link-hover-kololx, #033160);
}
.awsui_text-content_6absk_lv8vb_94 a:not(#\9):active, .awsui_text-content_6absk_lv8vb_94 a:not(#\9):focus, .awsui_text-content_6absk_lv8vb_94 a:not(#\9):hover {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-decoration-color: currentColor;
          text-decoration-color: currentColor;
}
.awsui_text-content_6absk_lv8vb_94 a:not(#\9):focus {
  outline: thin dotted;
  outline: 0;
  outline: var(--border-link-focus-ring-outline-k2wccv, 0);
  outline-offset: 2px;
  outline-color: #0972d3;
  outline-color: var(--color-border-item-focused-ap3b6s, #0972d3);
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 var(--border-link-focus-ring-shadow-spread-nv4ahb, 2px) var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_text-content_6absk_lv8vb_94 ul:not(#\9),
.awsui_text-content_6absk_lv8vb_94 ol:not(#\9) {
  padding-left: 20px;
  padding-left: var(--space-l-4vl6xu, 20px);
  margin: 4px 0;
  margin: var(--space-xxs-ynfts5, 4px) 0;
  list-style-position: outside;
}
.awsui_text-content_6absk_lv8vb_94 ul + ul:not(#\9),
.awsui_text-content_6absk_lv8vb_94 ul + ol:not(#\9),
.awsui_text-content_6absk_lv8vb_94 ul > li + ul:not(#\9),
.awsui_text-content_6absk_lv8vb_94 ul > li + li:not(#\9),
.awsui_text-content_6absk_lv8vb_94 ul > li + ol:not(#\9),
.awsui_text-content_6absk_lv8vb_94 ul > li > ul:not(#\9),
.awsui_text-content_6absk_lv8vb_94 ul > li > ol:not(#\9),
.awsui_text-content_6absk_lv8vb_94 ol + ul:not(#\9),
.awsui_text-content_6absk_lv8vb_94 ol + ol:not(#\9),
.awsui_text-content_6absk_lv8vb_94 ol > li + ul:not(#\9),
.awsui_text-content_6absk_lv8vb_94 ol > li + li:not(#\9),
.awsui_text-content_6absk_lv8vb_94 ol > li + ol:not(#\9),
.awsui_text-content_6absk_lv8vb_94 ol > li > ul:not(#\9),
.awsui_text-content_6absk_lv8vb_94 ol > li > ol:not(#\9) {
  padding-top: 4px;
  padding-top: var(--space-xxs-ynfts5, 4px);
}
.awsui_text-content_6absk_lv8vb_94 ul > li > ul:not(#\9),
.awsui_text-content_6absk_lv8vb_94 ul > li > ol:not(#\9),
.awsui_text-content_6absk_lv8vb_94 ol > li > ul:not(#\9),
.awsui_text-content_6absk_lv8vb_94 ol > li > ol:not(#\9) {
  margin: 0;
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
div.awsui_column-layout_vvxn7_1pfow_93:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: #000716;
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  word-wrap: break-word;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106:not(#\9) {
  margin: calc(20px / -2);
  margin: calc(var(--space-grid-gutter-0subky, 20px) / -2);
  /* stylelint-disable-next-line selector-max-universal */
  /* stylelint-disable-next-line selector-max-universal */
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-no-gutters_vvxn7_1pfow_111:not(#\9) {
  margin: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-variant-text-grid_vvxn7_1pfow_114:not(#\9) {
  /* stylelint-disable-next-line selector-max-universal */
  margin: calc(-1 * (16px)) calc(-1 * 20px);
  margin: calc(-1 * (var(--space-text-grid-vertical-tj8m1c, 16px))) calc(-1 * var(--space-grid-gutter-0subky, 20px));
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-variant-text-grid_vvxn7_1pfow_114 > *:not(#\9) {
  border-left: 2px solid #e9ebed;
  border-left: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-variant-text-grid_vvxn7_1pfow_114.awsui_grid-breakpoint-default_vvxn7_1pfow_121 > *:not(#\9):nth-child(1n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-variant-text-grid_vvxn7_1pfow_114.awsui_grid-columns-1_vvxn7_1pfow_124.awsui_grid-breakpoint-xxs_vvxn7_1pfow_124 > *:not(#\9):nth-child(1n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-variant-text-grid_vvxn7_1pfow_114.awsui_grid-columns-1_vvxn7_1pfow_124.awsui_grid-breakpoint-xs_vvxn7_1pfow_127 > *:not(#\9):nth-child(1n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-variant-text-grid_vvxn7_1pfow_114.awsui_grid-columns-2_vvxn7_1pfow_130.awsui_grid-breakpoint-xxs_vvxn7_1pfow_124 > *:not(#\9):nth-child(2n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-variant-text-grid_vvxn7_1pfow_114.awsui_grid-columns-2_vvxn7_1pfow_130.awsui_grid-breakpoint-xs_vvxn7_1pfow_127 > *:not(#\9):nth-child(2n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-variant-text-grid_vvxn7_1pfow_114.awsui_grid-columns-3_vvxn7_1pfow_136.awsui_grid-breakpoint-xxs_vvxn7_1pfow_124 > *:not(#\9):nth-child(2n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-variant-text-grid_vvxn7_1pfow_114.awsui_grid-columns-3_vvxn7_1pfow_136.awsui_grid-breakpoint-xs_vvxn7_1pfow_127 > *:not(#\9):nth-child(3n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-variant-text-grid_vvxn7_1pfow_114.awsui_grid-columns-4_vvxn7_1pfow_142.awsui_grid-breakpoint-xxs_vvxn7_1pfow_124 > *:not(#\9):nth-child(2n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-variant-text-grid_vvxn7_1pfow_114.awsui_grid-columns-4_vvxn7_1pfow_142.awsui_grid-breakpoint-xs_vvxn7_1pfow_127 > *:not(#\9):nth-child(4n+1) {
  border-left-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106:not(#\9):not(.awsui_grid-no-gutters_vvxn7_1pfow_111) > * {
  padding: calc(20px / 2);
  padding: calc(var(--space-grid-gutter-0subky, 20px) / 2);
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106:not(#\9):not(.awsui_grid-no-gutters_vvxn7_1pfow_111).awsui_grid-variant-text-grid_vvxn7_1pfow_114 > * {
  padding: 0 20px;
  padding: 0 var(--space-grid-gutter-0subky, 20px);
  margin: 16px 0;
  margin: var(--space-text-grid-vertical-tj8m1c, 16px) 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-vertical-borders_vvxn7_1pfow_155:not(#\9) {
  /* stylelint-disable-next-line selector-max-universal */
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-vertical-borders_vvxn7_1pfow_155 > *:not(#\9) {
  border-right: 2px solid #e9ebed;
  border-right: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-vertical-borders_vvxn7_1pfow_155.awsui_grid-breakpoint-default_vvxn7_1pfow_121 > *:not(#\9):nth-child(1n) {
  border-right-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-vertical-borders_vvxn7_1pfow_155.awsui_grid-columns-1_vvxn7_1pfow_124.awsui_grid-breakpoint-xxs_vvxn7_1pfow_124 > *:not(#\9):nth-child(1n) {
  border-right-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-vertical-borders_vvxn7_1pfow_155.awsui_grid-columns-1_vvxn7_1pfow_124.awsui_grid-breakpoint-xs_vvxn7_1pfow_127 > *:not(#\9):nth-child(1n) {
  border-right-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-vertical-borders_vvxn7_1pfow_155.awsui_grid-columns-2_vvxn7_1pfow_130.awsui_grid-breakpoint-xxs_vvxn7_1pfow_124 > *:not(#\9):nth-child(2n) {
  border-right-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-vertical-borders_vvxn7_1pfow_155.awsui_grid-columns-2_vvxn7_1pfow_130.awsui_grid-breakpoint-xs_vvxn7_1pfow_127 > *:not(#\9):nth-child(2n) {
  border-right-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-vertical-borders_vvxn7_1pfow_155.awsui_grid-columns-3_vvxn7_1pfow_136.awsui_grid-breakpoint-xxs_vvxn7_1pfow_124 > *:not(#\9):nth-child(2n) {
  border-right-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-vertical-borders_vvxn7_1pfow_155.awsui_grid-columns-3_vvxn7_1pfow_136.awsui_grid-breakpoint-xs_vvxn7_1pfow_127 > *:not(#\9):nth-child(3n) {
  border-right-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-vertical-borders_vvxn7_1pfow_155.awsui_grid-columns-4_vvxn7_1pfow_142.awsui_grid-breakpoint-xxs_vvxn7_1pfow_124 > *:not(#\9):nth-child(2n) {
  border-right-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-vertical-borders_vvxn7_1pfow_155.awsui_grid-columns-4_vvxn7_1pfow_142.awsui_grid-breakpoint-xs_vvxn7_1pfow_127 > *:not(#\9):nth-child(4n) {
  border-right-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-horizontal-borders_vvxn7_1pfow_188:not(#\9) {
  /* stylelint-disable selector-max-universal */
  /* stylelint-enable selector-max-universal */
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-horizontal-borders_vvxn7_1pfow_188 > *:not(#\9) {
  border-bottom: 2px solid #e9ebed;
  border-bottom: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-horizontal-borders_vvxn7_1pfow_188 > *:not(#\9):last-child {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-horizontal-borders_vvxn7_1pfow_188.awsui_grid-columns-2_vvxn7_1pfow_130.awsui_grid-breakpoint-xxs_vvxn7_1pfow_124 > *:not(#\9):nth-last-child(2):nth-child(2n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-horizontal-borders_vvxn7_1pfow_188.awsui_grid-columns-2_vvxn7_1pfow_130.awsui_grid-breakpoint-xs_vvxn7_1pfow_127 > *:not(#\9):nth-last-child(2):nth-child(2n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-horizontal-borders_vvxn7_1pfow_188.awsui_grid-columns-3_vvxn7_1pfow_136.awsui_grid-breakpoint-xxs_vvxn7_1pfow_124 > *:not(#\9):nth-last-child(2):nth-child(2n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-horizontal-borders_vvxn7_1pfow_188.awsui_grid-columns-3_vvxn7_1pfow_136.awsui_grid-breakpoint-xs_vvxn7_1pfow_127 > *:not(#\9):nth-last-child(2):nth-child(3n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-horizontal-borders_vvxn7_1pfow_188.awsui_grid-columns-3_vvxn7_1pfow_136.awsui_grid-breakpoint-xs_vvxn7_1pfow_127 > *:not(#\9):nth-last-child(2):nth-child(3n+2) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-horizontal-borders_vvxn7_1pfow_188.awsui_grid-columns-3_vvxn7_1pfow_136.awsui_grid-breakpoint-xs_vvxn7_1pfow_127 > *:not(#\9):nth-last-child(3):nth-child(3n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-horizontal-borders_vvxn7_1pfow_188.awsui_grid-columns-4_vvxn7_1pfow_142.awsui_grid-breakpoint-xxs_vvxn7_1pfow_124 > *:not(#\9):nth-last-child(2):nth-child(2n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-horizontal-borders_vvxn7_1pfow_188.awsui_grid-columns-4_vvxn7_1pfow_142.awsui_grid-breakpoint-xs_vvxn7_1pfow_127 > *:not(#\9):nth-last-child(2):nth-child(4n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-horizontal-borders_vvxn7_1pfow_188.awsui_grid-columns-4_vvxn7_1pfow_142.awsui_grid-breakpoint-xs_vvxn7_1pfow_127 > *:not(#\9):nth-last-child(2):nth-child(4n+2) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-horizontal-borders_vvxn7_1pfow_188.awsui_grid-columns-4_vvxn7_1pfow_142.awsui_grid-breakpoint-xs_vvxn7_1pfow_127 > *:not(#\9):nth-last-child(2):nth-child(4n+3) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-horizontal-borders_vvxn7_1pfow_188.awsui_grid-columns-4_vvxn7_1pfow_142.awsui_grid-breakpoint-xs_vvxn7_1pfow_127 > *:not(#\9):nth-last-child(3):nth-child(4n+1) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-horizontal-borders_vvxn7_1pfow_188.awsui_grid-columns-4_vvxn7_1pfow_142.awsui_grid-breakpoint-xs_vvxn7_1pfow_127 > *:not(#\9):nth-last-child(3):nth-child(4n+2) {
  border-bottom-width: 0;
}
div.awsui_column-layout_vvxn7_1pfow_93 > .awsui_grid_vvxn7_1pfow_106.awsui_grid-horizontal-borders_vvxn7_1pfow_188.awsui_grid-columns-4_vvxn7_1pfow_142.awsui_grid-breakpoint-xs_vvxn7_1pfow_127 > *:not(#\9):nth-last-child(4):nth-child(4n+1) {
  border-bottom-width: 0;
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_grid_14yj0_1ryep_93:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: #000716;
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: flex;
  flex-wrap: wrap;
  margin: calc(20px / -2);
  margin: calc(var(--space-grid-gutter-0subky, 20px) / -2);
  pointer-events: none;
}
.awsui_grid_14yj0_1ryep_93.awsui_no-gutters_14yj0_1ryep_109:not(#\9) {
  margin: 0;
}

.awsui_grid-column_14yj0_1ryep_113:not(#\9) {
  box-sizing: border-box;
  position: relative;
  padding: calc(20px / 2);
  padding: calc(var(--space-grid-gutter-0subky, 20px) / 2);
  display: flex;
}
.awsui_grid_14yj0_1ryep_93.awsui_no-gutters_14yj0_1ryep_109 > .awsui_grid-column_14yj0_1ryep_113:not(#\9) {
  padding: 0;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_colspan-1_14yj0_1ryep_122:not(#\9) {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_push-1_14yj0_1ryep_126:not(#\9) {
  left: 8.3333333333%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_pull-1_14yj0_1ryep_129:not(#\9) {
  right: 8.3333333333%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_colspan-2_14yj0_1ryep_132:not(#\9) {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_push-2_14yj0_1ryep_136:not(#\9) {
  left: 16.6666666667%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_pull-2_14yj0_1ryep_139:not(#\9) {
  right: 16.6666666667%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_colspan-3_14yj0_1ryep_142:not(#\9) {
  flex: 0 0 25%;
  max-width: 25%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_push-3_14yj0_1ryep_146:not(#\9) {
  left: 25%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_pull-3_14yj0_1ryep_149:not(#\9) {
  right: 25%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_colspan-4_14yj0_1ryep_152:not(#\9) {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_push-4_14yj0_1ryep_156:not(#\9) {
  left: 33.3333333333%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_pull-4_14yj0_1ryep_159:not(#\9) {
  right: 33.3333333333%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_colspan-5_14yj0_1ryep_162:not(#\9) {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_push-5_14yj0_1ryep_166:not(#\9) {
  left: 41.6666666667%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_pull-5_14yj0_1ryep_169:not(#\9) {
  right: 41.6666666667%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_colspan-6_14yj0_1ryep_172:not(#\9) {
  flex: 0 0 50%;
  max-width: 50%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_push-6_14yj0_1ryep_176:not(#\9) {
  left: 50%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_pull-6_14yj0_1ryep_179:not(#\9) {
  right: 50%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_colspan-7_14yj0_1ryep_182:not(#\9) {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_push-7_14yj0_1ryep_186:not(#\9) {
  left: 58.3333333333%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_pull-7_14yj0_1ryep_189:not(#\9) {
  right: 58.3333333333%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_colspan-8_14yj0_1ryep_192:not(#\9) {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_push-8_14yj0_1ryep_196:not(#\9) {
  left: 66.6666666667%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_pull-8_14yj0_1ryep_199:not(#\9) {
  right: 66.6666666667%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_colspan-9_14yj0_1ryep_202:not(#\9) {
  flex: 0 0 75%;
  max-width: 75%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_push-9_14yj0_1ryep_206:not(#\9) {
  left: 75%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_pull-9_14yj0_1ryep_209:not(#\9) {
  right: 75%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_colspan-10_14yj0_1ryep_212:not(#\9) {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_push-10_14yj0_1ryep_216:not(#\9) {
  left: 83.3333333333%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_pull-10_14yj0_1ryep_219:not(#\9) {
  right: 83.3333333333%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_colspan-11_14yj0_1ryep_222:not(#\9) {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_push-11_14yj0_1ryep_226:not(#\9) {
  left: 91.6666666667%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_pull-11_14yj0_1ryep_229:not(#\9) {
  right: 91.6666666667%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_colspan-12_14yj0_1ryep_232:not(#\9) {
  flex: 0 0 100%;
  max-width: 100%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_push-12_14yj0_1ryep_236:not(#\9) {
  left: 100%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_pull-12_14yj0_1ryep_239:not(#\9) {
  right: 100%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_push-0_14yj0_1ryep_242:not(#\9) {
  left: auto;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_pull-0_14yj0_1ryep_245:not(#\9) {
  right: auto;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_offset-1_14yj0_1ryep_248:not(#\9) {
  margin-left: 8.3333333333%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_offset-2_14yj0_1ryep_251:not(#\9) {
  margin-left: 16.6666666667%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_offset-3_14yj0_1ryep_254:not(#\9) {
  margin-left: 25%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_offset-4_14yj0_1ryep_257:not(#\9) {
  margin-left: 33.3333333333%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_offset-5_14yj0_1ryep_260:not(#\9) {
  margin-left: 41.6666666667%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_offset-6_14yj0_1ryep_263:not(#\9) {
  margin-left: 50%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_offset-7_14yj0_1ryep_266:not(#\9) {
  margin-left: 58.3333333333%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_offset-8_14yj0_1ryep_269:not(#\9) {
  margin-left: 66.6666666667%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_offset-9_14yj0_1ryep_272:not(#\9) {
  margin-left: 75%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_offset-10_14yj0_1ryep_275:not(#\9) {
  margin-left: 83.3333333333%;
}
.awsui_grid-column_14yj0_1ryep_113.awsui_offset-11_14yj0_1ryep_278:not(#\9) {
  margin-left: 91.6666666667%;
}

.awsui_restore-pointer-events_14yj0_1ryep_282:not(#\9) {
  pointer-events: auto;
  width: 100%;
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_18wu0_12k4n_93:not(#\9) {
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: #000716;
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

/* stylelint-disable no-invalid-position-at-import-rule */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-implicit-descendant */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-implicit-descendant */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-implicit-descendant */
.awsui_box_18wu0_12k4n_207.awsui_p-variant_18wu0_12k4n_207.awsui_color-default_18wu0_12k4n_207:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_b-variant_18wu0_12k4n_207.awsui_color-default_18wu0_12k4n_207:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_strong-variant_18wu0_12k4n_207.awsui_color-default_18wu0_12k4n_207:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_code-variant_18wu0_12k4n_207.awsui_color-default_18wu0_12k4n_207:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_pre-variant_18wu0_12k4n_207.awsui_color-default_18wu0_12k4n_207:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_samp-variant_18wu0_12k4n_207.awsui_color-default_18wu0_12k4n_207:not(#\9) {
  color: #000716;
  color: var(--color-text-body-default-ajf1h5, #000716);
}

.awsui_box_18wu0_12k4n_207.awsui_h1-variant_18wu0_12k4n_211.awsui_color-default_18wu0_12k4n_207:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_h2-variant_18wu0_12k4n_211.awsui_color-default_18wu0_12k4n_207:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_h3-variant_18wu0_12k4n_211.awsui_color-default_18wu0_12k4n_207:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_h4-variant_18wu0_12k4n_211.awsui_color-default_18wu0_12k4n_207:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_h5-variant_18wu0_12k4n_211.awsui_color-default_18wu0_12k4n_207:not(#\9) {
  color: #000716;
  color: var(--color-text-heading-default-jopz0f, #000716);
}

.awsui_box_18wu0_12k4n_207.awsui_small-variant_18wu0_12k4n_215.awsui_color-default_18wu0_12k4n_207:not(#\9) {
  color: #5f6b7a;
  color: var(--color-text-small-le1y42, #5f6b7a);
}

.awsui_box_18wu0_12k4n_207.awsui_a-variant_18wu0_12k4n_219.awsui_color-default_18wu0_12k4n_207:not(#\9) {
  color: #0972d3;
  color: var(--color-text-link-default-5f186r, #0972d3);
}

.awsui_box_18wu0_12k4n_207.awsui_small-variant_18wu0_12k4n_215.awsui_font-size-default_18wu0_12k4n_223:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_code-variant_18wu0_12k4n_207.awsui_font-size-default_18wu0_12k4n_223:not(#\9) {
  font-size: 12px;
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: 16px;
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: 0.005em;
  letter-spacing: var(--font-body-s-letter-spacing-cy0oxj, 0.005em);
}

.awsui_box_18wu0_12k4n_207.awsui_p-variant_18wu0_12k4n_207.awsui_font-size-default_18wu0_12k4n_223:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_b-variant_18wu0_12k4n_207.awsui_font-size-default_18wu0_12k4n_223:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_strong-variant_18wu0_12k4n_207.awsui_font-size-default_18wu0_12k4n_223:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_pre-variant_18wu0_12k4n_207.awsui_font-size-default_18wu0_12k4n_223:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_samp-variant_18wu0_12k4n_207.awsui_font-size-default_18wu0_12k4n_223:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_a-variant_18wu0_12k4n_219.awsui_font-size-default_18wu0_12k4n_223:not(#\9) {
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
}

.awsui_box_18wu0_12k4n_207.awsui_h5-variant_18wu0_12k4n_211.awsui_font-size-default_18wu0_12k4n_223:not(#\9) {
  font-size: 14px;
  font-size: var(--font-heading-xs-size-fryxro, 14px);
  line-height: 18px;
  line-height: var(--font-heading-xs-line-height-5x131j, 18px);
}

.awsui_box_18wu0_12k4n_207.awsui_h4-variant_18wu0_12k4n_211.awsui_font-size-default_18wu0_12k4n_223:not(#\9) {
  font-size: 16px;
  font-size: var(--font-heading-s-size-9clg8y, 16px);
  line-height: 20px;
  line-height: var(--font-heading-s-line-height-5b7f0q, 20px);
  letter-spacing: -0.005em;
  letter-spacing: var(--font-heading-s-letter-spacing-4d69jc, -0.005em);
}

.awsui_box_18wu0_12k4n_207.awsui_h3-variant_18wu0_12k4n_211.awsui_font-size-default_18wu0_12k4n_223:not(#\9) {
  font-size: 20px;
  font-size: var(--font-heading-m-size-6e3jmg, 20px);
  line-height: 24px;
  line-height: var(--font-heading-m-line-height-u3t54f, 24px);
  letter-spacing: -0.01em;
  letter-spacing: var(--font-heading-m-letter-spacing-r00pg3, -0.01em);
}

.awsui_box_18wu0_12k4n_207.awsui_h2-variant_18wu0_12k4n_211.awsui_font-size-default_18wu0_12k4n_223:not(#\9) {
  font-size: 24px;
  font-size: var(--font-heading-l-size-otmxys, 24px);
  line-height: 30px;
  line-height: var(--font-heading-l-line-height-gev4n1, 30px);
  letter-spacing: -0.015em;
  letter-spacing: var(--font-heading-l-letter-spacing-f6wvtk, -0.015em);
}

.awsui_box_18wu0_12k4n_207.awsui_h1-variant_18wu0_12k4n_211.awsui_font-size-default_18wu0_12k4n_223:not(#\9) {
  font-size: 32px;
  font-size: var(--font-heading-xl-size-d0vjbk, 32px);
  line-height: 40px;
  line-height: var(--font-heading-xl-line-height-doxfdj, 40px);
  letter-spacing: -0.02em;
  letter-spacing: var(--font-heading-xl-letter-spacing-9tcsmy, -0.02em);
}

.awsui_box_18wu0_12k4n_207.awsui_p-variant_18wu0_12k4n_207.awsui_font-weight-default_18wu0_12k4n_263:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_code-variant_18wu0_12k4n_207.awsui_font-weight-default_18wu0_12k4n_263:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_pre-variant_18wu0_12k4n_207.awsui_font-weight-default_18wu0_12k4n_263:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_samp-variant_18wu0_12k4n_207.awsui_font-weight-default_18wu0_12k4n_263:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_small-variant_18wu0_12k4n_215.awsui_font-weight-default_18wu0_12k4n_263:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_a-variant_18wu0_12k4n_219.awsui_font-weight-default_18wu0_12k4n_263:not(#\9) {
  font-weight: 400;
}

.awsui_box_18wu0_12k4n_207.awsui_strong-variant_18wu0_12k4n_207.awsui_font-weight-default_18wu0_12k4n_263:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_b-variant_18wu0_12k4n_207.awsui_font-weight-default_18wu0_12k4n_263:not(#\9) {
  font-weight: 700;
}

.awsui_box_18wu0_12k4n_207.awsui_h5-variant_18wu0_12k4n_211.awsui_font-weight-default_18wu0_12k4n_263:not(#\9) {
  font-weight: 800;
  font-weight: var(--font-heading-xs-weight-wwznty, 800);
}

.awsui_box_18wu0_12k4n_207.awsui_h4-variant_18wu0_12k4n_211.awsui_font-weight-default_18wu0_12k4n_263:not(#\9) {
  font-weight: 800;
  font-weight: var(--font-heading-s-weight-k8ys41, 800);
}

.awsui_box_18wu0_12k4n_207.awsui_h3-variant_18wu0_12k4n_211.awsui_font-weight-default_18wu0_12k4n_263:not(#\9) {
  font-weight: 800;
  font-weight: var(--font-heading-m-weight-hi5n0j, 800);
}

.awsui_box_18wu0_12k4n_207.awsui_h2-variant_18wu0_12k4n_211.awsui_font-weight-default_18wu0_12k4n_263:not(#\9) {
  font-weight: 800;
  font-weight: var(--font-heading-l-weight-xm7ks9, 800);
}

.awsui_box_18wu0_12k4n_207.awsui_h1-variant_18wu0_12k4n_211.awsui_font-weight-default_18wu0_12k4n_263:not(#\9) {
  font-weight: 800;
  font-weight: var(--font-heading-xl-weight-772870, 800);
}

.awsui_box_18wu0_12k4n_207.awsui_h1-variant_18wu0_12k4n_211:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_h2-variant_18wu0_12k4n_211:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_h3-variant_18wu0_12k4n_211:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_h4-variant_18wu0_12k4n_211:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_h5-variant_18wu0_12k4n_211:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_p-variant_18wu0_12k4n_207:not(#\9) {
  margin: 0;
  text-decoration: none;
  padding: 4px 0;
  padding: var(--space-xxs-ynfts5, 4px) 0;
}
.awsui_box_18wu0_12k4n_207.awsui_small-variant_18wu0_12k4n_215:not(#\9) {
  display: inline-block;
}
.awsui_box_18wu0_12k4n_207.awsui_code-variant_18wu0_12k4n_207:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_pre-variant_18wu0_12k4n_207:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_samp-variant_18wu0_12k4n_207:not(#\9) {
  font-family: Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  font-family: var(--font-family-monospace-iz223z, Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace);
  background: transparent;
}
.awsui_box_18wu0_12k4n_207.awsui_key-label-variant_18wu0_12k4n_303:not(#\9) {
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  font-weight: 700;
  font-weight: var(--font-display-label-weight-m18hjh, 700);
  color: #000716;
  color: var(--color-text-label-gxskdy, #000716);
  margin-bottom: 2px;
  margin-bottom: var(--space-scaled-xxxs-b6dm8t, 2px);
}
.awsui_box_18wu0_12k4n_207.awsui_value-large-variant_18wu0_12k4n_310:not(#\9) {
  font-size: 48px;
  font-size: var(--font-display-l-size-03lgcb, 48px);
  line-height: 54px;
  line-height: var(--font-display-l-line-height-71tnj3, 54px);
  letter-spacing: -0.03em;
  letter-spacing: var(--font-display-l-letter-spacing-4ff418, -0.03em);
  font-weight: 800;
  font-weight: var(--font-box-value-large-weight-dyt1pj, 800);
  color: inherit;
}
.awsui_box_18wu0_12k4n_207.awsui_h1-variant_18wu0_12k4n_211.awsui_font-weight-default_18wu0_12k4n_263:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_h2-variant_18wu0_12k4n_211.awsui_font-weight-default_18wu0_12k4n_263:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_h3-variant_18wu0_12k4n_211.awsui_font-weight-default_18wu0_12k4n_263:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_h4-variant_18wu0_12k4n_211.awsui_font-weight-default_18wu0_12k4n_263:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_h5-variant_18wu0_12k4n_211.awsui_font-weight-default_18wu0_12k4n_263:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_value-large-variant_18wu0_12k4n_310.awsui_font-weight-default_18wu0_12k4n_263:not(#\9), .awsui_box_18wu0_12k4n_207.awsui_font-weight-heavy_18wu0_12k4n_317:not(#\9) {
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
}

.awsui_box_18wu0_12k4n_207.awsui_color-inverted_18wu0_12k4n_322:not(#\9) {
  color: #fbfbfb;
  color: var(--color-text-notification-default-2kpcxy, #fbfbfb);
}
.awsui_box_18wu0_12k4n_207.awsui_color-text-label_18wu0_12k4n_325:not(#\9) {
  color: #000716;
  color: var(--color-text-label-gxskdy, #000716);
}
.awsui_box_18wu0_12k4n_207.awsui_color-text-body-secondary_18wu0_12k4n_328:not(#\9) {
  color: #414d5c;
  color: var(--color-text-body-secondary-fdstdf, #414d5c);
}
.awsui_box_18wu0_12k4n_207.awsui_color-text-status-error_18wu0_12k4n_331:not(#\9) {
  color: #d91515;
  color: var(--color-text-status-error-5sesl6, #d91515);
}
.awsui_box_18wu0_12k4n_207.awsui_color-text-status-success_18wu0_12k4n_334:not(#\9) {
  color: #037f0c;
  color: var(--color-text-status-success-0fzhv9, #037f0c);
}
.awsui_box_18wu0_12k4n_207.awsui_color-text-status-info_18wu0_12k4n_337:not(#\9) {
  color: #0972d3;
  color: var(--color-text-status-info-utupcz, #0972d3);
}
.awsui_box_18wu0_12k4n_207.awsui_color-text-status-inactive_18wu0_12k4n_340:not(#\9) {
  color: #5f6b7a;
  color: var(--color-text-status-inactive-5ei55p, #5f6b7a);
}
.awsui_box_18wu0_12k4n_207.awsui_color-inherit_18wu0_12k4n_343:not(#\9) {
  color: inherit;
}
.awsui_box_18wu0_12k4n_207.awsui_font-size-body-s_18wu0_12k4n_346:not(#\9) {
  font-size: 12px;
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: 16px;
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: 0.005em;
  letter-spacing: var(--font-body-s-letter-spacing-cy0oxj, 0.005em);
}
.awsui_box_18wu0_12k4n_207.awsui_font-size-body-m_18wu0_12k4n_351:not(#\9) {
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
}
.awsui_box_18wu0_12k4n_207.awsui_font-size-heading-xs_18wu0_12k4n_355:not(#\9) {
  font-size: 14px;
  font-size: var(--font-heading-xs-size-fryxro, 14px);
  line-height: 18px;
  line-height: var(--font-heading-xs-line-height-5x131j, 18px);
}
.awsui_box_18wu0_12k4n_207.awsui_font-size-heading-s_18wu0_12k4n_359:not(#\9) {
  font-size: 16px;
  font-size: var(--font-heading-s-size-9clg8y, 16px);
  line-height: 20px;
  line-height: var(--font-heading-s-line-height-5b7f0q, 20px);
  letter-spacing: -0.005em;
  letter-spacing: var(--font-heading-s-letter-spacing-4d69jc, -0.005em);
}
.awsui_box_18wu0_12k4n_207.awsui_font-size-heading-m_18wu0_12k4n_364:not(#\9) {
  font-size: 20px;
  font-size: var(--font-heading-m-size-6e3jmg, 20px);
  line-height: 24px;
  line-height: var(--font-heading-m-line-height-u3t54f, 24px);
  letter-spacing: -0.01em;
  letter-spacing: var(--font-heading-m-letter-spacing-r00pg3, -0.01em);
}
.awsui_box_18wu0_12k4n_207.awsui_font-size-heading-l_18wu0_12k4n_369:not(#\9) {
  font-size: 24px;
  font-size: var(--font-heading-l-size-otmxys, 24px);
  line-height: 30px;
  line-height: var(--font-heading-l-line-height-gev4n1, 30px);
  letter-spacing: -0.015em;
  letter-spacing: var(--font-heading-l-letter-spacing-f6wvtk, -0.015em);
}
.awsui_box_18wu0_12k4n_207.awsui_font-size-heading-xl_18wu0_12k4n_374:not(#\9) {
  font-size: 32px;
  font-size: var(--font-heading-xl-size-d0vjbk, 32px);
  line-height: 40px;
  line-height: var(--font-heading-xl-line-height-doxfdj, 40px);
  letter-spacing: -0.02em;
  letter-spacing: var(--font-heading-xl-letter-spacing-9tcsmy, -0.02em);
}
.awsui_box_18wu0_12k4n_207.awsui_font-size-display-l_18wu0_12k4n_379:not(#\9) {
  font-size: 48px;
  font-size: var(--font-display-l-size-03lgcb, 48px);
  line-height: 54px;
  line-height: var(--font-display-l-line-height-71tnj3, 54px);
  letter-spacing: -0.03em;
  letter-spacing: var(--font-display-l-letter-spacing-4ff418, -0.03em);
}
.awsui_box_18wu0_12k4n_207.awsui_font-weight-light_18wu0_12k4n_384:not(#\9) {
  font-weight: 300;
}
.awsui_box_18wu0_12k4n_207.awsui_font-weight-normal_18wu0_12k4n_387:not(#\9) {
  font-weight: 400;
}
.awsui_box_18wu0_12k4n_207.awsui_font-weight-bold_18wu0_12k4n_390:not(#\9) {
  font-weight: 700;
}
.awsui_box_18wu0_12k4n_207.awsui_font-weight-heavy_18wu0_12k4n_317:not(#\9) {
  font-weight: 800;
  font-weight: var(--font-weight-heavy-ydka9r, 800);
}

.awsui_t-left_18wu0_12k4n_397:not(#\9) {
  text-align: left;
}

.awsui_t-right_18wu0_12k4n_401:not(#\9) {
  text-align: right;
}

.awsui_t-center_18wu0_12k4n_405:not(#\9) {
  text-align: center;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-implicit-descendant */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_box_18wu0_12k4n_207.awsui_p-n_18wu0_12k4n_506:not(#\9) {
  padding: 0 0;
  padding: var(--space-scaled-none-cnv7sd, 0) var(--space-none-esmv0r, 0);
}

.awsui_box_18wu0_12k4n_207.awsui_p-top-n_18wu0_12k4n_510:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-vertical-n_18wu0_12k4n_511:not(#\9) {
  padding-top: 0;
  padding-top: var(--space-scaled-none-cnv7sd, 0);
}

.awsui_box_18wu0_12k4n_207.awsui_p-right-n_18wu0_12k4n_515:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-horizontal-n_18wu0_12k4n_516:not(#\9) {
  padding-right: 0;
  padding-right: var(--space-none-esmv0r, 0);
}

.awsui_box_18wu0_12k4n_207.awsui_p-bottom-n_18wu0_12k4n_520:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-vertical-n_18wu0_12k4n_511:not(#\9) {
  padding-bottom: 0;
  padding-bottom: var(--space-scaled-none-cnv7sd, 0);
}

.awsui_box_18wu0_12k4n_207.awsui_p-left-n_18wu0_12k4n_525:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-horizontal-n_18wu0_12k4n_516:not(#\9) {
  padding-left: 0;
  padding-left: var(--space-none-esmv0r, 0);
}

.awsui_box_18wu0_12k4n_207.awsui_p-xxxs_18wu0_12k4n_530:not(#\9) {
  padding: 2px 2px;
  padding: var(--space-scaled-xxxs-b6dm8t, 2px) var(--space-xxxs-k2w98v, 2px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-top-xxxs_18wu0_12k4n_534:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-vertical-xxxs_18wu0_12k4n_535:not(#\9) {
  padding-top: 2px;
  padding-top: var(--space-scaled-xxxs-b6dm8t, 2px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-right-xxxs_18wu0_12k4n_539:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-horizontal-xxxs_18wu0_12k4n_540:not(#\9) {
  padding-right: 2px;
  padding-right: var(--space-xxxs-k2w98v, 2px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-bottom-xxxs_18wu0_12k4n_544:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-vertical-xxxs_18wu0_12k4n_535:not(#\9) {
  padding-bottom: 2px;
  padding-bottom: var(--space-scaled-xxxs-b6dm8t, 2px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-left-xxxs_18wu0_12k4n_549:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-horizontal-xxxs_18wu0_12k4n_540:not(#\9) {
  padding-left: 2px;
  padding-left: var(--space-xxxs-k2w98v, 2px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-xxs_18wu0_12k4n_554:not(#\9) {
  padding: 4px 4px;
  padding: var(--space-scaled-xxs-95dhkm, 4px) var(--space-xxs-ynfts5, 4px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-top-xxs_18wu0_12k4n_558:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-vertical-xxs_18wu0_12k4n_559:not(#\9) {
  padding-top: 4px;
  padding-top: var(--space-scaled-xxs-95dhkm, 4px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-right-xxs_18wu0_12k4n_563:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-horizontal-xxs_18wu0_12k4n_564:not(#\9) {
  padding-right: 4px;
  padding-right: var(--space-xxs-ynfts5, 4px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-bottom-xxs_18wu0_12k4n_568:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-vertical-xxs_18wu0_12k4n_559:not(#\9) {
  padding-bottom: 4px;
  padding-bottom: var(--space-scaled-xxs-95dhkm, 4px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-left-xxs_18wu0_12k4n_573:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-horizontal-xxs_18wu0_12k4n_564:not(#\9) {
  padding-left: 4px;
  padding-left: var(--space-xxs-ynfts5, 4px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-xs_18wu0_12k4n_578:not(#\9) {
  padding: 8px 8px;
  padding: var(--space-scaled-xs-6859qs, 8px) var(--space-xs-rsr2qu, 8px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-top-xs_18wu0_12k4n_582:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-vertical-xs_18wu0_12k4n_583:not(#\9) {
  padding-top: 8px;
  padding-top: var(--space-scaled-xs-6859qs, 8px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-right-xs_18wu0_12k4n_587:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-horizontal-xs_18wu0_12k4n_588:not(#\9) {
  padding-right: 8px;
  padding-right: var(--space-xs-rsr2qu, 8px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-bottom-xs_18wu0_12k4n_592:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-vertical-xs_18wu0_12k4n_583:not(#\9) {
  padding-bottom: 8px;
  padding-bottom: var(--space-scaled-xs-6859qs, 8px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-left-xs_18wu0_12k4n_597:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-horizontal-xs_18wu0_12k4n_588:not(#\9) {
  padding-left: 8px;
  padding-left: var(--space-xs-rsr2qu, 8px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-s_18wu0_12k4n_602:not(#\9) {
  padding: 12px 12px;
  padding: var(--space-scaled-s-cu1hzn, 12px) var(--space-s-hv8c1d, 12px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-top-s_18wu0_12k4n_606:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-vertical-s_18wu0_12k4n_607:not(#\9) {
  padding-top: 12px;
  padding-top: var(--space-scaled-s-cu1hzn, 12px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-right-s_18wu0_12k4n_611:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-horizontal-s_18wu0_12k4n_612:not(#\9) {
  padding-right: 12px;
  padding-right: var(--space-s-hv8c1d, 12px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-bottom-s_18wu0_12k4n_616:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-vertical-s_18wu0_12k4n_607:not(#\9) {
  padding-bottom: 12px;
  padding-bottom: var(--space-scaled-s-cu1hzn, 12px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-left-s_18wu0_12k4n_621:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-horizontal-s_18wu0_12k4n_612:not(#\9) {
  padding-left: 12px;
  padding-left: var(--space-s-hv8c1d, 12px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-m_18wu0_12k4n_626:not(#\9) {
  padding: 16px 16px;
  padding: var(--space-scaled-m-pv0fmt, 16px) var(--space-m-17eucw, 16px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-top-m_18wu0_12k4n_630:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-vertical-m_18wu0_12k4n_631:not(#\9) {
  padding-top: 16px;
  padding-top: var(--space-scaled-m-pv0fmt, 16px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-right-m_18wu0_12k4n_635:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-horizontal-m_18wu0_12k4n_636:not(#\9) {
  padding-right: 16px;
  padding-right: var(--space-m-17eucw, 16px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-bottom-m_18wu0_12k4n_640:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-vertical-m_18wu0_12k4n_631:not(#\9) {
  padding-bottom: 16px;
  padding-bottom: var(--space-scaled-m-pv0fmt, 16px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-left-m_18wu0_12k4n_645:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-horizontal-m_18wu0_12k4n_636:not(#\9) {
  padding-left: 16px;
  padding-left: var(--space-m-17eucw, 16px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-l_18wu0_12k4n_525:not(#\9) {
  padding: 20px 20px;
  padding: var(--space-scaled-l-t03y3z, 20px) var(--space-l-4vl6xu, 20px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-top-l_18wu0_12k4n_654:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-vertical-l_18wu0_12k4n_655:not(#\9) {
  padding-top: 20px;
  padding-top: var(--space-scaled-l-t03y3z, 20px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-right-l_18wu0_12k4n_659:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-horizontal-l_18wu0_12k4n_660:not(#\9) {
  padding-right: 20px;
  padding-right: var(--space-l-4vl6xu, 20px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-bottom-l_18wu0_12k4n_664:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-vertical-l_18wu0_12k4n_655:not(#\9) {
  padding-bottom: 20px;
  padding-bottom: var(--space-scaled-l-t03y3z, 20px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-left-l_18wu0_12k4n_669:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-horizontal-l_18wu0_12k4n_660:not(#\9) {
  padding-left: 20px;
  padding-left: var(--space-l-4vl6xu, 20px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-xl_18wu0_12k4n_674:not(#\9) {
  padding: 24px 24px;
  padding: var(--space-scaled-xl-w2t504, 24px) var(--space-xl-a39hup, 24px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-top-xl_18wu0_12k4n_678:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-vertical-xl_18wu0_12k4n_679:not(#\9) {
  padding-top: 24px;
  padding-top: var(--space-scaled-xl-w2t504, 24px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-right-xl_18wu0_12k4n_683:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-horizontal-xl_18wu0_12k4n_684:not(#\9) {
  padding-right: 24px;
  padding-right: var(--space-xl-a39hup, 24px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-bottom-xl_18wu0_12k4n_688:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-vertical-xl_18wu0_12k4n_679:not(#\9) {
  padding-bottom: 24px;
  padding-bottom: var(--space-scaled-xl-w2t504, 24px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-left-xl_18wu0_12k4n_693:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-horizontal-xl_18wu0_12k4n_684:not(#\9) {
  padding-left: 24px;
  padding-left: var(--space-xl-a39hup, 24px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-xxl_18wu0_12k4n_698:not(#\9) {
  padding: 32px 32px;
  padding: var(--space-scaled-xxl-pfgv96, 32px) var(--space-xxl-2nvmf1, 32px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-top-xxl_18wu0_12k4n_702:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-vertical-xxl_18wu0_12k4n_703:not(#\9) {
  padding-top: 32px;
  padding-top: var(--space-scaled-xxl-pfgv96, 32px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-right-xxl_18wu0_12k4n_707:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-horizontal-xxl_18wu0_12k4n_708:not(#\9) {
  padding-right: 32px;
  padding-right: var(--space-xxl-2nvmf1, 32px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-bottom-xxl_18wu0_12k4n_712:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-vertical-xxl_18wu0_12k4n_703:not(#\9) {
  padding-bottom: 32px;
  padding-bottom: var(--space-scaled-xxl-pfgv96, 32px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-left-xxl_18wu0_12k4n_717:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-horizontal-xxl_18wu0_12k4n_708:not(#\9) {
  padding-left: 32px;
  padding-left: var(--space-xxl-2nvmf1, 32px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-xxxl_18wu0_12k4n_722:not(#\9) {
  padding: 40px 40px;
  padding: var(--space-scaled-xxxl-ffokcj, 40px) var(--space-xxxl-bo9z7f, 40px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-top-xxxl_18wu0_12k4n_726:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-vertical-xxxl_18wu0_12k4n_727:not(#\9) {
  padding-top: 40px;
  padding-top: var(--space-scaled-xxxl-ffokcj, 40px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-right-xxxl_18wu0_12k4n_731:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-horizontal-xxxl_18wu0_12k4n_732:not(#\9) {
  padding-right: 40px;
  padding-right: var(--space-xxxl-bo9z7f, 40px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-bottom-xxxl_18wu0_12k4n_736:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-vertical-xxxl_18wu0_12k4n_727:not(#\9) {
  padding-bottom: 40px;
  padding-bottom: var(--space-scaled-xxxl-ffokcj, 40px);
}

.awsui_box_18wu0_12k4n_207.awsui_p-left-xxxl_18wu0_12k4n_741:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_p-horizontal-xxxl_18wu0_12k4n_732:not(#\9) {
  padding-left: 40px;
  padding-left: var(--space-xxxl-bo9z7f, 40px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-n_18wu0_12k4n_746:not(#\9) {
  margin: 0 0;
  margin: var(--space-scaled-none-cnv7sd, 0) var(--space-none-esmv0r, 0);
}

.awsui_box_18wu0_12k4n_207.awsui_m-top-n_18wu0_12k4n_750:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-vertical-n_18wu0_12k4n_751:not(#\9) {
  margin-top: 0;
  margin-top: var(--space-scaled-none-cnv7sd, 0);
}

.awsui_box_18wu0_12k4n_207.awsui_m-right-n_18wu0_12k4n_755:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-horizontal-n_18wu0_12k4n_756:not(#\9) {
  margin-right: 0;
  margin-right: var(--space-none-esmv0r, 0);
}

.awsui_box_18wu0_12k4n_207.awsui_m-bottom-n_18wu0_12k4n_760:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-vertical-n_18wu0_12k4n_751:not(#\9) {
  margin-bottom: 0;
  margin-bottom: var(--space-scaled-none-cnv7sd, 0);
}

.awsui_box_18wu0_12k4n_207.awsui_m-left-n_18wu0_12k4n_765:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-horizontal-n_18wu0_12k4n_756:not(#\9) {
  margin-left: 0;
  margin-left: var(--space-none-esmv0r, 0);
}

.awsui_box_18wu0_12k4n_207.awsui_m-xxxs_18wu0_12k4n_770:not(#\9) {
  margin: 2px 2px;
  margin: var(--space-scaled-xxxs-b6dm8t, 2px) var(--space-xxxs-k2w98v, 2px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-top-xxxs_18wu0_12k4n_774:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-vertical-xxxs_18wu0_12k4n_775:not(#\9) {
  margin-top: 2px;
  margin-top: var(--space-scaled-xxxs-b6dm8t, 2px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-right-xxxs_18wu0_12k4n_779:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-horizontal-xxxs_18wu0_12k4n_780:not(#\9) {
  margin-right: 2px;
  margin-right: var(--space-xxxs-k2w98v, 2px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-bottom-xxxs_18wu0_12k4n_784:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-vertical-xxxs_18wu0_12k4n_775:not(#\9) {
  margin-bottom: 2px;
  margin-bottom: var(--space-scaled-xxxs-b6dm8t, 2px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-left-xxxs_18wu0_12k4n_789:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-horizontal-xxxs_18wu0_12k4n_780:not(#\9) {
  margin-left: 2px;
  margin-left: var(--space-xxxs-k2w98v, 2px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-xxs_18wu0_12k4n_794:not(#\9) {
  margin: 4px 4px;
  margin: var(--space-scaled-xxs-95dhkm, 4px) var(--space-xxs-ynfts5, 4px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-top-xxs_18wu0_12k4n_798:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-vertical-xxs_18wu0_12k4n_799:not(#\9) {
  margin-top: 4px;
  margin-top: var(--space-scaled-xxs-95dhkm, 4px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-right-xxs_18wu0_12k4n_803:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-horizontal-xxs_18wu0_12k4n_804:not(#\9) {
  margin-right: 4px;
  margin-right: var(--space-xxs-ynfts5, 4px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-bottom-xxs_18wu0_12k4n_808:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-vertical-xxs_18wu0_12k4n_799:not(#\9) {
  margin-bottom: 4px;
  margin-bottom: var(--space-scaled-xxs-95dhkm, 4px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-left-xxs_18wu0_12k4n_813:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-horizontal-xxs_18wu0_12k4n_804:not(#\9) {
  margin-left: 4px;
  margin-left: var(--space-xxs-ynfts5, 4px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-xs_18wu0_12k4n_818:not(#\9) {
  margin: 8px 8px;
  margin: var(--space-scaled-xs-6859qs, 8px) var(--space-xs-rsr2qu, 8px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-top-xs_18wu0_12k4n_822:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-vertical-xs_18wu0_12k4n_823:not(#\9) {
  margin-top: 8px;
  margin-top: var(--space-scaled-xs-6859qs, 8px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-right-xs_18wu0_12k4n_827:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-horizontal-xs_18wu0_12k4n_828:not(#\9) {
  margin-right: 8px;
  margin-right: var(--space-xs-rsr2qu, 8px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-bottom-xs_18wu0_12k4n_832:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-vertical-xs_18wu0_12k4n_823:not(#\9) {
  margin-bottom: 8px;
  margin-bottom: var(--space-scaled-xs-6859qs, 8px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-left-xs_18wu0_12k4n_837:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-horizontal-xs_18wu0_12k4n_828:not(#\9) {
  margin-left: 8px;
  margin-left: var(--space-xs-rsr2qu, 8px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-s_18wu0_12k4n_842:not(#\9) {
  margin: 12px 12px;
  margin: var(--space-scaled-s-cu1hzn, 12px) var(--space-s-hv8c1d, 12px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-top-s_18wu0_12k4n_846:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-vertical-s_18wu0_12k4n_847:not(#\9) {
  margin-top: 12px;
  margin-top: var(--space-scaled-s-cu1hzn, 12px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-right-s_18wu0_12k4n_851:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-horizontal-s_18wu0_12k4n_852:not(#\9) {
  margin-right: 12px;
  margin-right: var(--space-s-hv8c1d, 12px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-bottom-s_18wu0_12k4n_856:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-vertical-s_18wu0_12k4n_847:not(#\9) {
  margin-bottom: 12px;
  margin-bottom: var(--space-scaled-s-cu1hzn, 12px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-left-s_18wu0_12k4n_861:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-horizontal-s_18wu0_12k4n_852:not(#\9) {
  margin-left: 12px;
  margin-left: var(--space-s-hv8c1d, 12px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-m_18wu0_12k4n_866:not(#\9) {
  margin: 16px 16px;
  margin: var(--space-scaled-m-pv0fmt, 16px) var(--space-m-17eucw, 16px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-top-m_18wu0_12k4n_870:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-vertical-m_18wu0_12k4n_871:not(#\9) {
  margin-top: 16px;
  margin-top: var(--space-scaled-m-pv0fmt, 16px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-right-m_18wu0_12k4n_875:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-horizontal-m_18wu0_12k4n_876:not(#\9) {
  margin-right: 16px;
  margin-right: var(--space-m-17eucw, 16px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-bottom-m_18wu0_12k4n_880:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-vertical-m_18wu0_12k4n_871:not(#\9) {
  margin-bottom: 16px;
  margin-bottom: var(--space-scaled-m-pv0fmt, 16px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-left-m_18wu0_12k4n_885:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-horizontal-m_18wu0_12k4n_876:not(#\9) {
  margin-left: 16px;
  margin-left: var(--space-m-17eucw, 16px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-l_18wu0_12k4n_765:not(#\9) {
  margin: 20px 20px;
  margin: var(--space-scaled-l-t03y3z, 20px) var(--space-l-4vl6xu, 20px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-top-l_18wu0_12k4n_894:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-vertical-l_18wu0_12k4n_895:not(#\9) {
  margin-top: 20px;
  margin-top: var(--space-scaled-l-t03y3z, 20px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-right-l_18wu0_12k4n_899:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-horizontal-l_18wu0_12k4n_900:not(#\9) {
  margin-right: 20px;
  margin-right: var(--space-l-4vl6xu, 20px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-bottom-l_18wu0_12k4n_904:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-vertical-l_18wu0_12k4n_895:not(#\9) {
  margin-bottom: 20px;
  margin-bottom: var(--space-scaled-l-t03y3z, 20px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-left-l_18wu0_12k4n_909:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-horizontal-l_18wu0_12k4n_900:not(#\9) {
  margin-left: 20px;
  margin-left: var(--space-l-4vl6xu, 20px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-xl_18wu0_12k4n_914:not(#\9) {
  margin: 24px 24px;
  margin: var(--space-scaled-xl-w2t504, 24px) var(--space-xl-a39hup, 24px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-top-xl_18wu0_12k4n_918:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-vertical-xl_18wu0_12k4n_919:not(#\9) {
  margin-top: 24px;
  margin-top: var(--space-scaled-xl-w2t504, 24px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-right-xl_18wu0_12k4n_923:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-horizontal-xl_18wu0_12k4n_924:not(#\9) {
  margin-right: 24px;
  margin-right: var(--space-xl-a39hup, 24px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-bottom-xl_18wu0_12k4n_928:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-vertical-xl_18wu0_12k4n_919:not(#\9) {
  margin-bottom: 24px;
  margin-bottom: var(--space-scaled-xl-w2t504, 24px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-left-xl_18wu0_12k4n_933:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-horizontal-xl_18wu0_12k4n_924:not(#\9) {
  margin-left: 24px;
  margin-left: var(--space-xl-a39hup, 24px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-xxl_18wu0_12k4n_938:not(#\9) {
  margin: 32px 32px;
  margin: var(--space-scaled-xxl-pfgv96, 32px) var(--space-xxl-2nvmf1, 32px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-top-xxl_18wu0_12k4n_942:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-vertical-xxl_18wu0_12k4n_943:not(#\9) {
  margin-top: 32px;
  margin-top: var(--space-scaled-xxl-pfgv96, 32px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-right-xxl_18wu0_12k4n_947:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-horizontal-xxl_18wu0_12k4n_948:not(#\9) {
  margin-right: 32px;
  margin-right: var(--space-xxl-2nvmf1, 32px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-bottom-xxl_18wu0_12k4n_952:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-vertical-xxl_18wu0_12k4n_943:not(#\9) {
  margin-bottom: 32px;
  margin-bottom: var(--space-scaled-xxl-pfgv96, 32px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-left-xxl_18wu0_12k4n_957:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-horizontal-xxl_18wu0_12k4n_948:not(#\9) {
  margin-left: 32px;
  margin-left: var(--space-xxl-2nvmf1, 32px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-xxxl_18wu0_12k4n_962:not(#\9) {
  margin: 40px 40px;
  margin: var(--space-scaled-xxxl-ffokcj, 40px) var(--space-xxxl-bo9z7f, 40px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-top-xxxl_18wu0_12k4n_966:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-vertical-xxxl_18wu0_12k4n_967:not(#\9) {
  margin-top: 40px;
  margin-top: var(--space-scaled-xxxl-ffokcj, 40px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-right-xxxl_18wu0_12k4n_971:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-horizontal-xxxl_18wu0_12k4n_972:not(#\9) {
  margin-right: 40px;
  margin-right: var(--space-xxxl-bo9z7f, 40px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-bottom-xxxl_18wu0_12k4n_976:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-vertical-xxxl_18wu0_12k4n_967:not(#\9) {
  margin-bottom: 40px;
  margin-bottom: var(--space-scaled-xxxl-ffokcj, 40px);
}

.awsui_box_18wu0_12k4n_207.awsui_m-left-xxxl_18wu0_12k4n_981:not(#\9),
.awsui_box_18wu0_12k4n_207.awsui_m-horizontal-xxxl_18wu0_12k4n_972:not(#\9) {
  margin-left: 40px;
  margin-left: var(--space-xxxl-bo9z7f, 40px);
}

.awsui_box_18wu0_12k4n_207.awsui_d-block_18wu0_12k4n_986:not(#\9) {
  display: block;
}
.awsui_box_18wu0_12k4n_207.awsui_d-inline_18wu0_12k4n_989:not(#\9) {
  display: inline;
}
.awsui_box_18wu0_12k4n_207.awsui_d-inline-block_18wu0_12k4n_992:not(#\9) {
  display: inline-block;
}
.awsui_box_18wu0_12k4n_207.awsui_d-none_18wu0_12k4n_995:not(#\9) {
  display: none;
}

.awsui_f-left_18wu0_12k4n_999:not(#\9) {
  float: left;
}

.awsui_f-right_18wu0_12k4n_1003:not(#\9) {
  float: right;
}

/* stylelint-enable no-invalid-position-at-import-rule */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_error-icon-shake-wrapper_14mhv_veozk_97:not(#\9) {
  -webkit-animation: awsui_awsui-motion-shake-horizontally_14mhv_veozk_1 165ms;
          animation: awsui_awsui-motion-shake-horizontally_14mhv_veozk_1 165ms;
  -webkit-animation: awsui_awsui-motion-shake-horizontally_14mhv_veozk_1 var(--motion-duration-refresh-only-medium-5flen2, 165ms);
          animation: awsui_awsui-motion-shake-horizontally_14mhv_veozk_1 var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@-webkit-keyframes awsui_awsui-motion-shake-horizontally_14mhv_veozk_1 {
  0% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  50% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
            animation-timing-function: cubic-bezier(0, 0, 0, 1);
    -webkit-animation-timing-function: var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
            animation-timing-function: var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}
@keyframes awsui_awsui-motion-shake-horizontally_14mhv_veozk_1 {
  0% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  50% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
            animation-timing-function: cubic-bezier(0, 0, 0, 1);
    -webkit-animation-timing-function: var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
            animation-timing-function: var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_error-icon-shake-wrapper_14mhv_veozk_97:not(#\9) {
    -webkit-animation: none;
            animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_error-icon-shake-wrapper_14mhv_veozk_97:not(#\9), .awsui-mode-entering .awsui_error-icon-shake-wrapper_14mhv_veozk_97:not(#\9) {
  -webkit-animation: none;
          animation: none;
  transition: none;
}

.awsui_error-icon-scale-wrapper_14mhv_veozk_124:not(#\9) {
  -webkit-animation: awsui_awsui-motion-scale-popup_14mhv_veozk_1 165ms cubic-bezier(0, 0, 0, 1);
          animation: awsui_awsui-motion-scale-popup_14mhv_veozk_1 165ms cubic-bezier(0, 0, 0, 1);
  -webkit-animation: awsui_awsui-motion-scale-popup_14mhv_veozk_1 var(--motion-duration-refresh-only-medium-5flen2, 165ms) var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
          animation: awsui_awsui-motion-scale-popup_14mhv_veozk_1 var(--motion-duration-refresh-only-medium-5flen2, 165ms) var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
}
@-webkit-keyframes awsui_awsui-motion-scale-popup_14mhv_veozk_1 {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes awsui_awsui-motion-scale-popup_14mhv_veozk_1 {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_error-icon-scale-wrapper_14mhv_veozk_124:not(#\9) {
    -webkit-animation: none;
            animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_error-icon-scale-wrapper_14mhv_veozk_124:not(#\9), .awsui-mode-entering .awsui_error-icon-scale-wrapper_14mhv_veozk_124:not(#\9) {
  -webkit-animation: none;
          animation: none;
  transition: none;
}

.awsui_error_14mhv_veozk_97:not(#\9) {
  -webkit-animation: awsui_awsui-motion-fade-in-0_14mhv_veozk_1 165ms cubic-bezier(0, 0, 0, 1);
          animation: awsui_awsui-motion-fade-in-0_14mhv_veozk_1 165ms cubic-bezier(0, 0, 0, 1);
  -webkit-animation: awsui_awsui-motion-fade-in-0_14mhv_veozk_1 var(--motion-duration-refresh-only-medium-5flen2, 165ms) var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
          animation: awsui_awsui-motion-fade-in-0_14mhv_veozk_1 var(--motion-duration-refresh-only-medium-5flen2, 165ms) var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
}
@-webkit-keyframes awsui_awsui-motion-fade-in-0_14mhv_veozk_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes awsui_awsui-motion-fade-in-0_14mhv_veozk_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_error_14mhv_veozk_97:not(#\9) {
    -webkit-animation: none;
            animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_error_14mhv_veozk_97:not(#\9), .awsui-mode-entering .awsui_error_14mhv_veozk_97:not(#\9) {
  -webkit-animation: none;
          animation: none;
  transition: none;
}

.awsui_root_14mhv_veozk_168:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: #000716;
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  box-sizing: border-box;
}

.awsui_label_14mhv_veozk_182:not(#\9) {
  box-sizing: border-box;
  color: #000716;
  color: var(--color-text-form-label-k3j0b4, #000716);
  display: inline;
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  font-weight: 700;
  font-weight: var(--font-display-label-weight-m18hjh, 700);
  margin-right: 8px;
  margin-right: var(--space-xs-rsr2qu, 8px);
}
.awsui_label_14mhv_veozk_182:not(#\9):only-child {
  margin: 0;
}

.awsui_info_14mhv_veozk_195:not(#\9) {
  padding-left: 8px;
  padding-left: var(--space-xs-rsr2qu, 8px);
  border-left: 2px solid #e9ebed;
  border-left: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}

.awsui_description_14mhv_veozk_200:not(#\9),
.awsui_constraint_14mhv_veozk_201:not(#\9) {
  color: #5f6b7a;
  color: var(--color-text-form-secondary-ih9x7l, #5f6b7a);
  font-size: 12px;
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: 16px;
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: 0.005em;
  letter-spacing: var(--font-body-s-letter-spacing-cy0oxj, 0.005em);
}

.awsui_hints_14mhv_veozk_208:not(#\9),
.awsui_constraint-has-error_14mhv_veozk_209:not(#\9) {
  padding-top: 4px;
  padding-top: var(--space-xxs-ynfts5, 4px);
}

.awsui_secondary-control_14mhv_veozk_213:not(#\9) {
  /* used in test-utils */
}

.awsui_controls_14mhv_veozk_217:not(#\9):not(.awsui_label-hidden_14mhv_veozk_217) {
  padding-top: 4px;
  padding-top: var(--space-xxs-ynfts5, 4px);
}

.awsui_control_14mhv_veozk_217:not(#\9) {
  min-width: 0;
  -ms-word-break: break-all;
  word-break: break-word;
}

.awsui_error_14mhv_veozk_97:not(#\9) {
  color: #d91515;
  color: var(--color-text-status-error-5sesl6, #d91515);
  font-size: 12px;
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: 16px;
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: 0.005em;
  letter-spacing: var(--font-body-s-letter-spacing-cy0oxj, 0.005em);
  display: flex;
  align-items: flex-start;
}

.awsui_error__message_14mhv_veozk_236:not(#\9) {
  margin-left: 4px;
  margin-left: var(--space-xxs-ynfts5, 4px);
}

.awsui_visually-hidden_14mhv_veozk_240:not(#\9) {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable selector-max-universal, selector-max-type */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_icon_h11ix_ahfiu_98:not(#\9) {
  position: relative;
  display: inline-block;
  vertical-align: top;
  /* stylelint-disable-next-line selector-max-type */
}
.awsui_icon-flex-height_h11ix_ahfiu_104:not(#\9) {
  display: inline-flex;
  align-items: center;
}
.awsui_icon_h11ix_ahfiu_98 > svg:not(#\9) {
  pointer-events: none;
}
.awsui_icon_h11ix_ahfiu_98 > svg:not(#\9) {
  fill: none;
}
.awsui_icon_h11ix_ahfiu_98 > svg *:not(#\9) {
  stroke: currentColor;
}
.awsui_icon_h11ix_ahfiu_98 > svg .stroke-linejoin-round:not(#\9) {
  stroke-linejoin: round;
}
.awsui_icon_h11ix_ahfiu_98 > svg .stroke-linecap-square:not(#\9) {
  stroke-linecap: square;
}
.awsui_icon_h11ix_ahfiu_98 > svg .stroke-linecap-round:not(#\9) {
  stroke-linecap: round;
}
.awsui_icon_h11ix_ahfiu_98 > svg .filled:not(#\9) {
  fill: currentColor;
}
.awsui_icon_h11ix_ahfiu_98.awsui_size-small_h11ix_ahfiu_129:not(#\9) {
  width: 16px;
  width: var(--size-icon-normal-j1z5fn, 16px);
  box-sizing: border-box;
}
.awsui_icon_h11ix_ahfiu_98.awsui_size-small-mapped-height_h11ix_ahfiu_133:not(#\9) {
  height: 16px;
  height: var(--font-body-s-line-height-kdsbrl, 16px);
  padding: calc((16px - 16px) / 2) 0;
  padding: calc((var(--font-body-s-line-height-kdsbrl, 16px) - var(--size-icon-normal-j1z5fn, 16px)) / 2) 0;
}
.awsui_icon_h11ix_ahfiu_98.awsui_size-small_h11ix_ahfiu_129 > svg:not(#\9),
.awsui_icon_h11ix_ahfiu_98.awsui_size-small_h11ix_ahfiu_129 > img:not(#\9) {
  width: 16px;
  width: var(--size-icon-normal-j1z5fn, 16px);
  height: 16px;
  height: var(--size-icon-normal-j1z5fn, 16px);
  vertical-align: top;
}
.awsui_icon_h11ix_ahfiu_98.awsui_size-small_h11ix_ahfiu_129 > svg:not(#\9),
.awsui_icon_h11ix_ahfiu_98.awsui_size-small_h11ix_ahfiu_129 > svg *:not(#\9) {
  stroke-width: 2px;
}
.awsui_icon_h11ix_ahfiu_98.awsui_size-normal_h11ix_ahfiu_147:not(#\9) {
  width: 16px;
  width: var(--size-icon-normal-j1z5fn, 16px);
  box-sizing: border-box;
}
.awsui_icon_h11ix_ahfiu_98.awsui_size-normal-mapped-height_h11ix_ahfiu_151:not(#\9) {
  height: 22px;
  height: var(--font-body-m-line-height-i7xxvv, 22px);
  padding: calc((22px - 16px) / 2) 0;
  padding: calc((var(--font-body-m-line-height-i7xxvv, 22px) - var(--size-icon-normal-j1z5fn, 16px)) / 2) 0;
}
.awsui_icon_h11ix_ahfiu_98.awsui_size-normal_h11ix_ahfiu_147 > svg:not(#\9),
.awsui_icon_h11ix_ahfiu_98.awsui_size-normal_h11ix_ahfiu_147 > img:not(#\9) {
  width: 16px;
  width: var(--size-icon-normal-j1z5fn, 16px);
  height: 16px;
  height: var(--size-icon-normal-j1z5fn, 16px);
  vertical-align: top;
}
.awsui_icon_h11ix_ahfiu_98.awsui_size-normal_h11ix_ahfiu_147 > svg:not(#\9),
.awsui_icon_h11ix_ahfiu_98.awsui_size-normal_h11ix_ahfiu_147 > svg *:not(#\9) {
  stroke-width: 2px;
}
.awsui_icon_h11ix_ahfiu_98.awsui_size-medium_h11ix_ahfiu_165:not(#\9) {
  width: 20px;
  width: var(--size-icon-medium-fvz0oi, 20px);
  box-sizing: border-box;
}
.awsui_icon_h11ix_ahfiu_98.awsui_size-medium-mapped-height_h11ix_ahfiu_169:not(#\9) {
  height: 30px;
  height: var(--font-heading-l-line-height-gev4n1, 30px);
  padding: calc((30px - 20px) / 2) 0;
  padding: calc((var(--font-heading-l-line-height-gev4n1, 30px) - var(--size-icon-medium-fvz0oi, 20px)) / 2) 0;
}
.awsui_icon_h11ix_ahfiu_98.awsui_size-medium_h11ix_ahfiu_165 > svg:not(#\9),
.awsui_icon_h11ix_ahfiu_98.awsui_size-medium_h11ix_ahfiu_165 > img:not(#\9) {
  width: 20px;
  width: var(--size-icon-medium-fvz0oi, 20px);
  height: 20px;
  height: var(--size-icon-medium-fvz0oi, 20px);
  vertical-align: top;
}
.awsui_icon_h11ix_ahfiu_98.awsui_size-medium_h11ix_ahfiu_165 > svg:not(#\9),
.awsui_icon_h11ix_ahfiu_98.awsui_size-medium_h11ix_ahfiu_165 > svg *:not(#\9) {
  stroke-width: 2px;
}
.awsui_icon_h11ix_ahfiu_98.awsui_size-big_h11ix_ahfiu_183:not(#\9) {
  width: 32px;
  width: var(--size-icon-big-cu55wt, 32px);
  box-sizing: border-box;
}
.awsui_icon_h11ix_ahfiu_98.awsui_size-big-mapped-height_h11ix_ahfiu_187:not(#\9) {
  height: 40px;
  height: var(--font-heading-xl-line-height-doxfdj, 40px);
  padding: calc((40px - 32px) / 2) 0;
  padding: calc((var(--font-heading-xl-line-height-doxfdj, 40px) - var(--size-icon-big-cu55wt, 32px)) / 2) 0;
}
.awsui_icon_h11ix_ahfiu_98.awsui_size-big_h11ix_ahfiu_183 > svg:not(#\9),
.awsui_icon_h11ix_ahfiu_98.awsui_size-big_h11ix_ahfiu_183 > img:not(#\9) {
  width: 32px;
  width: var(--size-icon-big-cu55wt, 32px);
  height: 32px;
  height: var(--size-icon-big-cu55wt, 32px);
  vertical-align: top;
}
.awsui_icon_h11ix_ahfiu_98.awsui_size-big_h11ix_ahfiu_183 > svg:not(#\9),
.awsui_icon_h11ix_ahfiu_98.awsui_size-big_h11ix_ahfiu_183 > svg *:not(#\9) {
  stroke-width: 2px;
}
.awsui_icon_h11ix_ahfiu_98.awsui_size-large_h11ix_ahfiu_201:not(#\9) {
  width: 48px;
  width: var(--size-icon-large-9vh494, 48px);
  box-sizing: border-box;
}
.awsui_icon_h11ix_ahfiu_98.awsui_size-large-mapped-height_h11ix_ahfiu_205:not(#\9) {
  height: 54px;
  height: var(--font-display-l-line-height-71tnj3, 54px);
  padding: calc((54px - 48px) / 2) 0;
  padding: calc((var(--font-display-l-line-height-71tnj3, 54px) - var(--size-icon-large-9vh494, 48px)) / 2) 0;
}
.awsui_icon_h11ix_ahfiu_98.awsui_size-large_h11ix_ahfiu_201 > svg:not(#\9),
.awsui_icon_h11ix_ahfiu_98.awsui_size-large_h11ix_ahfiu_201 > img:not(#\9) {
  width: 48px;
  width: var(--size-icon-large-9vh494, 48px);
  height: 48px;
  height: var(--size-icon-large-9vh494, 48px);
  vertical-align: top;
}
.awsui_icon_h11ix_ahfiu_98.awsui_size-large_h11ix_ahfiu_201 > svg:not(#\9),
.awsui_icon_h11ix_ahfiu_98.awsui_size-large_h11ix_ahfiu_201 > svg *:not(#\9) {
  stroke-width: 1.2px;
}
.awsui_icon_h11ix_ahfiu_98.awsui_variant-normal_h11ix_ahfiu_219:not(#\9) {
  color: currentColor;
}
.awsui_icon_h11ix_ahfiu_98.awsui_variant-disabled_h11ix_ahfiu_222:not(#\9) {
  color: #9ba7b6;
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
}
.awsui_icon_h11ix_ahfiu_98.awsui_variant-inverted_h11ix_ahfiu_225:not(#\9) {
  color: #ffffff;
  color: var(--color-text-inverted-821sko, #ffffff);
}
.awsui_icon_h11ix_ahfiu_98.awsui_variant-subtle_h11ix_ahfiu_228:not(#\9) {
  color: #5f6b7a;
  color: var(--color-text-icon-subtle-bz5b7h, #5f6b7a);
}
.awsui_icon_h11ix_ahfiu_98.awsui_variant-warning_h11ix_ahfiu_231:not(#\9) {
  color: #d91515;
  color: var(--color-text-status-warning-27nlyh, #d91515);
}
.awsui_icon_h11ix_ahfiu_98.awsui_variant-error_h11ix_ahfiu_234:not(#\9) {
  color: #d91515;
  color: var(--color-text-status-error-5sesl6, #d91515);
}
.awsui_icon_h11ix_ahfiu_98.awsui_variant-success_h11ix_ahfiu_237:not(#\9) {
  color: #037f0c;
  color: var(--color-text-status-success-0fzhv9, #037f0c);
}
.awsui_icon_h11ix_ahfiu_98.awsui_variant-link_h11ix_ahfiu_240:not(#\9) {
  color: #0972d3;
  color: var(--color-text-link-default-5f186r, #0972d3);
}

.awsui_badge_h11ix_ahfiu_244:not(#\9)::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: #0972d3;
  background-color: var(--color-text-status-info-utupcz, #0972d3);
  top: 2px;
  right: -2px;
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_2rhyz_1mdn9_93:not(#\9) {
  /* used in test-utils for component to distinguish input from other input-like components, for example autosuggest */
}

.awsui_input_2rhyz_1mdn9_97:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: #000716;
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  padding: 4px 12px;
  padding: var(--space-scaled-xxs-95dhkm, 4px) var(--space-field-horizontal-89h2yr, 12px);
  color: #000716;
  color: var(--color-text-body-default-ajf1h5, #000716);
  width: 100%;
  cursor: text;
  box-sizing: border-box;
  background-color: #ffffff;
  background-color: var(--color-background-input-default-u57378, #ffffff);
  border-radius: 8px;
  border-radius: var(--border-radius-input-txq8zy, 8px);
  border: 2px solid #7d8998;
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-input-default-e47bkv, #7d8998);
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  height: 34px;
  height: var(--size-vertical-input-js5hfh, 34px);
}
.awsui_input_2rhyz_1mdn9_97.awsui_input-readonly_2rhyz_1mdn9_120:not(#\9) {
  background-color: #ffffff;
  background-color: var(--color-background-input-default-u57378, #ffffff);
  border: 2px solid #e9ebed;
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-input-disabled-x2lopj, #e9ebed);
}
.awsui_input_2rhyz_1mdn9_97:not(#\9)::-webkit-input-placeholder {
  color: #5f6b7a;
  color: var(--color-text-input-placeholder-j53j3c, #5f6b7a);
  font-style: italic;
  -webkit-user-select: none;
          user-select: none;
}
.awsui_input_2rhyz_1mdn9_97:not(#\9)::-ms-input-placeholder {
  color: #5f6b7a;
  color: var(--color-text-input-placeholder-j53j3c, #5f6b7a);
  font-style: italic;
}
.awsui_input_2rhyz_1mdn9_97:not(#\9)::-moz-placeholder {
  color: #5f6b7a;
  color: var(--color-text-input-placeholder-j53j3c, #5f6b7a);
  font-style: italic;
  opacity: 1;
}
.awsui_input_2rhyz_1mdn9_97:not(#\9):-ms-input-placeholder {
  color: #5f6b7a;
  color: var(--color-text-input-placeholder-j53j3c, #5f6b7a);
  font-style: italic;
}
.awsui_input_2rhyz_1mdn9_97:not(#\9):-moz-placeholder {
  color: #5f6b7a;
  color: var(--color-text-input-placeholder-j53j3c, #5f6b7a);
  font-style: italic;
  opacity: 1;
}
.awsui_input_2rhyz_1mdn9_97:not(#\9):focus {
  outline: 2px dotted transparent;
  border: 2px solid #0972d3;
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-item-focused-ap3b6s, #0972d3);
  border-radius: 8px;
  border-radius: var(--border-radius-input-txq8zy, 8px);
  box-shadow: 0 0 0 0px #0972d3;
  box-shadow: 0 0 0 var(--border-control-focus-ring-shadow-spread-srzyiu, 0px) var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_input_2rhyz_1mdn9_97:not(#\9):disabled {
  background-color: #e9ebed;
  background-color: var(--color-background-input-disabled-wu9j6u, #e9ebed);
  border: 2px solid #e9ebed;
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-input-disabled-x2lopj, #e9ebed);
  color: #9ba7b6;
  color: var(--color-text-input-disabled-oawyzo, #9ba7b6);
  cursor: auto;
  cursor: default;
}
.awsui_input_2rhyz_1mdn9_97:not(#\9):disabled::-webkit-input-placeholder {
  color: #9ba7b6;
  color: var(--color-text-input-placeholder-disabled-6928j8, #9ba7b6);
  -webkit-user-select: none;
          user-select: none;
}
.awsui_input_2rhyz_1mdn9_97:not(#\9):disabled::-ms-input-placeholder {
  color: #9ba7b6;
  color: var(--color-text-input-placeholder-disabled-6928j8, #9ba7b6);
}
.awsui_input_2rhyz_1mdn9_97:not(#\9):disabled::-moz-placeholder {
  color: #9ba7b6;
  color: var(--color-text-input-placeholder-disabled-6928j8, #9ba7b6);
  opacity: 1;
}
.awsui_input_2rhyz_1mdn9_97:not(#\9):disabled:-ms-input-placeholder {
  color: #9ba7b6;
  color: var(--color-text-input-placeholder-disabled-6928j8, #9ba7b6);
}
.awsui_input_2rhyz_1mdn9_97:not(#\9):disabled:-moz-placeholder {
  color: #9ba7b6;
  color: var(--color-text-input-placeholder-disabled-6928j8, #9ba7b6);
  opacity: 1;
}
.awsui_input_2rhyz_1mdn9_97:not(#\9):invalid {
  box-shadow: none;
}
.awsui_input_2rhyz_1mdn9_97.awsui_input-invalid_2rhyz_1mdn9_181:not(#\9) {
  color: #d91515;
  color: var(--color-text-status-error-5sesl6, #d91515);
  border-color: #d91515;
  border-color: var(--color-text-status-error-5sesl6, #d91515);
  padding-left: calc(
  12px - (8px - 2px)
);
  padding-left: calc(
  var(--space-field-horizontal-89h2yr, 12px) - (var(--border-invalid-width-5jpdqn, 8px) - var(--border-field-width-idlekx, 2px))
);
  border-left-width: 8px;
  border-left-width: var(--border-invalid-width-5jpdqn, 8px);
}
.awsui_input_2rhyz_1mdn9_97.awsui_input-invalid_2rhyz_1mdn9_181:not(#\9):focus {
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 var(--border-control-invalid-focus-ring-shadow-spread-omt6il, 2px) var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_input_2rhyz_1mdn9_97.awsui_input-invalid_2rhyz_1mdn9_181.awsui_input-has-icon-left_2rhyz_1mdn9_192:not(#\9) {
  padding-left: calc(
        36px - (8px - 2px)
      );
  padding-left: calc(
        var(--space-field-icon-offset-25xih0, 36px) - (var(--border-invalid-width-5jpdqn, 8px) - var(--border-field-width-idlekx, 2px))
      );
}
.awsui_input_2rhyz_1mdn9_97.awsui_input-type-search_2rhyz_1mdn9_197:not(#\9) {
  box-sizing: border-box;
  -webkit-appearance: none; /* stylelint-disable-line plugin/no-unsupported-browser-features */
}
.awsui_input_2rhyz_1mdn9_97.awsui_input-type-search_2rhyz_1mdn9_197:not(#\9)::-ms-clear {
  display: none;
}
.awsui_input_2rhyz_1mdn9_97.awsui_input-type-search_2rhyz_1mdn9_197:not(#\9)::-webkit-search-decoration {
  -webkit-appearance: none; /* stylelint-disable-line plugin/no-unsupported-browser-features */
}
.awsui_input_2rhyz_1mdn9_97.awsui_input-type-search_2rhyz_1mdn9_197:not(#\9)::-webkit-search-cancel-button {
  display: none;
}
.awsui_input_2rhyz_1mdn9_97.awsui_input-has-icon-left_2rhyz_1mdn9_192:not(#\9) {
  padding-left: 36px;
  padding-left: var(--space-field-icon-offset-25xih0, 36px);
}
.awsui_input_2rhyz_1mdn9_97.awsui_input-has-icon-right_2rhyz_1mdn9_213:not(#\9) {
  padding-right: 36px;
  padding-right: var(--space-field-icon-offset-25xih0, 36px);
}
.awsui_input_2rhyz_1mdn9_97.awsui_input-has-no-border-radius_2rhyz_1mdn9_216:not(#\9) {
  border-radius: 8px;
  border-radius: var(--border-radius-dropdown-ujknaf, 8px);
}

.awsui_input-container_2rhyz_1mdn9_220:not(#\9) {
  display: flex;
  position: relative;
}

.awsui_input-icon-left_2rhyz_1mdn9_225:not(#\9) {
  position: absolute;
  pointer-events: none;
  left: 12px;
  left: var(--space-field-horizontal-89h2yr, 12px);
  top: calc(50% - 22px / 2);
  top: calc(50% - var(--font-body-m-line-height-i7xxvv, 22px) / 2);
}

.awsui_input-icon-right_2rhyz_1mdn9_232:not(#\9) {
  position: absolute;
  top: calc(50% - 22px / 2);
  top: calc(50% - var(--font-body-m-line-height-i7xxvv, 22px) / 2);
  right: calc(12px - 4px);
  right: calc(var(--space-field-horizontal-89h2yr, 12px) - var(--space-xxs-ynfts5, 4px));
}

.awsui_input-button-right_2rhyz_1mdn9_238:not(#\9) {
  /* used in test-utils */
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_content_vjswe_qgmad_97:not(#\9) {
  /* used in test-utils */
}

.awsui_button_vjswe_qgmad_101:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: #000716;
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  min-width: 0;
  -ms-word-break: break-all;
  word-break: break-word;
  font-weight: 800;
  font-weight: var(--font-button-weight-7rj5fx, 800);
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
  letter-spacing: 0.005em;
  letter-spacing: var(--font-button-letter-spacing-8y5poz, 0.005em);
  border-radius: 20px;
  border-radius: var(--border-radius-button-axewzv, 20px);
  border: 2px solid;
  border: var(--border-field-width-idlekx, 2px) solid;
  padding: 4px 20px;
  padding: var(--space-scaled-xxs-95dhkm, 4px) var(--space-l-4vl6xu, 20px);
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-normal_vjswe_qgmad_126:not(#\9) {
  background: #ffffff;
  background: var(--color-background-button-normal-default-syw2p1, #ffffff);
  color: #0972d3;
  color: var(--color-text-button-normal-default-u8647m, #0972d3);
  border-color: #0972d3;
  border-color: var(--color-border-button-normal-default-djv8f5, #0972d3);
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-normal_vjswe_qgmad_126:not(#\9):hover {
  background: #f2f8fd;
  background: var(--color-background-button-normal-hover-c3qbgm, #f2f8fd);
  color: #033160;
  color: var(--color-text-button-normal-hover-kxmvcc, #033160);
  border-color: #033160;
  border-color: var(--color-border-button-normal-hover-e1d0kh, #033160);
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-normal_vjswe_qgmad_126:not(#\9):active, .awsui_button_vjswe_qgmad_101.awsui_variant-normal_vjswe_qgmad_126.awsui_is-activated_vjswe_qgmad_139:not(#\9) {
  background: #d3e7f9;
  background: var(--color-background-button-normal-active-9lyks0, #d3e7f9);
  color: #033160;
  color: var(--color-text-button-normal-active-6obrzh, #033160);
  border-color: #033160;
  border-color: var(--color-border-button-normal-active-zbmaft, #033160);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-normal_vjswe_qgmad_126:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-normal_vjswe_qgmad_126[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-normal_vjswe_qgmad_126[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(4px - 1px);
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-normal_vjswe_qgmad_126[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 4px);
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * 4px);
  top: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  width: calc(100% + 2 * 4px);
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * 4px);
  height: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-normal_vjswe_qgmad_126.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-normal_vjswe_qgmad_126.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-normal_vjswe_qgmad_126.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-normal_vjswe_qgmad_126.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-normal_vjswe_qgmad_126.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-normal_vjswe_qgmad_126.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(4px - 1px);
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-normal_vjswe_qgmad_126.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_qgmad_101.awsui_variant-normal_vjswe_qgmad_126.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_qgmad_101.awsui_variant-normal_vjswe_qgmad_126.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 4px);
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * 0px);
  top: calc(-1 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  width: calc(100% + 2 * 4px);
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * 0px);
  height: calc(100% + 2 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-normal_vjswe_qgmad_126.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-normal_vjswe_qgmad_126.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(0px - 1px);
  outline-offset: calc(var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-normal_vjswe_qgmad_126.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 0px);
  left: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  top: calc(-1 * 0px);
  top: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  width: calc(100% + 2 * 0px);
  width: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  height: calc(100% + 2 * 0px);
  height: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-normal_vjswe_qgmad_126.awsui_disabled_vjswe_qgmad_202:not(#\9) {
  background: #ffffff;
  background: var(--color-background-button-normal-disabled-z5vd4i, #ffffff);
  border-color: #9ba7b6;
  border-color: var(--color-border-button-normal-disabled-262a0x, #9ba7b6);
  color: #9ba7b6;
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
  text-decoration: none;
  pointer-events: none;
  cursor: auto;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-primary_vjswe_qgmad_210:not(#\9) {
  background: #0972d3;
  background: var(--color-background-button-primary-default-pwn1g1, #0972d3);
  color: #ffffff;
  color: var(--color-text-button-primary-default-340wh6, #ffffff);
  border-color: #0972d3;
  border-color: var(--color-background-button-primary-default-pwn1g1, #0972d3);
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-primary_vjswe_qgmad_210:not(#\9):hover {
  background: #033160;
  background: var(--color-background-button-primary-hover-qu1zio, #033160);
  color: #ffffff;
  color: var(--color-text-button-primary-hover-vzqjpi, #ffffff);
  border-color: #033160;
  border-color: var(--color-background-button-primary-hover-qu1zio, #033160);
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-primary_vjswe_qgmad_210:not(#\9):active, .awsui_button_vjswe_qgmad_101.awsui_variant-primary_vjswe_qgmad_210.awsui_is-activated_vjswe_qgmad_139:not(#\9) {
  background: #033160;
  background: var(--color-background-button-primary-active-vg0knj, #033160);
  color: #ffffff;
  color: var(--color-text-button-primary-active-vems2x, #ffffff);
  border-color: #033160;
  border-color: var(--color-background-button-primary-active-vg0knj, #033160);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-primary_vjswe_qgmad_210:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-primary_vjswe_qgmad_210[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-primary_vjswe_qgmad_210[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(4px - 1px);
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-primary_vjswe_qgmad_210[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 4px);
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * 4px);
  top: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  width: calc(100% + 2 * 4px);
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * 4px);
  height: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-primary_vjswe_qgmad_210.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-primary_vjswe_qgmad_210.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-primary_vjswe_qgmad_210.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-primary_vjswe_qgmad_210.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-primary_vjswe_qgmad_210.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-primary_vjswe_qgmad_210.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(4px - 1px);
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-primary_vjswe_qgmad_210.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_qgmad_101.awsui_variant-primary_vjswe_qgmad_210.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_qgmad_101.awsui_variant-primary_vjswe_qgmad_210.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 4px);
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * 0px);
  top: calc(-1 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  width: calc(100% + 2 * 4px);
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * 0px);
  height: calc(100% + 2 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-primary_vjswe_qgmad_210.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-primary_vjswe_qgmad_210.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(0px - 1px);
  outline-offset: calc(var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-primary_vjswe_qgmad_210.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 0px);
  left: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  top: calc(-1 * 0px);
  top: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  width: calc(100% + 2 * 0px);
  width: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  height: calc(100% + 2 * 0px);
  height: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-primary_vjswe_qgmad_210.awsui_disabled_vjswe_qgmad_202:not(#\9) {
  background: #e9ebed;
  background: var(--color-background-button-primary-disabled-eon7km, #e9ebed);
  border-color: #e9ebed;
  border-color: var(--color-border-button-primary-disabled-6gwabt, #e9ebed);
  color: #9ba7b6;
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
  text-decoration: none;
  pointer-events: none;
  cursor: auto;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-link_vjswe_qgmad_294:not(#\9) {
  background: transparent;
  color: #0972d3;
  color: var(--color-text-button-normal-default-u8647m, #0972d3);
  border-color: transparent;
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-link_vjswe_qgmad_294:not(#\9):hover {
  background: #f2f8fd;
  background: var(--color-background-button-link-hover-69sbpz, #f2f8fd);
  color: #033160;
  color: var(--color-text-button-normal-hover-kxmvcc, #033160);
  border-color: #f2f8fd;
  border-color: var(--color-background-button-link-hover-69sbpz, #f2f8fd);
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-link_vjswe_qgmad_294:not(#\9):active, .awsui_button_vjswe_qgmad_101.awsui_variant-link_vjswe_qgmad_294.awsui_is-activated_vjswe_qgmad_139:not(#\9) {
  background: #d3e7f9;
  background: var(--color-background-button-link-active-t13dka, #d3e7f9);
  color: #033160;
  color: var(--color-text-button-normal-active-6obrzh, #033160);
  border-color: #d3e7f9;
  border-color: var(--color-background-button-link-active-t13dka, #d3e7f9);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-link_vjswe_qgmad_294:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-link_vjswe_qgmad_294[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-link_vjswe_qgmad_294[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(4px - 1px);
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-link_vjswe_qgmad_294[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 4px);
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * 4px);
  top: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  width: calc(100% + 2 * 4px);
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * 4px);
  height: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-link_vjswe_qgmad_294.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-link_vjswe_qgmad_294.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-link_vjswe_qgmad_294.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-link_vjswe_qgmad_294.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-link_vjswe_qgmad_294.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-link_vjswe_qgmad_294.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(4px - 1px);
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-link_vjswe_qgmad_294.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_qgmad_101.awsui_variant-link_vjswe_qgmad_294.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_qgmad_101.awsui_variant-link_vjswe_qgmad_294.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 4px);
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * 0px);
  top: calc(-1 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  width: calc(100% + 2 * 4px);
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * 0px);
  height: calc(100% + 2 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-link_vjswe_qgmad_294.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-link_vjswe_qgmad_294.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(0px - 1px);
  outline-offset: calc(var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-link_vjswe_qgmad_294.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 0px);
  left: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  top: calc(-1 * 0px);
  top: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  width: calc(100% + 2 * 0px);
  width: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  height: calc(100% + 2 * 0px);
  height: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-link_vjswe_qgmad_294.awsui_disabled_vjswe_qgmad_202:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: #9ba7b6;
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
  text-decoration: none;
  pointer-events: none;
  cursor: auto;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166:not(#\9) {
  background: transparent;
  color: #414d5c;
  color: var(--color-text-interactive-default-eg5fsa, #414d5c);
  border-color: transparent;
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166:not(#\9):hover {
  background: transparent;
  color: #000716;
  color: var(--color-text-interactive-hover-v3lasm, #000716);
  border-color: transparent;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166:not(#\9):active, .awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166.awsui_is-activated_vjswe_qgmad_139:not(#\9) {
  background: transparent;
  color: #414d5c;
  color: var(--color-text-interactive-default-eg5fsa, #414d5c);
  border-color: transparent;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(4px - 1px);
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 4px);
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * 4px);
  top: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  width: calc(100% + 2 * 4px);
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * 4px);
  height: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(4px - 1px);
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 4px);
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * 0px);
  top: calc(-1 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  width: calc(100% + 2 * 4px);
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * 0px);
  height: calc(100% + 2 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(0px - 1px);
  outline-offset: calc(var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 0px);
  left: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  top: calc(-1 * 0px);
  top: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  width: calc(100% + 2 * 0px);
  width: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  height: calc(100% + 2 * 0px);
  height: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166.awsui_disabled_vjswe_qgmad_202:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: #9ba7b6;
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
  text-decoration: none;
  pointer-events: none;
  cursor: auto;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184:not(#\9) {
  background: transparent;
  color: #0972d3;
  color: var(--color-text-button-inline-icon-default-w65lqn, #0972d3);
  border-color: transparent;
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184:not(#\9):hover {
  background: transparent;
  color: #033160;
  color: var(--color-text-button-inline-icon-hover-wd5icp, #033160);
  border-color: transparent;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184:not(#\9):active, .awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184.awsui_is-activated_vjswe_qgmad_139:not(#\9) {
  background: transparent;
  color: #0972d3;
  color: var(--color-text-button-inline-icon-default-w65lqn, #0972d3);
  border-color: transparent;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(4px - 1px);
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 4px);
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * 4px);
  top: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  width: calc(100% + 2 * 4px);
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * 4px);
  height: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(4px - 1px);
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 4px);
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * 0px);
  top: calc(-1 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  width: calc(100% + 2 * 4px);
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * 0px);
  height: calc(100% + 2 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(0px - 1px);
  outline-offset: calc(var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 0px);
  left: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  top: calc(-1 * 0px);
  top: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  width: calc(100% + 2 * 0px);
  width: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  height: calc(100% + 2 * 0px);
  height: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184.awsui_disabled_vjswe_qgmad_202:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: #9ba7b6;
  color: var(--color-text-button-inline-icon-disabled-gfhvz7, #9ba7b6);
  text-decoration: none;
  pointer-events: none;
  cursor: auto;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166:not(#\9) {
  background: transparent;
  color: #414d5c;
  color: var(--color-text-interactive-default-eg5fsa, #414d5c);
  border-color: transparent;
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166:not(#\9):hover {
  background: transparent;
  color: #000716;
  color: var(--color-text-interactive-hover-v3lasm, #000716);
  border-color: transparent;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166:not(#\9):active, .awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166.awsui_is-activated_vjswe_qgmad_139:not(#\9) {
  background: transparent;
  color: #414d5c;
  color: var(--color-text-interactive-default-eg5fsa, #414d5c);
  border-color: transparent;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(4px - 1px);
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 4px);
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * 4px);
  top: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  width: calc(100% + 2 * 4px);
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * 4px);
  height: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(4px - 1px);
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 4px);
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * 0px);
  top: calc(-1 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  width: calc(100% + 2 * 4px);
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * 0px);
  height: calc(100% + 2 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(0px - 1px);
  outline-offset: calc(var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 0px);
  left: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  top: calc(-1 * 0px);
  top: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  width: calc(100% + 2 * 0px);
  width: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  height: calc(100% + 2 * 0px);
  height: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166.awsui_disabled_vjswe_qgmad_202:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: #9ba7b6;
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
  text-decoration: none;
  pointer-events: none;
  cursor: auto;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166:not(#\9) {
  background: transparent;
  color: #d1d5db;
  color: var(--color-text-interactive-inverted-default-phki5u, #d1d5db);
  border-color: transparent;
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166:not(#\9):hover {
  background: transparent;
  color: #fbfbfb;
  color: var(--color-text-interactive-inverted-hover-g2obc6, #fbfbfb);
  border-color: transparent;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166:not(#\9):active, .awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166.awsui_is-activated_vjswe_qgmad_139:not(#\9) {
  background: transparent;
  color: #d1d5db;
  color: var(--color-text-interactive-inverted-default-phki5u, #d1d5db);
  border-color: transparent;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(4px - 1px);
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 4px);
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * 4px);
  top: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  width: calc(100% + 2 * 4px);
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * 4px);
  height: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(4px - 1px);
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 4px);
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * 0px);
  top: calc(-1 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  width: calc(100% + 2 * 4px);
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * 0px);
  height: calc(100% + 2 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(0px - 1px);
  outline-offset: calc(var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 0px);
  left: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  top: calc(-1 * 0px);
  top: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  width: calc(100% + 2 * 0px);
  width: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  height: calc(100% + 2 * 0px);
  height: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166.awsui_disabled_vjswe_qgmad_202:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: #9ba7b6;
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
  text-decoration: none;
  pointer-events: none;
  cursor: auto;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-breadcrumb-group_vjswe_qgmad_714:not(#\9) {
  background: transparent;
  color: #0972d3;
  color: var(--color-text-link-default-5f186r, #0972d3);
  border-color: transparent;
  border-width: 0;
  position: relative;
  text-decoration: none;
  padding: 0;
  font-weight: normal;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-breadcrumb-group_vjswe_qgmad_714:not(#\9):hover {
  background: transparent;
  color: #033160;
  color: var(--color-text-link-hover-kololx, #033160);
  border-color: transparent;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-breadcrumb-group_vjswe_qgmad_714:not(#\9):active, .awsui_button_vjswe_qgmad_101.awsui_variant-breadcrumb-group_vjswe_qgmad_714.awsui_is-activated_vjswe_qgmad_139:not(#\9) {
  background: transparent;
  color: #0972d3;
  color: var(--color-text-link-default-5f186r, #0972d3);
  border-color: transparent;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-breadcrumb-group_vjswe_qgmad_714:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-breadcrumb-group_vjswe_qgmad_714[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-breadcrumb-group_vjswe_qgmad_714[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(4px - 1px);
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-breadcrumb-group_vjswe_qgmad_714[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 4px);
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * 4px);
  top: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  width: calc(100% + 2 * 4px);
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * 4px);
  height: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-breadcrumb-group_vjswe_qgmad_714.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-breadcrumb-group_vjswe_qgmad_714.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-breadcrumb-group_vjswe_qgmad_714.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-breadcrumb-group_vjswe_qgmad_714.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-breadcrumb-group_vjswe_qgmad_714.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-breadcrumb-group_vjswe_qgmad_714.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(4px - 1px);
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-breadcrumb-group_vjswe_qgmad_714.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_qgmad_101.awsui_variant-breadcrumb-group_vjswe_qgmad_714.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_qgmad_101.awsui_variant-breadcrumb-group_vjswe_qgmad_714.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 4px);
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * 0px);
  top: calc(-1 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  width: calc(100% + 2 * 4px);
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * 0px);
  height: calc(100% + 2 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-breadcrumb-group_vjswe_qgmad_714.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-breadcrumb-group_vjswe_qgmad_714.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(0px - 1px);
  outline-offset: calc(var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-breadcrumb-group_vjswe_qgmad_714.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 0px);
  left: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  top: calc(-1 * 0px);
  top: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  width: calc(100% + 2 * 0px);
  width: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  height: calc(100% + 2 * 0px);
  height: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-breadcrumb-group_vjswe_qgmad_714.awsui_disabled_vjswe_qgmad_202:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: #9ba7b6;
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
  text-decoration: none;
  pointer-events: none;
  cursor: auto;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-menu-trigger_vjswe_qgmad_801:not(#\9) {
  background: transparent;
  color: #0972d3;
  color: var(--color-text-button-normal-default-u8647m, #0972d3);
  border-color: transparent;
  position: relative;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-menu-trigger_vjswe_qgmad_801:not(#\9):hover {
  background: transparent;
  color: #033160;
  color: var(--color-text-button-normal-hover-kxmvcc, #033160);
  border-color: transparent;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-menu-trigger_vjswe_qgmad_801:not(#\9):active, .awsui_button_vjswe_qgmad_101.awsui_variant-menu-trigger_vjswe_qgmad_801.awsui_is-activated_vjswe_qgmad_139:not(#\9) {
  background: transparent;
  color: #033160;
  color: var(--color-text-button-normal-active-6obrzh, #033160);
  border-color: transparent;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-menu-trigger_vjswe_qgmad_801:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-menu-trigger_vjswe_qgmad_801[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-menu-trigger_vjswe_qgmad_801[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(4px - 1px);
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-menu-trigger_vjswe_qgmad_801[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 4px);
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * 4px);
  top: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  width: calc(100% + 2 * 4px);
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * 4px);
  height: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-menu-trigger_vjswe_qgmad_801.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-menu-trigger_vjswe_qgmad_801.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-menu-trigger_vjswe_qgmad_801.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-menu-trigger_vjswe_qgmad_801.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-menu-trigger_vjswe_qgmad_801.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus, .awsui_button_vjswe_qgmad_101.awsui_variant-menu-trigger_vjswe_qgmad_801.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(4px - 1px);
  outline-offset: calc(var(--space-button-focus-outline-gutter-4vaedu, 4px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-menu-trigger_vjswe_qgmad_801.awsui_variant-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_qgmad_101.awsui_variant-menu-trigger_vjswe_qgmad_801.awsui_variant-modal-dismiss_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before, .awsui_button_vjswe_qgmad_101.awsui_variant-menu-trigger_vjswe_qgmad_801.awsui_variant-flashbar-icon_vjswe_qgmad_166[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 4px);
  left: calc(-1 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  top: calc(-1 * 0px);
  top: calc(-1 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  width: calc(100% + 2 * 4px);
  width: calc(100% + 2 * var(--space-button-focus-outline-gutter-4vaedu, 4px));
  height: calc(100% + 2 * 0px);
  height: calc(100% + 2 * var(--space-button-icon-focus-outline-gutter-vertical-ysj0na, 0px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-menu-trigger_vjswe_qgmad_801.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-menu-trigger_vjswe_qgmad_801.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(0px - 1px);
  outline-offset: calc(var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px) - 1px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-menu-trigger_vjswe_qgmad_801.awsui_variant-inline-icon_vjswe_qgmad_184[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 0px);
  left: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  top: calc(-1 * 0px);
  top: calc(-1 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  width: calc(100% + 2 * 0px);
  width: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  height: calc(100% + 2 * 0px);
  height: calc(100% + 2 * var(--space-button-inline-icon-focus-outline-gutter-f9qjev, 0px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-menu-trigger_vjswe_qgmad_801.awsui_disabled_vjswe_qgmad_202:not(#\9) {
  background: transparent;
  border-color: transparent;
  color: #9ba7b6;
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
  text-decoration: none;
  pointer-events: none;
  cursor: auto;
}
.awsui_button_vjswe_qgmad_101.awsui_button-no-text_vjswe_qgmad_885:not(#\9) {
  padding-left: 7px;
  padding-left: var(--space-button-icon-only-horizontal-nidslr, 7px);
  padding-right: 7px;
  padding-right: var(--space-button-icon-only-horizontal-nidslr, 7px);
}
.awsui_button_vjswe_qgmad_101.awsui_button-no-wrap_vjswe_qgmad_889:not(#\9) {
  white-space: nowrap;
}
.awsui_button_vjswe_qgmad_101.awsui_variant-icon_vjswe_qgmad_166:not(#\9), .awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184:not(#\9), .awsui_button_vjswe_qgmad_101.awsui_variant-flashbar-icon_vjswe_qgmad_166:not(#\9) {
  padding-left: 4px;
  padding-left: var(--space-xxs-ynfts5, 4px);
  padding-right: 4px;
  padding-right: var(--space-xxs-ynfts5, 4px);
}
.awsui_button_vjswe_qgmad_101.awsui_variant-modal-dismiss_vjswe_qgmad_166:not(#\9) {
  padding: 0px 4px;
  padding: var(--space-button-modal-dismiss-vertical-r4lx2k, 0px) var(--space-xxs-ynfts5, 4px);
  margin-right: calc(-1 * 4px);
  margin-right: calc(-1 * var(--space-xxs-ynfts5, 4px));
}
.awsui_button_vjswe_qgmad_101.awsui_variant-inline-icon_vjswe_qgmad_184:not(#\9) {
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
}
.awsui_button_vjswe_qgmad_101 > .awsui_icon-left_vjswe_qgmad_905:not(#\9) {
  position: relative;
  left: calc(-1 * 4px);
  left: calc(-1 * var(--space-xxs-ynfts5, 4px));
  margin-right: 4px;
  margin-right: var(--space-xxs-ynfts5, 4px);
}
.awsui_button_vjswe_qgmad_101 > .awsui_icon-right_vjswe_qgmad_910:not(#\9) {
  position: relative;
  right: calc(-1 * 4px);
  right: calc(-1 * var(--space-xxs-ynfts5, 4px));
  margin-left: 4px;
  margin-left: var(--space-xxs-ynfts5, 4px);
}
.awsui_button_vjswe_qgmad_101.awsui_button-no-text_vjswe_qgmad_885 > .awsui_icon_vjswe_qgmad_905:not(#\9) {
  margin-right: auto;
  margin-left: auto;
  right: 0;
  left: 0;
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
@-webkit-keyframes awsui_spinner-rotator_1612d_iivw8_1 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes awsui_spinner-rotator_1612d_iivw8_1 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes awsui_spinner-line-left_1612d_iivw8_1 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes awsui_spinner-line-left_1612d_iivw8_1 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@-webkit-keyframes awsui_spinner-line-right_1612d_iivw8_1 {
  0% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}
@keyframes awsui_spinner-line-right_1612d_iivw8_1 {
  0% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}
.awsui_root_1612d_iivw8_127:not(#\9) {
  display: inline-block;
  vertical-align: top;
  /*stylelint-disable-next-line @cloudscape-design/no-motion-outside-of-mixin */
  -webkit-animation: awsui_spinner-rotator_1612d_iivw8_1 0.7s linear infinite;
          animation: awsui_spinner-rotator_1612d_iivw8_1 0.7s linear infinite;
  box-sizing: border-box;
  line-height: 0;
}
.awsui_root_1612d_iivw8_127.awsui_size-normal_1612d_iivw8_135:not(#\9) {
  width: 16px;
  width: var(--size-icon-normal-j1z5fn, 16px);
  height: 16px;
  height: var(--size-icon-normal-j1z5fn, 16px);
  padding: calc((16px - 12px) / 2);
  padding: calc((var(--size-icon-normal-j1z5fn, 16px) - 12px) / 2);
  margin-top: calc((22px - 16px) / 2);
  margin-top: calc((var(--font-body-m-line-height-i7xxvv, 22px) - var(--size-icon-normal-j1z5fn, 16px)) / 2);
  margin-bottom: calc((22px - 16px) / 2);
  margin-bottom: calc((var(--font-body-m-line-height-i7xxvv, 22px) - var(--size-icon-normal-j1z5fn, 16px)) / 2);
  box-sizing: border-box;
}
.awsui_root_1612d_iivw8_127.awsui_size-big_1612d_iivw8_143:not(#\9) {
  width: 32px;
  width: var(--size-icon-big-cu55wt, 32px);
  height: 32px;
  height: var(--size-icon-big-cu55wt, 32px);
  padding: calc((32px - 24px) / 2);
  padding: calc((var(--size-icon-big-cu55wt, 32px) - 24px) / 2);
  margin-top: calc((40px - 32px) / 2);
  margin-top: calc((var(--font-heading-xl-line-height-doxfdj, 40px) - var(--size-icon-big-cu55wt, 32px)) / 2);
  margin-bottom: calc((40px - 32px) / 2);
  margin-bottom: calc((var(--font-heading-xl-line-height-doxfdj, 40px) - var(--size-icon-big-cu55wt, 32px)) / 2);
  box-sizing: border-box;
}
.awsui_root_1612d_iivw8_127.awsui_size-large_1612d_iivw8_151:not(#\9) {
  width: 48px;
  width: var(--size-icon-large-9vh494, 48px);
  height: 48px;
  height: var(--size-icon-large-9vh494, 48px);
  padding: calc((48px - 36px) / 2);
  padding: calc((var(--size-icon-large-9vh494, 48px) - 36px) / 2);
  margin-top: calc((54px - 48px) / 2);
  margin-top: calc((var(--font-display-l-line-height-71tnj3, 54px) - var(--size-icon-large-9vh494, 48px)) / 2);
  margin-bottom: calc((54px - 48px) / 2);
  margin-bottom: calc((var(--font-display-l-line-height-71tnj3, 54px) - var(--size-icon-large-9vh494, 48px)) / 2);
  box-sizing: border-box;
}
.awsui_root_1612d_iivw8_127.awsui_variant-normal_1612d_iivw8_159:not(#\9) {
  color: currentColor;
}
.awsui_root_1612d_iivw8_127.awsui_variant-disabled_1612d_iivw8_162:not(#\9) {
  color: #9ba7b6;
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
}
.awsui_root_1612d_iivw8_127.awsui_variant-inverted_1612d_iivw8_165:not(#\9) {
  color: #ffffff;
  color: var(--color-text-inverted-821sko, #ffffff);
}

.awsui_circle_1612d_iivw8_169:not(#\9) {
  display: inline-block;
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.awsui_circle_1612d_iivw8_169:not(#\9):after {
  position: absolute;
  box-sizing: border-box;
  content: "";
  border-radius: 50%;
  border: 2px solid;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.awsui_circle_1612d_iivw8_169:not(#\9):after {
  /*stylelint-disable-next-line @cloudscape-design/no-motion-outside-of-mixin */
  -webkit-animation: 1.5s ease-in-out infinite;
          animation: 1.5s ease-in-out infinite;
  top: 0;
  left: 0;
  height: 100%;
  width: 200%;
}
.awsui_circle_1612d_iivw8_169.awsui_circle-left_1612d_iivw8_193:not(#\9):after {
  left: 0;
  /*stylelint-disable-next-line @cloudscape-design/no-motion-outside-of-mixin */
  -webkit-animation-name: awsui_spinner-line-left_1612d_iivw8_1;
          animation-name: awsui_spinner-line-left_1612d_iivw8_1;
}
.awsui_circle_1612d_iivw8_169.awsui_circle-right_1612d_iivw8_198:not(#\9):after {
  left: -100%;
  /*stylelint-disable-next-line @cloudscape-design/no-motion-outside-of-mixin */
  -webkit-animation-name: awsui_spinner-line-right_1612d_iivw8_1;
          animation-name: awsui_spinner-line-right_1612d_iivw8_1;
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_xttbq_1i4dh_93:not(#\9) {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_3bgfn_im8v7_5:not(#\9) {
  /* used in test-utils */
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_placeholder_dwuol_zur0f_93:not(#\9) {
  color: #5f6b7a;
  color: var(--color-text-input-placeholder-j53j3c, #5f6b7a);
  font-style: italic;
}

.awsui_item_dwuol_zur0f_98:not(#\9) {
  display: flex;
  align-items: center;
}
.awsui_item_dwuol_zur0f_98 > .awsui_checkbox_dwuol_zur0f_102:not(#\9) {
  position: relative;
  min-height: 16px;
  min-height: var(--size-control-n9i4yo, 16px);
  min-width: 16px;
  min-width: var(--size-control-n9i4yo, 16px);
  height: 16px;
  height: var(--size-control-n9i4yo, 16px);
  width: 16px;
  width: var(--size-control-n9i4yo, 16px);
  margin-right: 12px;
  margin-right: var(--space-field-horizontal-89h2yr, 12px);
}

.awsui_filter_dwuol_zur0f_111:not(#\9) {
  z-index: 4;
  flex-shrink: 0;
}

.awsui_trigger_dwuol_zur0f_116:not(#\9) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.awsui_trigger_dwuol_zur0f_116[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_trigger_dwuol_zur0f_116[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(calc(-1 * 8px) - 1px);
  outline-offset: calc(calc(-1 * var(--space-scaled-xs-6859qs, 8px)) - 1px);
}
.awsui_trigger_dwuol_zur0f_116[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * calc(-1 * 8px));
  left: calc(-1 * calc(-1 * var(--space-scaled-xs-6859qs, 8px)));
  top: calc(-1 * calc(-1 * 4px));
  top: calc(-1 * calc(-1 * var(--space-scaled-xxs-95dhkm, 4px)));
  width: calc(100% + 2 * calc(-1 * 8px));
  width: calc(100% + 2 * calc(-1 * var(--space-scaled-xs-6859qs, 8px)));
  height: calc(100% + 2 * calc(-1 * 4px));
  height: calc(100% + 2 * calc(-1 * var(--space-scaled-xxs-95dhkm, 4px)));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}

.awsui_layout-strut_dwuol_zur0f_140:not(#\9) {
  width: 100%;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.awsui_list-bottom_dwuol_zur0f_146:not(#\9) {
  /* used in unit-tests */
}

.awsui_selected-icon_dwuol_zur0f_150:not(#\9) {
  color: #0972d3;
  color: var(--color-border-item-focused-ap3b6s, #0972d3);
}

.awsui_show-label-tag_dwuol_zur0f_154 > .awsui_selected-icon_dwuol_zur0f_150:not(#\9) {
  padding-left: 12px;
  padding-left: var(--space-scaled-s-cu1hzn, 12px);
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_option_1p2cx_rry8e_97:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: #000716;
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: flex;
  align-items: flex-start;
  width: 100%;
  min-width: 0;
  box-sizing: border-box;
  color: inherit;
}
.awsui_option_1p2cx_rry8e_97:not(#\9):not(.awsui_disabled_1p2cx_rry8e_115) {
  cursor: inherit;
}
.awsui_option_1p2cx_rry8e_97.awsui_parent_1p2cx_rry8e_118:not(#\9) {
  font-weight: bold;
}
.awsui_option_1p2cx_rry8e_97.awsui_parent_1p2cx_rry8e_118:not(#\9):not(.awsui_disabled_1p2cx_rry8e_115) {
  color: #414d5c;
  color: var(--color-text-dropdown-group-label-eaqlcl, #414d5c);
}

.awsui_content_1p2cx_rry8e_125:not(#\9) {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.awsui_label-content_1p2cx_rry8e_132:not(#\9) {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.awsui_label_1p2cx_rry8e_132:not(#\9),
.awsui_tag_1p2cx_rry8e_139:not(#\9),
.awsui_label-tag_1p2cx_rry8e_140:not(#\9) {
  min-width: 0;
  -ms-word-break: break-all;
  word-break: break-word;
}

.awsui_label_1p2cx_rry8e_132:not(#\9),
.awsui_tag_1p2cx_rry8e_139:not(#\9) {
  flex-wrap: wrap;
}

.awsui_label-prefix_1p2cx_rry8e_151:not(#\9) {
  font-weight: 700;
}

.awsui_label-tag_1p2cx_rry8e_140:not(#\9) {
  padding-left: 12px;
  padding-left: var(--space-s-hv8c1d, 12px);
  flex: auto;
  text-align: right;
}
.awsui_label-tag_1p2cx_rry8e_140:not(#\9):empty {
  display: none;
}

.awsui_tags_1p2cx_rry8e_164:not(#\9),
.awsui_description_1p2cx_rry8e_165:not(#\9) {
  font-size: 12px;
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: 16px;
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: 0.005em;
  letter-spacing: var(--font-body-s-letter-spacing-cy0oxj, 0.005em);
  color: #5f6b7a;
  color: var(--color-text-dropdown-item-secondary-0me7js, #5f6b7a);
  flex-wrap: wrap;
}
.awsui_tags_1p2cx_rry8e_164.awsui_highlighted_1p2cx_rry8e_172:not(#\9),
.awsui_description_1p2cx_rry8e_165.awsui_highlighted_1p2cx_rry8e_172:not(#\9) {
  color: #5f6b7a;
  color: var(--color-text-dropdown-item-secondary-hover-7ii1ru, #5f6b7a);
}
.awsui_tags_1p2cx_rry8e_164.awsui_selected_1p2cx_rry8e_176:not(#\9),
.awsui_description_1p2cx_rry8e_165.awsui_selected_1p2cx_rry8e_176:not(#\9) {
  color: #5f6b7a;
  color: var(--color-text-dropdown-item-secondary-0me7js, #5f6b7a);
}

.awsui_tags_1p2cx_rry8e_164:not(#\9) {
  display: flex;
  align-items: stretch;
}

.awsui_tag_1p2cx_rry8e_139:not(#\9):not(:last-child) {
  padding-right: 16px;
  padding-right: var(--space-m-17eucw, 16px);
}

.awsui_icon_1p2cx_rry8e_190:not(#\9) {
  padding-right: 8px;
  padding-right: var(--space-xs-rsr2qu, 8px);
  align-content: center;
  display: flex;
  flex-shrink: 0;
}

.awsui_icon-size-big_1p2cx_rry8e_197:not(#\9) {
  padding-top: 2px;
  padding-top: var(--space-xxxs-k2w98v, 2px);
}

.awsui_filtering-match-highlight_1p2cx_rry8e_201:not(#\9) {
  background-color: #f2f8fd;
  background-color: var(--color-background-dropdown-item-filter-match-6ac2mw, #f2f8fd);
  color: #0972d3;
  color: var(--color-text-dropdown-item-filter-match-x302if, #0972d3);
  font-weight: bold;
}

.awsui_disabled_1p2cx_rry8e_115:not(#\9) {
  color: #9ba7b6;
  color: var(--color-text-dropdown-item-disabled-varol7, #9ba7b6);
  pointer-events: none;
}
.awsui_disabled_1p2cx_rry8e_115 > .awsui_content_1p2cx_rry8e_125 > .awsui_tags_1p2cx_rry8e_164:not(#\9),
.awsui_disabled_1p2cx_rry8e_115 > .awsui_content_1p2cx_rry8e_125 > .awsui_description_1p2cx_rry8e_165:not(#\9) {
  color: currentColor;
}

.awsui_trigger-variant_1p2cx_rry8e_216:not(#\9) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_button-trigger_18eso_9ggzo_97 > .awsui_arrow_18eso_9ggzo_97:not(#\9) {
  transition: -webkit-transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: -webkit-transform var(--motion-duration-rotate-180-ofa1ir, 135ms) var(--motion-easing-rotate-180-3rbbga, cubic-bezier(0.165, 0.84, 0.44, 1));
  transition: transform var(--motion-duration-rotate-180-ofa1ir, 135ms) var(--motion-easing-rotate-180-3rbbga, cubic-bezier(0.165, 0.84, 0.44, 1));
  transition: transform var(--motion-duration-rotate-180-ofa1ir, 135ms) var(--motion-easing-rotate-180-3rbbga, cubic-bezier(0.165, 0.84, 0.44, 1)), -webkit-transform var(--motion-duration-rotate-180-ofa1ir, 135ms) var(--motion-easing-rotate-180-3rbbga, cubic-bezier(0.165, 0.84, 0.44, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_button-trigger_18eso_9ggzo_97 > .awsui_arrow_18eso_9ggzo_97:not(#\9) {
    -webkit-animation: none;
            animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_button-trigger_18eso_9ggzo_97 > .awsui_arrow_18eso_9ggzo_97:not(#\9), .awsui-mode-entering .awsui_button-trigger_18eso_9ggzo_97 > .awsui_arrow_18eso_9ggzo_97:not(#\9) {
  -webkit-animation: none;
          animation: none;
  transition: none;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_button-trigger_18eso_9ggzo_97:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: #000716;
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px;
  padding: var(--space-scaled-xxs-95dhkm, 4px) var(--space-field-horizontal-89h2yr, 12px);
  background-color: #ffffff;
  background-color: var(--color-background-input-default-u57378, #ffffff);
  border-radius: 8px;
  border-radius: var(--border-radius-input-txq8zy, 8px);
  border: 2px solid #7d8998;
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-input-default-e47bkv, #7d8998);
  min-height: 34px;
  min-height: var(--size-vertical-input-js5hfh, 34px);
}
.awsui_button-trigger_18eso_9ggzo_97.awsui_has-caret_18eso_9ggzo_137:not(#\9) {
  padding-right: 36px;
  padding-right: var(--space-field-icon-offset-25xih0, 36px);
}
.awsui_button-trigger_18eso_9ggzo_97 > .awsui_placeholder_18eso_9ggzo_140:not(#\9) {
  color: #5f6b7a;
  color: var(--color-text-input-placeholder-j53j3c, #5f6b7a);
  font-style: italic;
}
.awsui_button-trigger_18eso_9ggzo_97 > .awsui_arrow_18eso_9ggzo_97:not(#\9) {
  position: absolute;
  right: 12px;
  right: var(--space-field-horizontal-89h2yr, 12px);
  top: calc(50% - 22px / 2);
  top: calc(50% - var(--font-body-m-line-height-i7xxvv, 22px) / 2);
  color: #0972d3;
  color: var(--color-text-button-inline-icon-default-w65lqn, #0972d3);
}
.awsui_button-trigger_18eso_9ggzo_97:not(#\9):hover > .awsui_arrow_18eso_9ggzo_97 {
  color: #033160;
  color: var(--color-text-button-inline-icon-hover-wd5icp, #033160);
}
.awsui_button-trigger_18eso_9ggzo_97.awsui_pressed_18eso_9ggzo_153 > .awsui_arrow_18eso_9ggzo_97:not(#\9) {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.awsui_button-trigger_18eso_9ggzo_97.awsui_disabled_18eso_9ggzo_156:not(#\9) {
  background-color: #e9ebed;
  background-color: var(--color-background-input-disabled-wu9j6u, #e9ebed);
  border: 2px solid #e9ebed;
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-input-disabled-x2lopj, #e9ebed);
  color: #9ba7b6;
  color: var(--color-text-input-disabled-oawyzo, #9ba7b6);
  cursor: auto;
}
.awsui_button-trigger_18eso_9ggzo_97.awsui_disabled_18eso_9ggzo_156 > .awsui_arrow_18eso_9ggzo_97:not(#\9) {
  color: #9ba7b6;
  color: var(--color-text-button-inline-icon-disabled-gfhvz7, #9ba7b6);
}
.awsui_button-trigger_18eso_9ggzo_97.awsui_disabled_18eso_9ggzo_156.awsui_in-filtering-token_18eso_9ggzo_165:not(#\9) {
  border-color: #d1d5db;
  border-color: var(--color-border-control-disabled-vx8bco, #d1d5db);
}
.awsui_button-trigger_18eso_9ggzo_97.awsui_disabled_18eso_9ggzo_156 > .awsui_placeholder_18eso_9ggzo_140:not(#\9) {
  color: #9ba7b6;
  color: var(--color-text-input-placeholder-disabled-6928j8, #9ba7b6);
}
.awsui_button-trigger_18eso_9ggzo_97.awsui_read-only_18eso_9ggzo_171:not(#\9) {
  background-color: #ffffff;
  background-color: var(--color-background-input-default-u57378, #ffffff);
  border: 2px solid #e9ebed;
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-input-disabled-x2lopj, #e9ebed);
}
.awsui_button-trigger_18eso_9ggzo_97:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_button-trigger_18eso_9ggzo_97:not(#\9):not(.awsui_in-filtering-token_18eso_9ggzo_165)[data-awsui-focus-visible=true]:focus {
  outline: 2px dotted transparent;
  border: 2px solid #0972d3;
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-item-focused-ap3b6s, #0972d3);
  border-radius: 8px;
  border-radius: var(--border-radius-input-txq8zy, 8px);
  box-shadow: 0 0 0 0px #0972d3;
  box-shadow: 0 0 0 var(--border-control-focus-ring-shadow-spread-srzyiu, 0px) var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button-trigger_18eso_9ggzo_97:not(#\9):not(.awsui_in-filtering-token_18eso_9ggzo_165):invalid {
  box-shadow: none;
}
.awsui_button-trigger_18eso_9ggzo_97:not(#\9):not(.awsui_in-filtering-token_18eso_9ggzo_165).awsui_invalid_18eso_9ggzo_188, .awsui_button-trigger_18eso_9ggzo_97:not(#\9):not(.awsui_in-filtering-token_18eso_9ggzo_165).awsui_invalid_18eso_9ggzo_188:focus {
  color: #d91515;
  color: var(--color-text-status-error-5sesl6, #d91515);
  border-color: #d91515;
  border-color: var(--color-text-status-error-5sesl6, #d91515);
  padding-left: calc(
  12px - (8px - 2px)
);
  padding-left: calc(
  var(--space-field-horizontal-89h2yr, 12px) - (var(--border-invalid-width-5jpdqn, 8px) - var(--border-field-width-idlekx, 2px))
);
  border-left-width: 8px;
  border-left-width: var(--border-invalid-width-5jpdqn, 8px);
}
.awsui_button-trigger_18eso_9ggzo_97:not(#\9):not(.awsui_in-filtering-token_18eso_9ggzo_165).awsui_invalid_18eso_9ggzo_188:focus, .awsui_button-trigger_18eso_9ggzo_97:not(#\9):not(.awsui_in-filtering-token_18eso_9ggzo_165).awsui_invalid_18eso_9ggzo_188:focus:focus {
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 var(--border-control-invalid-focus-ring-shadow-spread-omt6il, 2px) var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_button-trigger_18eso_9ggzo_97.awsui_in-filtering-token_18eso_9ggzo_165:not(#\9) {
  border-color: #0972d3;
  border-color: var(--color-border-item-selected-ppkssz, #0972d3);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 100%;
}
.awsui_button-trigger_18eso_9ggzo_97.awsui_in-filtering-token_18eso_9ggzo_165[data-awsui-focus-visible=true]:not(#\9):focus {
  position: relative;
}
.awsui_button-trigger_18eso_9ggzo_97.awsui_in-filtering-token_18eso_9ggzo_165[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(-5px - 1px);
  outline-offset: calc(var(--space-filtering-token-operation-select-focus-outline-gutter-awvnzl, -5px) - 1px);
}
.awsui_button-trigger_18eso_9ggzo_97.awsui_in-filtering-token_18eso_9ggzo_165[data-awsui-focus-visible=true]:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * -5px);
  left: calc(-1 * var(--space-filtering-token-operation-select-focus-outline-gutter-awvnzl, -5px));
  top: calc(-1 * -5px);
  top: calc(-1 * var(--space-filtering-token-operation-select-focus-outline-gutter-awvnzl, -5px));
  width: calc(100% + 2 * -5px);
  width: calc(100% + 2 * var(--space-filtering-token-operation-select-focus-outline-gutter-awvnzl, -5px));
  height: calc(100% + 2 * -5px);
  height: calc(100% + 2 * var(--space-filtering-token-operation-select-focus-outline-gutter-awvnzl, -5px));
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_vrgzu_1v4yu_93:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: #000716;
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  padding: 4px 12px calc(4px + 2px);
  padding: var(--space-xxs-ynfts5, 4px) var(--space-field-horizontal-89h2yr, 12px) calc(var(--space-xxs-ynfts5, 4px) + var(--space-xxxs-k2w98v, 2px));
  color: #5f6b7a;
  color: var(--color-text-dropdown-footer-rj6jvf, #5f6b7a);
}
.awsui_root_vrgzu_1v4yu_93 > .awsui_recovery_vrgzu_1v4yu_107:not(#\9) {
  /* used in test-utils */
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_icon-shake_1cbgc_2rq10_97:not(#\9) {
  display: inline-block;
  -webkit-animation: awsui_awsui-motion-shake-horizontally_1cbgc_2rq10_1 165ms;
          animation: awsui_awsui-motion-shake-horizontally_1cbgc_2rq10_1 165ms;
  -webkit-animation: awsui_awsui-motion-shake-horizontally_1cbgc_2rq10_1 var(--motion-duration-refresh-only-medium-5flen2, 165ms);
          animation: awsui_awsui-motion-shake-horizontally_1cbgc_2rq10_1 var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@-webkit-keyframes awsui_awsui-motion-shake-horizontally_1cbgc_2rq10_1 {
  0% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  50% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
            animation-timing-function: cubic-bezier(0, 0, 0, 1);
    -webkit-animation-timing-function: var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
            animation-timing-function: var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}
@keyframes awsui_awsui-motion-shake-horizontally_1cbgc_2rq10_1 {
  0% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  50% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
            animation-timing-function: cubic-bezier(0, 0, 0, 1);
    -webkit-animation-timing-function: var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
            animation-timing-function: var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_icon-shake_1cbgc_2rq10_97:not(#\9) {
    -webkit-animation: none;
            animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_icon-shake_1cbgc_2rq10_97:not(#\9), .awsui-mode-entering .awsui_icon-shake_1cbgc_2rq10_97:not(#\9) {
  -webkit-animation: none;
          animation: none;
  transition: none;
}

.awsui_container-fade-in_1cbgc_2rq10_125:not(#\9) {
  -webkit-animation: awsui_awsui-motion-fade-in-0_1cbgc_2rq10_1 165ms cubic-bezier(0, 0, 0, 1);
          animation: awsui_awsui-motion-fade-in-0_1cbgc_2rq10_1 165ms cubic-bezier(0, 0, 0, 1);
  -webkit-animation: awsui_awsui-motion-fade-in-0_1cbgc_2rq10_1 var(--motion-duration-refresh-only-medium-5flen2, 165ms) var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
          animation: awsui_awsui-motion-fade-in-0_1cbgc_2rq10_1 var(--motion-duration-refresh-only-medium-5flen2, 165ms) var(--motion-easing-refresh-only-a-sy0g8m, cubic-bezier(0, 0, 0, 1));
}
@-webkit-keyframes awsui_awsui-motion-fade-in-0_1cbgc_2rq10_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes awsui_awsui-motion-fade-in-0_1cbgc_2rq10_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_container-fade-in_1cbgc_2rq10_125:not(#\9) {
    -webkit-animation: none;
            animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_container-fade-in_1cbgc_2rq10_125:not(#\9), .awsui-mode-entering .awsui_container-fade-in_1cbgc_2rq10_125:not(#\9) {
  -webkit-animation: none;
          animation: none;
  transition: none;
}

.awsui_root_1cbgc_2rq10_147:not(#\9) {
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: #000716;
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}
.awsui_root_1cbgc_2rq10_147.awsui_status-error_1cbgc_2rq10_156:not(#\9) {
  color: #d91515;
  color: var(--color-text-status-error-5sesl6, #d91515);
}
.awsui_root_1cbgc_2rq10_147.awsui_status-warning_1cbgc_2rq10_159:not(#\9) {
  color: #d91515;
  color: var(--color-text-status-error-5sesl6, #d91515);
}
.awsui_root_1cbgc_2rq10_147.awsui_status-success_1cbgc_2rq10_162:not(#\9) {
  color: #037f0c;
  color: var(--color-text-status-success-0fzhv9, #037f0c);
}
.awsui_root_1cbgc_2rq10_147.awsui_status-info_1cbgc_2rq10_165:not(#\9) {
  color: #0972d3;
  color: var(--color-text-status-info-utupcz, #0972d3);
}
.awsui_root_1cbgc_2rq10_147.awsui_status-stopped_1cbgc_2rq10_168:not(#\9) {
  color: #5f6b7a;
  color: var(--color-text-status-inactive-5ei55p, #5f6b7a);
}
.awsui_root_1cbgc_2rq10_147.awsui_status-pending_1cbgc_2rq10_171:not(#\9) {
  color: #5f6b7a;
  color: var(--color-text-status-inactive-5ei55p, #5f6b7a);
}
.awsui_root_1cbgc_2rq10_147.awsui_status-in-progress_1cbgc_2rq10_174:not(#\9) {
  color: #5f6b7a;
  color: var(--color-text-status-inactive-5ei55p, #5f6b7a);
}
.awsui_root_1cbgc_2rq10_147.awsui_status-loading_1cbgc_2rq10_177:not(#\9) {
  color: #5f6b7a;
  color: var(--color-text-status-inactive-5ei55p, #5f6b7a);
}
.awsui_root_1cbgc_2rq10_147.awsui_color-override-red_1cbgc_2rq10_180:not(#\9) {
  color: #d91515;
  color: var(--color-text-status-error-5sesl6, #d91515);
}
.awsui_root_1cbgc_2rq10_147.awsui_color-override-grey_1cbgc_2rq10_183:not(#\9) {
  color: #5f6b7a;
  color: var(--color-text-status-inactive-5ei55p, #5f6b7a);
}
.awsui_root_1cbgc_2rq10_147.awsui_color-override-blue_1cbgc_2rq10_186:not(#\9) {
  color: #0972d3;
  color: var(--color-text-status-info-utupcz, #0972d3);
}
.awsui_root_1cbgc_2rq10_147.awsui_color-override-green_1cbgc_2rq10_189:not(#\9) {
  color: #037f0c;
  color: var(--color-text-status-success-0fzhv9, #037f0c);
}

.awsui_icon_1cbgc_2rq10_97:not(#\9) {
  padding-right: 4px;
  padding-right: var(--space-xxs-ynfts5, 4px);
}

.awsui_container_1cbgc_2rq10_125:not(#\9) {
  display: inline-block;
  word-break: break-all;
  word-wrap: break-word;
}

.awsui_overflow-ellipsis_1cbgc_2rq10_203:not(#\9) {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: text-bottom;
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_link_4c84z_tyyos_93:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: #000716;
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: inline;
  white-space: inherit;
  color: #0972d3;
  color: var(--color-text-link-default-5f186r, #0972d3);
  font-weight: inherit;
  letter-spacing: normal;
  text-decoration: none;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: 165ms;
  transition-duration: var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_tyyos_93:not(#\9) {
    -webkit-animation: none;
            animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_tyyos_93:not(#\9), .awsui-mode-entering .awsui_link_4c84z_tyyos_93:not(#\9) {
  -webkit-animation: none;
          animation: none;
  transition: none;
}
.awsui_link_4c84z_tyyos_93:not(#\9):hover {
  cursor: pointer;
  color: #033160;
  color: var(--color-text-link-hover-kololx, #033160);
}
.awsui_link_4c84z_tyyos_93:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_tyyos_93:not(#\9):active {
  color: #033160;
  color: var(--color-text-link-hover-kololx, #033160);
}
.awsui_link_4c84z_tyyos_93:not(#\9):active, .awsui_link_4c84z_tyyos_93:not(#\9):focus, .awsui_link_4c84z_tyyos_93:not(#\9):hover {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-decoration-color: currentColor;
          text-decoration-color: currentColor;
}
.awsui_link_4c84z_tyyos_93.awsui_variant-secondary_4c84z_tyyos_140:not(#\9) {
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  color: #0972d3;
  color: var(--color-text-link-default-5f186r, #0972d3);
  font-weight: inherit;
  letter-spacing: normal;
  text-decoration: none;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: 165ms;
  transition-duration: var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_tyyos_93.awsui_variant-secondary_4c84z_tyyos_140:not(#\9) {
    -webkit-animation: none;
            animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_tyyos_93.awsui_variant-secondary_4c84z_tyyos_140:not(#\9), .awsui-mode-entering .awsui_link_4c84z_tyyos_93.awsui_variant-secondary_4c84z_tyyos_140:not(#\9) {
  -webkit-animation: none;
          animation: none;
  transition: none;
}
.awsui_link_4c84z_tyyos_93.awsui_variant-secondary_4c84z_tyyos_140:not(#\9):hover {
  cursor: pointer;
  color: #033160;
  color: var(--color-text-link-hover-kololx, #033160);
}
.awsui_link_4c84z_tyyos_93.awsui_variant-secondary_4c84z_tyyos_140:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_tyyos_93.awsui_variant-secondary_4c84z_tyyos_140:not(#\9):active {
  color: #033160;
  color: var(--color-text-link-hover-kololx, #033160);
}
.awsui_link_4c84z_tyyos_93.awsui_variant-secondary_4c84z_tyyos_140:not(#\9):active, .awsui_link_4c84z_tyyos_93.awsui_variant-secondary_4c84z_tyyos_140:not(#\9):focus, .awsui_link_4c84z_tyyos_93.awsui_variant-secondary_4c84z_tyyos_140:not(#\9):hover {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-decoration-color: currentColor;
          text-decoration-color: currentColor;
}
.awsui_link_4c84z_tyyos_93.awsui_variant-primary_4c84z_tyyos_177:not(#\9) {
  color: #0972d3;
  color: var(--color-text-link-default-5f186r, #0972d3);
  font-weight: "inherit";
  font-weight: var(--font-link-primary-weight-91pybi, "inherit");
  letter-spacing: "inherit";
  letter-spacing: var(--font-link-primary-letter-spacing-vmsgcx, "inherit");
  -webkit-text-decoration: underline;
  -webkit-text-decoration: var(--font-link-primary-decoration-wfgjh2, underline);
          text-decoration: underline;
          text-decoration: var(--font-link-primary-decoration-wfgjh2, underline);
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-decoration-color: #0972d3;
          text-decoration-color: #0972d3;
  -webkit-text-decoration-color: var(--color-text-link-primary-underline-ga7rq8, #0972d3);
          text-decoration-color: var(--color-text-link-primary-underline-ga7rq8, #0972d3);
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: 165ms;
  transition-duration: var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_tyyos_93.awsui_variant-primary_4c84z_tyyos_177:not(#\9) {
    -webkit-animation: none;
            animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_tyyos_93.awsui_variant-primary_4c84z_tyyos_177:not(#\9), .awsui-mode-entering .awsui_link_4c84z_tyyos_93.awsui_variant-primary_4c84z_tyyos_177:not(#\9) {
  -webkit-animation: none;
          animation: none;
  transition: none;
}
.awsui_link_4c84z_tyyos_93.awsui_variant-primary_4c84z_tyyos_177:not(#\9):hover {
  cursor: pointer;
  color: #033160;
  color: var(--color-text-link-hover-kololx, #033160);
}
.awsui_link_4c84z_tyyos_93.awsui_variant-primary_4c84z_tyyos_177:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_tyyos_93.awsui_variant-primary_4c84z_tyyos_177:not(#\9):active {
  color: #033160;
  color: var(--color-text-link-hover-kololx, #033160);
}
.awsui_link_4c84z_tyyos_93.awsui_variant-primary_4c84z_tyyos_177:not(#\9):active, .awsui_link_4c84z_tyyos_93.awsui_variant-primary_4c84z_tyyos_177:not(#\9):focus, .awsui_link_4c84z_tyyos_93.awsui_variant-primary_4c84z_tyyos_177:not(#\9):hover {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-decoration-color: currentColor;
          text-decoration-color: currentColor;
}
.awsui_link_4c84z_tyyos_93.awsui_variant-info_4c84z_tyyos_212:not(#\9) {
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
  color: #0972d3;
  color: var(--color-text-link-default-5f186r, #0972d3);
  font-weight: 800;
  font-weight: var(--font-button-weight-7rj5fx, 800);
  letter-spacing: 0.005em;
  text-decoration: none;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: 165ms;
  transition-duration: var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_tyyos_93.awsui_variant-info_4c84z_tyyos_212:not(#\9) {
    -webkit-animation: none;
            animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_tyyos_93.awsui_variant-info_4c84z_tyyos_212:not(#\9), .awsui-mode-entering .awsui_link_4c84z_tyyos_93.awsui_variant-info_4c84z_tyyos_212:not(#\9) {
  -webkit-animation: none;
          animation: none;
  transition: none;
}
.awsui_link_4c84z_tyyos_93.awsui_variant-info_4c84z_tyyos_212:not(#\9):hover {
  cursor: pointer;
  color: #033160;
  color: var(--color-text-link-hover-kololx, #033160);
}
.awsui_link_4c84z_tyyos_93.awsui_variant-info_4c84z_tyyos_212:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_tyyos_93.awsui_variant-info_4c84z_tyyos_212:not(#\9):active {
  color: #033160;
  color: var(--color-text-link-hover-kololx, #033160);
}
.awsui_link_4c84z_tyyos_93.awsui_variant-info_4c84z_tyyos_212:not(#\9):active, .awsui_link_4c84z_tyyos_93.awsui_variant-info_4c84z_tyyos_212:not(#\9):focus, .awsui_link_4c84z_tyyos_93.awsui_variant-info_4c84z_tyyos_212:not(#\9):hover {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
  -webkit-text-decoration-color: var(--color-text-link-button-underline-hover-ei09le, transparent);
          text-decoration-color: var(--color-text-link-button-underline-hover-ei09le, transparent);
}
.awsui_link_4c84z_tyyos_93.awsui_variant-value-large_4c84z_tyyos_249:not(#\9) {
  color: #0972d3;
  color: var(--color-text-link-default-5f186r, #0972d3);
  font-weight: 800;
  font-weight: var(--font-box-value-large-weight-dyt1pj, 800);
  text-decoration: none;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: 165ms;
  transition-duration: var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_tyyos_93.awsui_variant-value-large_4c84z_tyyos_249:not(#\9) {
    -webkit-animation: none;
            animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_tyyos_93.awsui_variant-value-large_4c84z_tyyos_249:not(#\9), .awsui-mode-entering .awsui_link_4c84z_tyyos_93.awsui_variant-value-large_4c84z_tyyos_249:not(#\9) {
  -webkit-animation: none;
          animation: none;
  transition: none;
}
.awsui_link_4c84z_tyyos_93.awsui_variant-value-large_4c84z_tyyos_249:not(#\9):hover {
  cursor: pointer;
  color: #033160;
  color: var(--color-text-link-hover-kololx, #033160);
}
.awsui_link_4c84z_tyyos_93.awsui_variant-value-large_4c84z_tyyos_249:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_tyyos_93.awsui_variant-value-large_4c84z_tyyos_249:not(#\9):active {
  color: #033160;
  color: var(--color-text-link-hover-kololx, #033160);
}
.awsui_link_4c84z_tyyos_93.awsui_variant-value-large_4c84z_tyyos_249:not(#\9):active, .awsui_link_4c84z_tyyos_93.awsui_variant-value-large_4c84z_tyyos_249:not(#\9):focus, .awsui_link_4c84z_tyyos_93.awsui_variant-value-large_4c84z_tyyos_249:not(#\9):hover {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-decoration-color: currentColor;
          text-decoration-color: currentColor;
}
.awsui_link_4c84z_tyyos_93.awsui_variant-top-navigation_4c84z_tyyos_283:not(#\9) {
  color: #414d5c;
  color: var(--color-text-interactive-default-eg5fsa, #414d5c);
  font-weight: 700;
  letter-spacing: 0.005em;
  text-decoration: none;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: 165ms;
  transition-duration: var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_tyyos_93.awsui_variant-top-navigation_4c84z_tyyos_283:not(#\9) {
    -webkit-animation: none;
            animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_tyyos_93.awsui_variant-top-navigation_4c84z_tyyos_283:not(#\9), .awsui-mode-entering .awsui_link_4c84z_tyyos_93.awsui_variant-top-navigation_4c84z_tyyos_283:not(#\9) {
  -webkit-animation: none;
          animation: none;
  transition: none;
}
.awsui_link_4c84z_tyyos_93.awsui_variant-top-navigation_4c84z_tyyos_283:not(#\9):hover {
  cursor: pointer;
  color: #000716;
  color: var(--color-text-interactive-hover-v3lasm, #000716);
}
.awsui_link_4c84z_tyyos_93.awsui_variant-top-navigation_4c84z_tyyos_283:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_tyyos_93.awsui_variant-top-navigation_4c84z_tyyos_283:not(#\9):active {
  color: #000716;
  color: var(--color-text-interactive-active-vol84d, #000716);
}
.awsui_link_4c84z_tyyos_93.awsui_variant-top-navigation_4c84z_tyyos_283:not(#\9):active, .awsui_link_4c84z_tyyos_93.awsui_variant-top-navigation_4c84z_tyyos_283:not(#\9):focus, .awsui_link_4c84z_tyyos_93.awsui_variant-top-navigation_4c84z_tyyos_283:not(#\9):hover {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
}
.awsui_link_4c84z_tyyos_93.awsui_variant-recovery_4c84z_tyyos_318:not(#\9) {
  color: #0972d3;
  color: var(--color-text-link-default-5f186r, #0972d3);
  font-weight: 800;
  font-weight: var(--font-link-button-weight-4f1hjr, 800);
  letter-spacing: 0.005em;
  letter-spacing: var(--font-link-button-letter-spacing-58wset, 0.005em);
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
  -webkit-text-decoration-color: var(--color-text-link-button-underline-8mp92t, transparent);
          text-decoration-color: var(--color-text-link-button-underline-8mp92t, transparent);
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: 165ms;
  transition-duration: var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_tyyos_93.awsui_variant-recovery_4c84z_tyyos_318:not(#\9) {
    -webkit-animation: none;
            animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_tyyos_93.awsui_variant-recovery_4c84z_tyyos_318:not(#\9), .awsui-mode-entering .awsui_link_4c84z_tyyos_93.awsui_variant-recovery_4c84z_tyyos_318:not(#\9) {
  -webkit-animation: none;
          animation: none;
  transition: none;
}
.awsui_link_4c84z_tyyos_93.awsui_variant-recovery_4c84z_tyyos_318:not(#\9):hover {
  cursor: pointer;
  color: #033160;
  color: var(--color-text-link-hover-kololx, #033160);
}
.awsui_link_4c84z_tyyos_93.awsui_variant-recovery_4c84z_tyyos_318:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_tyyos_93.awsui_variant-recovery_4c84z_tyyos_318:not(#\9):active {
  color: #033160;
  color: var(--color-text-link-hover-kololx, #033160);
}
.awsui_link_4c84z_tyyos_93.awsui_variant-recovery_4c84z_tyyos_318:not(#\9):active, .awsui_link_4c84z_tyyos_93.awsui_variant-recovery_4c84z_tyyos_318:not(#\9):focus, .awsui_link_4c84z_tyyos_93.awsui_variant-recovery_4c84z_tyyos_318:not(#\9):hover {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
  -webkit-text-decoration-color: var(--color-text-link-button-underline-hover-ei09le, transparent);
          text-decoration-color: var(--color-text-link-button-underline-hover-ei09le, transparent);
}
.awsui_link_4c84z_tyyos_93.awsui_button_4c84z_tyyos_353:not(#\9) {
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: var(--font-smoothing-webkit-vu3hx4, antialiased);
  -moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-nkh1jb, grayscale);
  color: #0972d3;
  color: var(--color-text-link-button-normal-default-2yrp9u, #0972d3);
  font-weight: 800;
  font-weight: var(--font-button-weight-7rj5fx, 800);
  letter-spacing: 0.005em;
  letter-spacing: var(--font-button-letter-spacing-8y5poz, 0.005em);
  text-decoration: none;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: 165ms;
  transition-duration: var(--motion-duration-refresh-only-medium-5flen2, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_link_4c84z_tyyos_93.awsui_button_4c84z_tyyos_353:not(#\9) {
    -webkit-animation: none;
            animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_link_4c84z_tyyos_93.awsui_button_4c84z_tyyos_353:not(#\9), .awsui-mode-entering .awsui_link_4c84z_tyyos_93.awsui_button_4c84z_tyyos_353:not(#\9) {
  -webkit-animation: none;
          animation: none;
  transition: none;
}
.awsui_link_4c84z_tyyos_93.awsui_button_4c84z_tyyos_353:not(#\9):hover {
  cursor: pointer;
  color: #033160;
  color: var(--color-text-link-button-normal-hover-gz8y5b, #033160);
}
.awsui_link_4c84z_tyyos_93.awsui_button_4c84z_tyyos_353:not(#\9):focus {
  outline: none;
}
.awsui_link_4c84z_tyyos_93.awsui_button_4c84z_tyyos_353:not(#\9):active {
  color: #033160;
  color: var(--color-text-link-button-normal-active-8lhs8f, #033160);
}
.awsui_link_4c84z_tyyos_93.awsui_button_4c84z_tyyos_353:not(#\9):active, .awsui_link_4c84z_tyyos_93.awsui_button_4c84z_tyyos_353:not(#\9):focus, .awsui_link_4c84z_tyyos_93.awsui_button_4c84z_tyyos_353:not(#\9):hover {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
}
.awsui_link_4c84z_tyyos_93.awsui_color-inverted_4c84z_tyyos_390:not(#\9) {
  color: #fbfbfb;
  color: var(--color-text-notification-default-2kpcxy, #fbfbfb);
}
.awsui_link_4c84z_tyyos_93.awsui_color-inverted_4c84z_tyyos_390:not(#\9):not(.awsui_button_4c84z_tyyos_353) {
  text-decoration: underline;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  -webkit-text-decoration-color: currentColor;
          text-decoration-color: currentColor;
}
.awsui_link_4c84z_tyyos_93.awsui_color-inverted_4c84z_tyyos_390:not(#\9):hover {
  color: #ffffff;
  color: var(--color-text-link-inverted-hover-5tnsdi, #ffffff);
}
.awsui_link_4c84z_tyyos_93[data-awsui-focus-visible=true]:not(#\9):focus {
  outline: thin dotted;
  outline: 0;
  outline: var(--border-link-focus-ring-outline-k2wccv, 0);
  outline-offset: 2px;
  outline-color: #0972d3;
  outline-color: var(--color-border-item-focused-ap3b6s, #0972d3);
  border-radius: 4px;
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px #0972d3;
  box-shadow: 0 0 0 var(--border-link-focus-ring-shadow-spread-nv4ahb, 2px) var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_link_4c84z_tyyos_93.awsui_font-size-body-s_4c84z_tyyos_409:not(#\9) {
  font-size: 12px;
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: 16px;
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: 0.005em;
  letter-spacing: var(--font-body-s-letter-spacing-cy0oxj, 0.005em);
}
.awsui_link_4c84z_tyyos_93.awsui_font-size-body-m_4c84z_tyyos_414:not(#\9) {
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
}
.awsui_link_4c84z_tyyos_93.awsui_font-size-heading-xs_4c84z_tyyos_418:not(#\9) {
  font-size: 14px;
  font-size: var(--font-heading-xs-size-fryxro, 14px);
  line-height: 18px;
  line-height: var(--font-heading-xs-line-height-5x131j, 18px);
}
.awsui_link_4c84z_tyyos_93.awsui_font-size-heading-s_4c84z_tyyos_422:not(#\9) {
  font-size: 16px;
  font-size: var(--font-heading-s-size-9clg8y, 16px);
  line-height: 20px;
  line-height: var(--font-heading-s-line-height-5b7f0q, 20px);
  letter-spacing: -0.005em;
  letter-spacing: var(--font-heading-s-letter-spacing-4d69jc, -0.005em);
}
.awsui_link_4c84z_tyyos_93.awsui_font-size-heading-m_4c84z_tyyos_427:not(#\9) {
  font-size: 20px;
  font-size: var(--font-heading-m-size-6e3jmg, 20px);
  line-height: 24px;
  line-height: var(--font-heading-m-line-height-u3t54f, 24px);
  letter-spacing: -0.01em;
  letter-spacing: var(--font-heading-m-letter-spacing-r00pg3, -0.01em);
}
.awsui_link_4c84z_tyyos_93.awsui_font-size-heading-l_4c84z_tyyos_432:not(#\9) {
  font-size: 24px;
  font-size: var(--font-heading-l-size-otmxys, 24px);
  line-height: 30px;
  line-height: var(--font-heading-l-line-height-gev4n1, 30px);
  letter-spacing: -0.015em;
  letter-spacing: var(--font-heading-l-letter-spacing-f6wvtk, -0.015em);
}
.awsui_link_4c84z_tyyos_93.awsui_font-size-heading-xl_4c84z_tyyos_437:not(#\9) {
  font-size: 32px;
  font-size: var(--font-heading-xl-size-d0vjbk, 32px);
  line-height: 40px;
  line-height: var(--font-heading-xl-line-height-doxfdj, 40px);
  letter-spacing: -0.02em;
  letter-spacing: var(--font-heading-xl-letter-spacing-9tcsmy, -0.02em);
}
.awsui_link_4c84z_tyyos_93.awsui_font-size-display-l_4c84z_tyyos_442:not(#\9) {
  font-size: 48px;
  font-size: var(--font-display-l-size-03lgcb, 48px);
  line-height: 54px;
  line-height: var(--font-display-l-line-height-71tnj3, 54px);
  letter-spacing: -0.03em;
  letter-spacing: var(--font-display-l-letter-spacing-4ff418, -0.03em);
}
.awsui_link_4c84z_tyyos_93.awsui_font-size-inherit_4c84z_tyyos_447:not(#\9) {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

.awsui_icon-wrapper_4c84z_tyyos_453:not(#\9) {
  white-space: nowrap;
}

.awsui_icon_4c84z_tyyos_453:not(#\9) {
  display: inline-block;
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_selectable-item_15o6u_9fxjf_93:not(#\9) {
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: #000716;
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  position: relative;
  list-style: none;
  z-index: 1;
  border: 1px solid transparent;
  border: var(--border-divider-list-width-hacikr, 1px) solid transparent;
  border-top-color: #e9ebed;
  border-top-color: var(--color-border-dropdown-item-default-faosd8, #e9ebed);
  border-bottom-color: #e9ebed;
  border-bottom-color: var(--color-border-dropdown-item-default-faosd8, #e9ebed);
  background-color: #ffffff;
  background-color: var(--color-background-dropdown-item-default-fzdqsg, #ffffff);
  color: #000716;
  color: var(--color-text-dropdown-item-default-7tg9p2, #000716);
  border-left-width: 0;
  border-right-width: 0;
  padding: calc(
  4px + (2px - 1px)
) calc(12px + 2px);
  padding: calc(
  var(--space-xxs-ynfts5, 4px) + (var(--border-item-width-qbbbsa, 2px) - var(--border-divider-list-width-hacikr, 1px))
) calc(var(--space-field-horizontal-89h2yr, 12px) + var(--border-item-width-qbbbsa, 2px));
}
.awsui_selectable-item_15o6u_9fxjf_93.awsui_pad-bottom_15o6u_9fxjf_115:not(#\9) {
  padding-bottom: calc(calc(
  4px + (2px - 1px)
) + 2px);
  padding-bottom: calc(calc(
  var(--space-xxs-ynfts5, 4px) + (var(--border-item-width-qbbbsa, 2px) - var(--border-divider-list-width-hacikr, 1px))
) + var(--space-xxxs-k2w98v, 2px));
  border-bottom-color: transparent;
}
.awsui_selectable-item_15o6u_9fxjf_93:not(#\9):not(:first-child) {
  margin-top: calc(-1 * 2px);
  margin-top: calc(-1 * var(--border-item-width-qbbbsa, 2px));
}
.awsui_selectable-item_15o6u_9fxjf_93.awsui_has-background_15o6u_9fxjf_124:not(#\9) {
  background-color: #f4f4f4;
  background-color: var(--color-background-dropdown-item-hover-mqsxkv, #f4f4f4);
}
.awsui_selectable-item_15o6u_9fxjf_93.awsui_highlighted_15o6u_9fxjf_127:not(#\9), .awsui_selectable-item_15o6u_9fxjf_93.awsui_selected_15o6u_9fxjf_127:not(#\9) {
  color: #000716;
  color: var(--color-text-dropdown-item-highlighted-oaabyk, #000716);
  border-width: 2px;
  border-width: var(--border-item-width-qbbbsa, 2px);
  border-radius: 8px;
  border-radius: var(--border-radius-item-u2ibpi, 8px);
  padding: 4px 12px;
  padding: var(--space-xxs-ynfts5, 4px) var(--space-field-horizontal-89h2yr, 12px);
}
.awsui_selectable-item_15o6u_9fxjf_93.awsui_highlighted_15o6u_9fxjf_127.awsui_pad-bottom_15o6u_9fxjf_115:not(#\9), .awsui_selectable-item_15o6u_9fxjf_93.awsui_selected_15o6u_9fxjf_127.awsui_pad-bottom_15o6u_9fxjf_115:not(#\9) {
  padding-bottom: calc(4px + 2px);
  padding-bottom: calc(var(--space-xxs-ynfts5, 4px) + var(--space-xxxs-k2w98v, 2px));
}
.awsui_selectable-item_15o6u_9fxjf_93.awsui_highlighted_15o6u_9fxjf_127:not(#\9) {
  z-index: 2;
  background-color: #f4f4f4;
  background-color: var(--color-background-dropdown-item-hover-mqsxkv, #f4f4f4);
  border-color: #7d8998;
  border-color: var(--color-border-dropdown-item-hover-tyzq9m, #7d8998);
}
.awsui_selectable-item_15o6u_9fxjf_93.awsui_highlighted_15o6u_9fxjf_127.awsui_disabled_15o6u_9fxjf_141:not(#\9) {
  color: #9ba7b6;
  color: var(--color-text-dropdown-item-dimmed-jyqdrs, #9ba7b6);
  border-color: #7d8998;
  border-color: var(--color-border-dropdown-item-dimmed-hover-6dhy15, #7d8998);
  background-color: transparent;
  background-color: var(--color-background-dropdown-item-dimmed-qw48ma, transparent);
}
.awsui_selectable-item_15o6u_9fxjf_93.awsui_selected_15o6u_9fxjf_127:not(#\9) {
  background-color: #f2f8fd;
  background-color: var(--color-background-dropdown-item-selected-8l4mpk, #f2f8fd);
}
.awsui_selectable-item_15o6u_9fxjf_93.awsui_selected_15o6u_9fxjf_127.awsui_next-item-selected_15o6u_9fxjf_149:not(#\9) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.awsui_selectable-item_15o6u_9fxjf_93.awsui_selected_15o6u_9fxjf_127 + .awsui_selectable-item_15o6u_9fxjf_93.awsui_selected_15o6u_9fxjf_127:not(#\9) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.awsui_selectable-item_15o6u_9fxjf_93.awsui_highlighted_15o6u_9fxjf_127.awsui_is-keyboard_15o6u_9fxjf_157:not(#\9) {
  border-color: #0972d3;
  border-color: var(--color-border-item-focused-ap3b6s, #0972d3);
  box-shadow: inset 0 0 0 0px #0972d3;
  box-shadow: inset 0 0 0 var(--border-control-focus-ring-shadow-spread-srzyiu, 0px) var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_selectable-item_15o6u_9fxjf_93.awsui_parent_15o6u_9fxjf_161:not(#\9) {
  font-weight: bold;
  color: #414d5c;
  color: var(--color-text-dropdown-group-label-eaqlcl, #414d5c);
}
.awsui_selectable-item_15o6u_9fxjf_93.awsui_parent_15o6u_9fxjf_161:not(#\9):not(.awsui_interactiveGroups_15o6u_9fxjf_165) {
  border-top-color: #e9ebed;
  border-top-color: var(--color-border-dropdown-group-0utpsr, #e9ebed);
  padding: 8px;
  padding: var(--space-xs-rsr2qu, 8px);
}
.awsui_selectable-item_15o6u_9fxjf_93.awsui_parent_15o6u_9fxjf_161.awsui_interactiveGroups_15o6u_9fxjf_165:not(#\9) {
  padding: calc(
  8px + (2px - 1px)
) calc(12px + 2px);
  padding: calc(
  var(--space-xs-rsr2qu, 8px) + (var(--border-item-width-qbbbsa, 2px) - var(--border-divider-list-width-hacikr, 1px))
) calc(var(--space-field-horizontal-89h2yr, 12px) + var(--border-item-width-qbbbsa, 2px));
}
.awsui_selectable-item_15o6u_9fxjf_93.awsui_parent_15o6u_9fxjf_161.awsui_interactiveGroups_15o6u_9fxjf_165.awsui_highlighted_15o6u_9fxjf_127:not(#\9), .awsui_selectable-item_15o6u_9fxjf_93.awsui_parent_15o6u_9fxjf_161.awsui_interactiveGroups_15o6u_9fxjf_165.awsui_selected_15o6u_9fxjf_127:not(#\9) {
  padding: 8px 12px;
  padding: var(--space-xs-rsr2qu, 8px) var(--space-field-horizontal-89h2yr, 12px);
}
.awsui_selectable-item_15o6u_9fxjf_93.awsui_child_15o6u_9fxjf_177:not(#\9) {
  padding-left: calc(32px + 2px);
  padding-left: calc(var(--space-xxl-2nvmf1, 32px) + var(--border-item-width-qbbbsa, 2px));
}
.awsui_selectable-item_15o6u_9fxjf_93.awsui_child_15o6u_9fxjf_177.awsui_highlighted_15o6u_9fxjf_127:not(#\9), .awsui_selectable-item_15o6u_9fxjf_93.awsui_child_15o6u_9fxjf_177.awsui_selected_15o6u_9fxjf_127:not(#\9) {
  padding-left: 32px;
  padding-left: var(--space-xxl-2nvmf1, 32px);
}
.awsui_selectable-item_15o6u_9fxjf_93.awsui_disabled_15o6u_9fxjf_141:not(#\9) {
  color: #9ba7b6;
  color: var(--color-text-dropdown-item-disabled-varol7, #9ba7b6);
}
.awsui_selectable-item_15o6u_9fxjf_93:not(#\9):not(.awsui_disabled_15o6u_9fxjf_141):not(.awsui_parent_15o6u_9fxjf_161) {
  cursor: pointer;
}
.awsui_selectable-item_15o6u_9fxjf_93.awsui_interactiveGroups_15o6u_9fxjf_165:not(#\9):not(.awsui_disabled_15o6u_9fxjf_141) {
  cursor: pointer;
}
.awsui_selectable-item_15o6u_9fxjf_93.awsui_virtual_15o6u_9fxjf_192:not(#\9) {
  position: absolute;
  top: 2px;
  top: var(--border-dropdown-virtual-offset-width-fzhepk, 2px);
  left: 0;
  width: 100%;
  box-sizing: border-box;
}
.awsui_selectable-item_15o6u_9fxjf_93.awsui_virtual_15o6u_9fxjf_192:not(#\9):first-of-type:not(.awsui_selected_15o6u_9fxjf_127, .awsui_highlighted_15o6u_9fxjf_127) {
  border-top-color: transparent;
  border-top-color: var(--color-border-dropdown-item-top-xnzq48, transparent);
}

.awsui_measure-strut_15o6u_9fxjf_203:not(#\9) {
  position: absolute;
  pointer-events: none;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding-top: 1px;
  padding-top: var(--border-divider-list-width-hacikr, 1px);
}
.awsui_measure-strut-first_15o6u_9fxjf_212:not(#\9) {
  padding-bottom: 1px;
  padding-bottom: var(--border-divider-list-width-hacikr, 1px);
}

.awsui_screenreader-content_15o6u_9fxjf_216:not(#\9) {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

.awsui_option-content_15o6u_9fxjf_222:not(#\9) {
  /* used in test-utils */
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_1fn7j_1rcpg_93:not(#\9) {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.awsui_root_1fn7j_1rcpg_93 > .awsui_styled-box_1fn7j_1rcpg_100:not(#\9) {
  fill: #ffffff;
  fill: var(--color-background-control-default-4pa05r, #ffffff);
  stroke: #7d8998;
  stroke: var(--color-border-control-default-m3lmsh, #7d8998);
  stroke-width: 2px;
  stroke-width: var(--border-field-width-idlekx, 2px);
  transition: fill 90ms linear, stroke 90ms linear;
  transition: fill var(--motion-duration-transition-quick-x85tae, 90ms) var(--motion-easing-transition-quick-lukbd8, linear), stroke var(--motion-duration-transition-quick-x85tae, 90ms) var(--motion-easing-transition-quick-lukbd8, linear);
}
.awsui_root_1fn7j_1rcpg_93 > .awsui_styled-box-checked_1fn7j_1rcpg_106:not(#\9), .awsui_root_1fn7j_1rcpg_93 > .awsui_styled-box-indeterminate_1fn7j_1rcpg_106:not(#\9) {
  fill: #0972d3;
  fill: var(--color-background-control-checked-9admlu, #0972d3);
  stroke: #0972d3;
  stroke: var(--color-border-control-checked-4xvndc, #0972d3);
}
.awsui_root_1fn7j_1rcpg_93 > .awsui_styled-box-disabled_1fn7j_1rcpg_110:not(#\9) {
  fill: #d1d5db;
  fill: var(--color-background-control-disabled-zplipy, #d1d5db);
  stroke: #d1d5db;
  stroke: var(--color-border-control-disabled-vx8bco, #d1d5db);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_root_1fn7j_1rcpg_93 > .awsui_styled-box_1fn7j_1rcpg_100:not(#\9) {
    -webkit-animation: none;
            animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_root_1fn7j_1rcpg_93 > .awsui_styled-box_1fn7j_1rcpg_100:not(#\9), .awsui-mode-entering .awsui_root_1fn7j_1rcpg_93 > .awsui_styled-box_1fn7j_1rcpg_100:not(#\9) {
  -webkit-animation: none;
          animation: none;
  transition: none;
}
.awsui_root_1fn7j_1rcpg_93 > .awsui_styled-line_1fn7j_1rcpg_124:not(#\9) {
  stroke: #ffffff;
  stroke: var(--color-foreground-control-default-7ajdem, #ffffff);
  stroke-width: 2;
  fill: none;
}
.awsui_root_1fn7j_1rcpg_93 > .awsui_styled-line-disabled_1fn7j_1rcpg_129:not(#\9) {
  stroke: #ffffff;
  stroke: var(--color-foreground-control-disabled-v6a97u, #ffffff);
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_options-list_19gcf_12ty9_93:not(#\9) {
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: #000716;
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  white-space: inherit;
  overflow-y: auto;
  flex: 1 1 auto;
  min-height: 0;
  border-radius: 8px;
  border-radius: var(--border-radius-dropdown-ujknaf, 8px);
}

.awsui_decrease-top-margin_19gcf_12ty9_115:not(#\9) {
  margin-top: -1px;
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_r2vco_1ni6t_93:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: #000716;
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_dropdown-content-wrapper_qwoo0_2687b_93:not(#\9) {
  -webkit-animation: awsui_awsui-motion-fade-in-dropdown_qwoo0_2687b_1 135ms ease-out;
          animation: awsui_awsui-motion-fade-in-dropdown_qwoo0_2687b_1 135ms ease-out;
  -webkit-animation: awsui_awsui-motion-fade-in-dropdown_qwoo0_2687b_1 var(--motion-duration-show-quick-tyaalm, 135ms) var(--motion-easing-show-quick-1fcgbv, ease-out);
          animation: awsui_awsui-motion-fade-in-dropdown_qwoo0_2687b_1 var(--motion-duration-show-quick-tyaalm, 135ms) var(--motion-easing-show-quick-1fcgbv, ease-out);
}
@-webkit-keyframes awsui_awsui-motion-fade-in-dropdown_qwoo0_2687b_1 {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
@keyframes awsui_awsui-motion-fade-in-dropdown_qwoo0_2687b_1 {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_dropdown-content-wrapper_qwoo0_2687b_93:not(#\9) {
    -webkit-animation: none;
            animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_dropdown-content-wrapper_qwoo0_2687b_93:not(#\9), .awsui-mode-entering .awsui_dropdown-content-wrapper_qwoo0_2687b_93:not(#\9) {
  -webkit-animation: none;
          animation: none;
  transition: none;
}
.awsui_dropdown-content-wrapper_qwoo0_2687b_93.awsui_refresh_qwoo0_2687b_114:not(#\9) {
  -webkit-transform-origin: top;
          transform-origin: top;
  -webkit-animation-name: awsui_awsui-motion-fade-in-0_qwoo0_2687b_1;
          animation-name: awsui_awsui-motion-fade-in-0_qwoo0_2687b_1;
  -webkit-animation-duration: 135ms;
          animation-duration: 135ms;
  -webkit-animation-duration: var(--motion-duration-show-quick-tyaalm, 135ms);
          animation-duration: var(--motion-duration-show-quick-tyaalm, 135ms);
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-timing-function: var(--motion-easing-show-quick-1fcgbv, ease-out);
          animation-timing-function: var(--motion-easing-show-quick-1fcgbv, ease-out);
}
@-webkit-keyframes awsui_awsui-motion-fade-in-0_qwoo0_2687b_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes awsui_awsui-motion-fade-in-0_qwoo0_2687b_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_dropdown-content-wrapper_qwoo0_2687b_93.awsui_refresh_qwoo0_2687b_114:not(#\9) {
    -webkit-animation: none;
            animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_dropdown-content-wrapper_qwoo0_2687b_93.awsui_refresh_qwoo0_2687b_114:not(#\9), .awsui-mode-entering .awsui_dropdown-content-wrapper_qwoo0_2687b_93.awsui_refresh_qwoo0_2687b_114:not(#\9) {
  -webkit-animation: none;
          animation: none;
  transition: none;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_qwoo0_2687b_143:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: #000716;
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  position: relative;
  white-space: inherit;
}
.awsui_root_qwoo0_2687b_143.awsui_interior_qwoo0_2687b_157:not(#\9) {
  position: static;
}

.awsui_dropdown_qwoo0_2687b_93:not(#\9) {
  position: absolute;
  display: none;
  z-index: 2000;
  -webkit-user-select: none;
          user-select: none;
  -webkit-transform-origin: top;
          transform-origin: top;
}
.awsui_dropdown_qwoo0_2687b_93.awsui_use-portal_qwoo0_2687b_168:not(#\9) {
  z-index: 7000;
}
.awsui_dropdown_qwoo0_2687b_93:not(#\9):not(.awsui_interior_qwoo0_2687b_157) {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  transition: -webkit-transform 135ms;
  transition: transform 135ms;
  transition: transform 135ms, -webkit-transform 135ms;
  transition: -webkit-transform var(--motion-duration-show-quick-tyaalm, 135ms);
  transition: transform var(--motion-duration-show-quick-tyaalm, 135ms);
  transition: transform var(--motion-duration-show-quick-tyaalm, 135ms), -webkit-transform var(--motion-duration-show-quick-tyaalm, 135ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_dropdown_qwoo0_2687b_93:not(#\9):not(.awsui_interior_qwoo0_2687b_157) {
    -webkit-animation: none;
            animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_dropdown_qwoo0_2687b_93:not(#\9):not(.awsui_interior_qwoo0_2687b_157), .awsui-mode-entering .awsui_dropdown_qwoo0_2687b_93:not(#\9):not(.awsui_interior_qwoo0_2687b_157) {
  -webkit-animation: none;
          animation: none;
  transition: none;
}
.awsui_dropdown-content-wrapper_qwoo0_2687b_93:not(#\9) {
  position: relative;
  background-color: #ffffff;
  background-color: var(--color-background-dropdown-item-default-fzdqsg, #ffffff);
  outline: none;
  box-shadow: 0px 4px 20px 1px rgba(0, 7, 22, 0.1);
  box-shadow: var(--shadow-dropdown-99h0gz, 0px 4px 20px 1px rgba(0, 7, 22, 0.1));
  border-radius: 8px;
  border-radius: var(--border-radius-dropdown-ujknaf, 8px);
  border-top: 1px solid transparent;
  border-top: var(--border-divider-list-width-hacikr, 1px) solid var(--color-border-container-top-srcvx4, transparent);
  box-sizing: border-box;
  border-bottom: none;
}
.awsui_dropdown-content-wrapper_qwoo0_2687b_93:not(#\9)::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -1px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: transparent;
  border: 2px solid #9ba7b6;
  border: var(--border-field-width-idlekx, 2px) solid var(--color-border-dropdown-container-tyn0js, #9ba7b6);
  border-radius: 8px;
  border-radius: var(--border-radius-dropdown-ujknaf, 8px);
  pointer-events: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .awsui_dropdown-content-wrapper_qwoo0_2687b_93:not(#\9) {
    border: 2px solid transparent;
    border: var(--border-field-width-idlekx, 2px) solid var(--color-border-container-top-srcvx4, transparent);
  }
}
.awsui_dropdown-drop-up_qwoo0_2687b_213 > .awsui_dropdown-content-wrapper_qwoo0_2687b_93:not(#\9) {
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  box-shadow: 0px 4px 20px 1px rgba(0, 7, 22, 0.1);
  box-shadow: var(--shadow-dropup-11esug, 0px 4px 20px 1px rgba(0, 7, 22, 0.1));
}
.awsui_dropdown-drop-up_qwoo0_2687b_213.awsui_with-limited-width_qwoo0_2687b_217 > .awsui_dropdown-content-wrapper_qwoo0_2687b_93:not(#\9) {
  border-top: none;
}
.awsui_dropdown-drop-left_qwoo0_2687b_220:not(#\9) {
  right: 0;
}
.awsui_dropdown-drop-right_qwoo0_2687b_223:not(#\9) {
  left: 0;
}
.awsui_dropdown_qwoo0_2687b_93.awsui_occupy-entire-width_qwoo0_2687b_226:not(#\9) {
  min-width: 100%;
}
.awsui_dropdown_qwoo0_2687b_93.awsui_hide-upper-border_qwoo0_2687b_229 > .awsui_dropdown-content-wrapper_qwoo0_2687b_93:not(#\9) {
  border-top: none;
}
.awsui_dropdown_qwoo0_2687b_93.awsui_interior_qwoo0_2687b_157 > .awsui_dropdown-content-wrapper_qwoo0_2687b_93:not(#\9) {
  margin-top: -1px;
  border-top: 1px solid transparent;
  border-top: var(--border-divider-list-width-hacikr, 1px) solid var(--color-border-container-top-srcvx4, transparent);
}
.awsui_dropdown_qwoo0_2687b_93.awsui_open_qwoo0_2687b_236:not(#\9) {
  display: block;
}
.awsui_dropdown_qwoo0_2687b_93.awsui_open_qwoo0_2687b_236:not(#\9):not(.awsui_interior_qwoo0_2687b_157).awsui_refresh_qwoo0_2687b_114[data-animating=true] {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}
.awsui_dropdown_qwoo0_2687b_93.awsui_open_qwoo0_2687b_236:not(#\9):not(.awsui_interior_qwoo0_2687b_157).awsui_refresh_qwoo0_2687b_114[data-animating=true].awsui_dropdown-drop-up_qwoo0_2687b_213 {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}
.awsui_dropdown_qwoo0_2687b_93.awsui_open_qwoo0_2687b_236.awsui_nowrap_qwoo0_2687b_245:not(#\9) {
  white-space: nowrap;
  overflow: scroll;
}
.awsui_dropdown_qwoo0_2687b_93.awsui_is-empty_qwoo0_2687b_249:not(#\9)::after {
  display: none;
}

.awsui_dropdown-content_qwoo0_2687b_93:not(#\9) {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.awsui_ie11-wrapper_qwoo0_2687b_259:not(#\9) {
  display: flex;
}

.awsui_stretch-trigger-height_qwoo0_2687b_263:not(#\9) {
  height: 100%;
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_1afi9_msmw3_93:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: #000716;
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}
.awsui_root_1afi9_msmw3_93:not(#\9):not(.awsui_hidden_1afi9_msmw3_105) {
  border-top: 1px solid #e9ebed;
  border-top: var(--border-divider-list-width-hacikr, 1px) solid var(--color-border-dropdown-item-default-faosd8, #e9ebed);
}
.awsui_root_1afi9_msmw3_93:not(#\9):not(.awsui_hidden_1afi9_msmw3_105).awsui_no-items_1afi9_msmw3_108 {
  border-top: 1px solid transparent;
  border-top: var(--border-divider-list-width-hacikr, 1px) solid var(--color-border-dropdown-item-top-xnzq48, transparent);
}
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_14iqq_140qo_97:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: 14px;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: 22px;
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: #000716;
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  word-wrap: break-word;
  position: relative;
}
.awsui_root_14iqq_140qo_97.awsui_fit-height_14iqq_140qo_111:not(#\9) {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.awsui_root_14iqq_140qo_97.awsui_variant-default_14iqq_140qo_116:not(#\9), .awsui_root_14iqq_140qo_97.awsui_variant-stacked_14iqq_140qo_116:not(#\9) {
  border-radius: 16px;
  border-radius: var(--border-radius-container-gh9ysk, 16px);
  box-sizing: border-box;
  background-color: #ffffff;
  background-color: var(--color-background-container-content-i8i4a0, #ffffff);
}
.awsui_root_14iqq_140qo_97.awsui_variant-default_14iqq_140qo_116:not(#\9)::before, .awsui_root_14iqq_140qo_97.awsui_variant-stacked_14iqq_140qo_116:not(#\9)::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: transparent;
  box-sizing: border-box;
  border-color: transparent;
  border-top: 0px solid transparent;
  border-top: var(--border-container-top-width-jk6fhm, 0px) solid var(--color-border-container-top-srcvx4, transparent);
  border-radius: 16px;
  border-radius: var(--border-radius-container-gh9ysk, 16px);
  z-index: 1;
}
.awsui_root_14iqq_140qo_97.awsui_variant-default_14iqq_140qo_116:not(#\9)::after, .awsui_root_14iqq_140qo_97.awsui_variant-stacked_14iqq_140qo_116:not(#\9)::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: transparent;
  box-sizing: border-box;
  box-shadow: 0px 0px 1px 1px #e9ebed, 0px 1px 8px 2px rgba(0, 7, 22, 0.12);
  box-shadow: var(--shadow-container-fx563k, 0px 0px 1px 1px #e9ebed, 0px 1px 8px 2px rgba(0, 7, 22, 0.12));
  border-radius: 16px;
  border-radius: var(--border-radius-container-gh9ysk, 16px);
  mix-blend-mode: multiply;
}
@media screen and (-ms-high-contrast: active) {
  .awsui_root_14iqq_140qo_97.awsui_variant-default_14iqq_140qo_116:not(#\9), .awsui_root_14iqq_140qo_97.awsui_variant-stacked_14iqq_140qo_116:not(#\9) {
    border: 2px solid transparent;
    border: var(--border-field-width-idlekx, 2px) solid var(--color-border-container-top-srcvx4, transparent);
  }
}
.awsui_root_14iqq_140qo_97.awsui_variant-stacked_14iqq_140qo_116:not(#\9):not(:last-child), .awsui_root_14iqq_140qo_97.awsui_variant-stacked_14iqq_140qo_116:not(#\9):not(:last-child)::before, .awsui_root_14iqq_140qo_97.awsui_variant-stacked_14iqq_140qo_116:not(#\9):not(:last-child)::after {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.awsui_root_14iqq_140qo_97.awsui_variant-stacked_14iqq_140qo_116 + .awsui_root_14iqq_140qo_97.awsui_variant-stacked_14iqq_140qo_116:not(#\9), .awsui_root_14iqq_140qo_97.awsui_variant-stacked_14iqq_140qo_116 + .awsui_root_14iqq_140qo_97.awsui_variant-stacked_14iqq_140qo_116:not(#\9)::before, .awsui_root_14iqq_140qo_97.awsui_variant-stacked_14iqq_140qo_116 + .awsui_root_14iqq_140qo_97.awsui_variant-stacked_14iqq_140qo_116:not(#\9)::after {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.awsui_root_14iqq_140qo_97.awsui_variant-stacked_14iqq_140qo_116 + .awsui_root_14iqq_140qo_97.awsui_variant-stacked_14iqq_140qo_116:not(#\9)::before {
  border-top: 2px solid #e9ebed;
  border-top: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}
.awsui_root_14iqq_140qo_97.awsui_variant-stacked_14iqq_140qo_116 + .awsui_root_14iqq_140qo_97.awsui_variant-stacked_14iqq_140qo_116:not(#\9)::after {
  box-shadow: -1px 1px 1px 0px #e9ebed, 1px 1px 1px 0px #e9ebed, 0px 9px 8px -7px rgba(0, 7, 22, 0.12), 8px 0px 8px -7px rgba(0, 7, 22, 0.12), -8px 0px 8px -7px rgba(0, 7, 22, 0.12);
  box-shadow: var(--shadow-container-stacked-jfo2nn, -1px 1px 1px 0px #e9ebed, 1px 1px 1px 0px #e9ebed, 0px 9px 8px -7px rgba(0, 7, 22, 0.12), 8px 0px 8px -7px rgba(0, 7, 22, 0.12), -8px 0px 8px -7px rgba(0, 7, 22, 0.12));
}
.awsui_root_14iqq_140qo_97.awsui_sticky-enabled_14iqq_140qo_169:not(#\9)::before {
  top: calc(-1 * 0px);
  top: calc(-1 * var(--border-container-top-width-jk6fhm, 0px));
}
.awsui_root_14iqq_140qo_97.awsui_sticky-enabled_14iqq_140qo_169.awsui_variant-stacked_14iqq_140qo_116:not(#\9)::before {
  top: calc(-1 * 2px);
  top: calc(-1 * var(--border-divider-section-width-4wm2it, 2px));
}

.awsui_header_14iqq_140qo_176:not(#\9) {
  background-color: #ffffff;
  background-color: var(--color-background-container-header-4flbq5, #ffffff);
  border-top-left-radius: 16px;
  border-top-left-radius: var(--border-radius-container-gh9ysk, 16px);
  border-top-right-radius: 16px;
  border-top-right-radius: var(--border-radius-container-gh9ysk, 16px);
}
.awsui_header-sticky-disabled_14iqq_140qo_181:not(#\9) {
  position: relative;
  z-index: 1;
}
.awsui_header-sticky-enabled_14iqq_140qo_185:not(#\9) {
  top: 0;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  position: -webkit-sticky;
  position: sticky;
  z-index: 800;
}
.awsui_header-stuck_14iqq_140qo_191:not(#\9) {
  border-radius: 0;
}
.awsui_header-stuck_14iqq_140qo_191:not(#\9)::before {
  border: 0;
}
.awsui_header-stuck_14iqq_140qo_191:not(#\9):not(.awsui_header-variant-cards_14iqq_140qo_197) {
  box-shadow: 0px 2px 0px 0px #e9ebed, 0px 16px 16px -12px rgba(0, 7, 22, 0.1);
  box-shadow: var(--shadow-sticky-embedded-qit8ba, 0px 2px 0px 0px #e9ebed, 0px 16px 16px -12px rgba(0, 7, 22, 0.1));
}
.awsui_header-dynamic-height_14iqq_140qo_200.awsui_header-stuck_14iqq_140qo_191:not(#\9) {
  margin-bottom: calc(40px - 30px);
  margin-bottom: calc(var(--font-heading-xl-line-height-doxfdj, 40px) - var(--font-heading-l-line-height-gev4n1, 30px));
}
.awsui_header_14iqq_140qo_176:not(#\9):not(:empty) {
  border-bottom: 0px solid transparent;
  border-bottom: var(--border-container-sticky-width-i7t7xe, 0px) solid var(--color-border-container-divider-4ade7z, transparent);
}
.awsui_header_14iqq_140qo_176.awsui_with-paddings_14iqq_140qo_206:not(#\9) {
  padding: 12px 20px;
  padding: var(--space-container-header-vertical-zsjhel, 12px) var(--space-container-horizontal-tlw03i, 20px);
}
.awsui_header_14iqq_140qo_176.awsui_with-hidden-content_14iqq_140qo_209:not(#\9) {
  border-bottom-left-radius: 16px;
  border-bottom-left-radius: var(--border-radius-container-gh9ysk, 16px);
  border-bottom-right-radius: 16px;
  border-bottom-right-radius: var(--border-radius-container-gh9ysk, 16px);
}
.awsui_header-variant-cards_14iqq_140qo_197:not(#\9) {
  border-radius: 16px;
  border-radius: var(--border-radius-container-gh9ysk, 16px);
  box-sizing: border-box;
}
.awsui_header-variant-cards_14iqq_140qo_197:not(#\9):not(.awsui_header-sticky-enabled_14iqq_140qo_185) {
  position: relative;
}
.awsui_header-variant-cards_14iqq_140qo_197:not(#\9)::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: transparent;
  box-sizing: border-box;
  border-color: transparent;
  border-top: 0px solid transparent;
  border-top: var(--border-container-top-width-jk6fhm, 0px) solid var(--color-border-container-top-srcvx4, transparent);
  border-radius: 16px;
  border-radius: var(--border-radius-container-gh9ysk, 16px);
  z-index: 1;
}
.awsui_header-variant-cards_14iqq_140qo_197:not(#\9)::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: transparent;
  box-sizing: border-box;
  box-shadow: 0px 0px 1px 1px #e9ebed, 0px 1px 8px 2px rgba(0, 7, 22, 0.12);
  box-shadow: var(--shadow-container-fx563k, 0px 0px 1px 1px #e9ebed, 0px 1px 8px 2px rgba(0, 7, 22, 0.12));
  border-radius: 16px;
  border-radius: var(--border-radius-container-gh9ysk, 16px);
  mix-blend-mode: multiply;
}
@media screen and (-ms-high-contrast: active) {
  .awsui_header-variant-cards_14iqq_140qo_197:not(#\9) {
    border: 2px solid transparent;
    border: var(--border-field-width-idlekx, 2px) solid var(--color-border-container-top-srcvx4, transparent);
  }
}
.awsui_header-variant-cards_14iqq_140qo_197.awsui_header-stuck_14iqq_140qo_191:not(#\9)::after, .awsui_header-variant-cards_14iqq_140qo_197.awsui_header-stuck_14iqq_140qo_191:not(#\9)::before {
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.awsui_header-variant-full-page_14iqq_140qo_259.awsui_header-stuck_14iqq_140qo_191:not(#\9) {
  box-shadow: none;
}
.awsui_header-variant-full-page_14iqq_140qo_259.awsui_header-stuck_14iqq_140qo_191:not(#\9)::after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  box-shadow: 0px 4px 20px 1px rgba(0, 7, 22, 0.1);
  box-shadow: var(--shadow-sticky-aijlo6, 0px 4px 20px 1px rgba(0, 7, 22, 0.1));
  -webkit-clip-path: polygon(-999% 100%, 999% 100%, 999% 999%, -999% 999%);
          clip-path: polygon(-999% 100%, 999% 100%, 999% 999%, -999% 999%);
}

/*
The dynamic height dark header needs a background that will cover
the default white background of the container component.
*/
.awsui_dark-header_14iqq_140qo_277:not(#\9) {
  background-color: #ffffff;
  background-color: var(--color-background-layout-main-sfhm4y, #ffffff);
}

.awsui_fit-height_14iqq_140qo_111 > .awsui_content_14iqq_140qo_281:not(#\9) {
  flex: 1 1;
  overflow: auto;
}
.awsui_with-content-paddings_14iqq_140qo_285 > .awsui_content_14iqq_140qo_281:not(#\9) {
  padding: 20px 20px;
  padding: var(--space-scaled-l-t03y3z, 20px) var(--space-container-horizontal-tlw03i, 20px);
}
.awsui_with-content-paddings_14iqq_140qo_285 > .awsui_header_14iqq_140qo_176 + .awsui_content_14iqq_140qo_281:not(#\9) {
  padding-top: 0px;
  padding-top: var(--space-container-content-top-fsd8nr, 0px);
}

.awsui_fit-height_14iqq_140qo_111.awsui_with-content-paddings_14iqq_140qo_285:not(#\9):not(.awsui_with-footer_14iqq_140qo_292) {
  padding-bottom: 20px;
  padding-bottom: var(--space-scaled-l-t03y3z, 20px);
}
.awsui_fit-height_14iqq_140qo_111.awsui_with-content-paddings_14iqq_140qo_285:not(#\9):not(.awsui_with-footer_14iqq_140qo_292) > .awsui_content_14iqq_140qo_281 {
  padding-bottom: 0;
}

.awsui_footer_14iqq_140qo_299.awsui_with-paddings_14iqq_140qo_206:not(#\9) {
  padding: 12px 20px;
  padding: var(--space-scaled-s-cu1hzn, 12px) var(--space-container-horizontal-tlw03i, 20px);
}
.awsui_footer_14iqq_140qo_299.awsui_with-divider_14iqq_140qo_302:not(#\9) {
  border-top: 2px solid #e9ebed;
  border-top: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}
